import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-status-selector',
    templateUrl: './status-selector.component.html',
    styleUrls: ['./status-selector.component.scss']
})
export class StatusSelectorComponent {
    @Input() isIgnored: boolean;
    @Output() isIgnoredChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    public valueChanged(isIgnored: boolean): void {
        this.isIgnoredChange.emit(isIgnored);
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {environment as env} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';
import {ConfigService} from '../../core/services/config/config.service';
import {ComponentConfiguration} from '../../core/models/config';

@Injectable({
    providedIn: 'root'
})
export class PaceVsRemDemService {
    private defaultConfig = {
        id: 'paceVsRemDmd',
        name: 'Pacing vs Rem Dmd',
        type: 'chart',
        config: {
            metric: {
                override: {
                    name: 'Inv Overrides',
                    style: {color: '#0B3EF9'}
                },
                noOverride: {
                    name: 'No Inv Overrides',
                    style: {color: '#71E2AF'}
                }
            }
        }
    };

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    public getComponentConfig(): ComponentConfiguration {
        const config = this.configService.getComponentConfiguration('dashboard', 'paceVsRemDmd');
        return config ? config : this.defaultConfig;
    }

    public getPaceVsRemainingDemand(request: any): Observable<PaceVsRemDemModel[]> {
        return this.http.post<PaceVsRemDemModel[]>(`${env.apiBaseUrl}/dashboard/pace_vs_rem_dmd`,
            {
                ...request,
                startDate: moment(request.startDate).format('YYYY-MM-DD'),
                endDate: moment(request.endDate).format('YYYY-MM-DD')
            }).pipe(
                map(resp => {
                    resp.forEach(item => {
                        let valueSet: any;
                        valueSet = item;
                        for(let i=0; i<valueSet.length; i++) {
                            if ("effDt" in valueSet[i]) {
                                valueSet[i].effDt = DateUtils.convertToLocaleDateString(valueSet[i].effDt as string);
                            }
                        }

            });
            return resp;
        }));
    }
}

export class PaceVsRemDemModel {
    depDt: string | Date;
    effDt: string | Date;
    rsid: string;
    od: string;
    isFcstOvrd: boolean;
    remFcstDem: number;
    paceDelta: number;
    curProf: string;
    recProf: string;
    col?: string;
}

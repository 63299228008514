import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-override-metrics',
    templateUrl: './override-metrics.component.html',
    styleUrls: ['./override-metrics.component.scss']
})
export class OverrideMetricsComponent {
    @Input() overrideMetrics: any;

    constructor() {
    }

}

import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router, CanActivateChild
} from '@angular/router';
import {AuthService} from '../../../auth/auth.service';
import {UrlStore} from '../../../shared/url-store';

@Injectable({
    providedIn: 'root'
})
export class MainGuard implements CanActivate, CanLoad, CanActivateChild {

    constructor(private auth: AuthService, public router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.hasAccess(state.url);
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.hasAccess(state.url);
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): boolean {
        return this.hasAccess(segments.map(seg => seg.path).join('/'));
    }

    private hasAccess(url: string): boolean {
        const isConfigured = this.auth.isConfigured();
        if (!isConfigured) {
            this.auth.beginLogin(url);
            return false;
        } else {
            const config = this.auth.getConfig();
            if (config.isActive) {
                return true;
            } else {
                this.router.navigate([UrlStore.ui.accessDeined]);
                return false;
            }
        }

    }
}

import {Component, Input, OnInit, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-heatmap-legends',
    templateUrl: './heatmap-legends.component.html',
    styleUrls: [
        '../../shared/styles/shared-chart.scss',
        './heatmap-legends.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class HeatmapLegendsComponent implements OnInit, OnChanges {
    @Input() selectedMetric: any;
    public metricId = '';
    public capacityCon = {};

    constructor() {
    }

    ngOnInit(): void {
        const appConfig = JSON.parse(localStorage.getItem('appConfig'));
        let capacityConstrain: number[] = [.9, .8, .7, .6, 0];

        if ('capacity_constraints' in appConfig) {
            capacityConstrain = appConfig['capacity_constraints'];
        }

        this.capacityCon = [
            {
                'class': 'cap-con-high-color',
                'label': ' > ' + capacityConstrain[0] * 100 + '% '
            },
            {
                'class': 'cap-con-med-high-color',
                'label': ' '+ capacityConstrain[1] * 100 + '% to '+ capacityConstrain[0] * 100 + '% ',
            },
            {
                'class': 'cap-con-med-color',
                'label': ' ' + capacityConstrain[2] * 100 + '% to '+ capacityConstrain[1] * 100 + '% ',
            },
            {
                'class': 'cap-con-med-low-color',
                'label': ' ' + capacityConstrain[3] * 100 + '% to '+ capacityConstrain[2] * 100 + '% ',
            },
            {
                'class': 'cap-con-low-color',
                'label': ' ' + capacityConstrain[4] * 100 + '% to '+ capacityConstrain[3] * 100 + '% ',
            },
            {
                'class': 'unavailable-color',
                'label': ' Unavailable',
            }
        ]       
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedMetric && changes.selectedMetric.currentValue) {
            this.metricId = this.selectedMetric.id;
        }
    }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment as env} from '../../../environments/environment';
import * as moment from 'moment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusReportService {
  constructor(private http: HttpClient) {}

  public loadStatusReport(searchOptions: any): Observable<StatusReportModel> {
    return this.http.post<StatusReportModel>(
            `${env.apiBaseUrl}/dashboard/status_report`,
            { ...searchOptions,
                startDate: moment(searchOptions.startDate).format('YYYY-MM-DD'),
                endDate: moment(searchOptions.endDate).format('YYYY-MM-DD') });
  }
}
export class StatusReportModel {
  numRecords: number;
  newRec: number;
  needAppRec: number;
  autoRec: number;
}

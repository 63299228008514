<i class="fas fa-save" (click)="open()"></i>
<kendo-dialog *ngIf="saveDialogOpened"
              title="Save Filters"
              [actions]="dialogActions"
              (action)="onAction($event)"
              (close)="close()">
    <div class="save-input-container">
        <p>Enter name for filter view</p>
        <kendo-combobox [data]="existingFilterNamesData" [allowCustom]="true"
                        [filterable]="true"
                        (filterChange)="filterDropDownChange($event)"
                        class="save-filter-input"
                        [(ngModel)]="currentInput"></kendo-combobox>
    </div>
    <div class="date-switch-title">
        <h5>Date Options:</h5>
    </div>
    <div class="date-switch-container">
        <label [style.padding-right.px]="15">Days Prior</label>
        <kendo-switch [onLabel]="' '" [offLabel]="' '" [checked]="dateOptionSwitch"
                      [(ngModel)]="dateOptionSwitch"
                      class="filter-switch"></kendo-switch>
        <label [style.padding-left.px]="15">Date Range</label>
    </div>
</kendo-dialog>
<kendo-dialog *ngIf="overwriteDialogOpened"
              title="Overwrite Existing Filter"
              [actions]="dialogActions"
              (action)="onAction($event)"
              (close)="close()">
    <div style="padding-top: 1em;">
        Are you sure you want to override existing filter <b>{{currentInput}}</b>?
    </div>
</kendo-dialog>
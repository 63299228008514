import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Align} from '@progress/kendo-angular-popup';
import {MenuItem} from "./customer-menu-item.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment as env} from "../../../environments/environment";

@Component({
    selector: 'app-customer-menu',
    templateUrl: './customer-menu.component.html',
    styleUrls: ['./customer-menu.component.scss']
})
export class CustomerMenuComponent implements OnInit {
    @ViewChild('popup', {read: ElementRef}) private popup: ElementRef;
    public show = false;
    public anchorAlign: Align = {horizontal: 'right', vertical: 'bottom'};
    public popupAlign: Align = {horizontal: 'left', vertical: 'bottom'};
    public customerMenuItems: MenuItem[];

    public allValues: any;
    public filteredValues: any;
    public selectedValues: any;

    constructor(public elRef: ElementRef, private http: HttpClient) {
    }

    ngOnInit(): void {
        const data = this.loadCustomerMenu();
        this.loadCustomerMenuValues(data);
    }

    public onToggle(show?: boolean): void {
        const userConfig = JSON.parse(localStorage.getItem('userConfig'))
        const customerMenuItemHidden = userConfig['customerMenuHidden'];
        if (this.customerMenuItems.length === 0 && !customerMenuItemHidden) {
            const data = this.loadCustomerMenu();
            this.loadCustomerMenuValues(data);
        }
        // console.log(data);
        this.show = show !== undefined ? show : !this.show;
    }

    @HostListener('window:click', ['$event'])
    private documentClick(event: any): void {
        if (!this.popupContains(event.target)) {
            this.onToggle(false);
        }
    }

    private popupContains(target: any): boolean {
        return this.elRef.nativeElement.parentElement.contains(target) ||
            (this.popup ? this.popup.nativeElement.contains(target) : false);
    }

    public loadCustomerMenu() {
        console.log('load customer types');
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/get_tenants`);
    }

    public loadCustomerMenuValues(observableValues: Observable<any>, sortFunction?: () => any): void {
        observableValues.subscribe(response => {
            this.allValues = response.customer_menu_items;
            // console.log(this.allValues);
            if (sortFunction) {
                this.allValues = this.allValues.sort(sortFunction);
            }
            this.filteredValues = this.allValues;
        });
        let appendCustomerMenuValues = [];
        for (const val in this.allValues) {
            let values = this.allValues[val];
            let menuItemObj = new MenuItem()
            menuItemObj.title = values['name'];
            menuItemObj.menuIcon = 'subway';
            //switch_access_shortcut, directions_subway, subway, train
            menuItemObj.routerLink = values['id'];
            menuItemObj.enabled = true;
            appendCustomerMenuValues.push(menuItemObj);
        }
        this.customerMenuItems = appendCustomerMenuValues;
    }

}

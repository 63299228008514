import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs/internal/observable/of';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {environment as env} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';
import {ConfigService} from '../../core/services/config/config.service';
import {ComponentConfiguration} from '../../core/models/config';

@Injectable({
    providedIn: 'root'
})
export class TopBkgChangeservice {
    private defaultConfig = {
        id: 'topBkgChanges',
        name: 'Top 20 Booking Changes',
        type: 'chart',
        config: {
            metric: {
                individualBkg: {
                    name: 'Individual Bkg',
                    style: {color: '#0B3EF9'}
                }
            }
        }
    };

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    public getComponentConfig(): ComponentConfiguration {
        const config = this.configService.getComponentConfiguration('dashboard', 'topBkgChanges');
        return config ? config : this.defaultConfig;
    }

    public loadTopBookingsChanges(searchOptions: any): Observable<TopBkgChange> {
        return this.http.post<TopBkgChange>(
            `${env.apiBaseUrl}/dashboard/top_booking_changes`,
            {
                ...searchOptions,
                startDate: moment(searchOptions.startDate).format('YYYY-MM-DD'),
                endDate: moment(searchOptions.endDate).format('YYYY-MM-DD')
            })
            .pipe(map(resp => {
                Object.keys(resp).forEach(key => {
                    resp[key].forEach(item => {
                        item.effDt = DateUtils.convertToLocaleDateString(item.effDt);
                        item.key = `${item.rsid}::::${item.effDt}`;
                    });
                });
                return resp;
            }));
    }

    getDaysDropDownOptions() {
        return of({
            data: [{id: -1, name: '-1 Day'},
                {id: -7, name: '-7 Days'},
                {id: -14, name: '-14 Days'},
                {id: -2, name: 'Intraday'}]
        });
    }
}

export class TopBkgChange {
    1: TopBkgChangesModel[];
    // option 2 is for Intraday dropdown option that includes days prior 0 and days prior 1.
    2: TopBkgChangesModel[];
    7: TopBkgChangesModel[];
    14: TopBkgChangesModel[];
}

export class TopBkgChangesModel {
    rsid: string;
    indivBkg: number;
    od: string;
    effDt: string | Date;
    key?: string;
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseBucketOverrideGrid} from '../../models/base-bucket-override-grid.model';

@Component({
    selector: 'app-bulk-bucket-grid',
    templateUrl: './bulk-bucket-grid.component.html',
    styleUrls: ['./bulk-bucket-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BulkBucketGridComponent extends BaseBucketOverrideGrid implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.scanBucketsUp();
    }

}

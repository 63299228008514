import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {UrlStore} from '../../shared/url-store';
import {TubemapModel} from '../models/tubemap.model';
import {map} from 'rxjs/operators';
import {HeatmapModel} from '../models/heatmap.model';

@Injectable({
    providedIn: 'root'
})
export class TubemapService {
    private changeMetricSubject = new Subject<any>();
    private closeTubemapSubject = new Subject<any>();
    private displayTubemapSubject = new Subject<any>();

    changeMetric$ = this.changeMetricSubject.asObservable();
    closeTubemap$ = this.closeTubemapSubject.asObservable();
    displayTubemap$ = this.displayTubemapSubject.asObservable();

    constructor(private http: HttpClient) {
    }

    public changeMetric(metricId: string): void {
        this.changeMetricSubject.next(metricId);
    }

    public closeTubemap(): void {
        this.closeTubemapSubject.next();
    }

    public displayTubemap(tube: HeatmapModel, metricId: string): void {
        this.displayTubemapSubject.next({tube, metricId});
    }

    public getTubemapData(filterOptions: any): Observable<TubemapModel> {
        return this.http.post<TubemapModel>(`${UrlStore.api.inventory.singleTubemap}`, filterOptions)
            .pipe(map(resp => {
                resp.tubes.forEach(tube => {
                    tube.segments.forEach(seg => {
                        seg.capacityConstraint = seg.capacity_constraints;
                        seg.revenueOpportunity = seg.revenue_opportunity;
                    });
                });
                return resp;
            }));
    }
}

<div class="notification-menu">
  <span #notification class="fa-stack fa-lg" (click)="onToggle()">
    <i class="fas fa-bell fa-lg fa-stack-1x"></i>
  </span>
  <kendo-popup #popup [anchor]="notification"
               [anchorAlign]="anchorAlign"
               [popupAlign]="popupAlign"
               (anchorViewportLeave)="show = false"
               *ngIf="show">
    <div #popupContainer class="popup-content">
      <ul>
        <li *ngFor="let notification of notificationData">
          <app-notification-card [notificationData]="notification"
                                 (dismiss)="dismissNotification($event)"
                                 (notificationRoute)="routeNotification($event)"></app-notification-card>
        </li>
      </ul>
    </div>
  </kendo-popup>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../models/menu-item.model';

@Component({
    selector: 'app-settings-menu-item',
    templateUrl: './settings-menu-item.component.html',
    styleUrls: ['./settings-menu-item.component.scss']
})
export class SettingsMenuItemComponent implements OnInit {
    @Input() menuItem: MenuItem;

    constructor() {
    }

    ngOnInit(): void {
    }

}

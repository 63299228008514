import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FilterItem} from '../../../shared/filter-item.model';
import {InventoryService} from '../../inventory.service';
import {HeatmapMarketTabModel} from '../../models/heatmap.model';
import {HeatmapService} from '../../services/heatmap.service';
import {TubemapService} from '../../services/tubemap.service';
import {NotificationPopupService} from '../../../shared/notification-popup/services/notification-popup.service';

@Component({
    selector: 'app-heatmap-container-card',
    templateUrl: './heatmap-container-card.component.html',
    styleUrls: ['./heatmap-container-card.component.scss']
})
export class HeatmapContainerCardComponent implements OnInit {
    @ViewChild('container', {read: ViewContainerRef}) public container: ViewContainerRef;
    @Input() public set filterOptions(filterOptions: any) {
        if (filterOptions === undefined || filterOptions === null) {
            return;
        }
        this.heatmapFilterOption = filterOptions;
        if (filterOptions.portfolio.length === 0) {
            this.updateMarketTabsByRSID(filterOptions);
        }else{
            this.updateMarketTabs(filterOptions);
        }
    }
    public heatmapFilterOption: any;
    public heatmapLoading = false;
    public heatmapMetricOptions = new FilterItem();
    public heatMapOrderOptions = new FilterItem();
    public heatmapMarketTabs: HeatmapMarketTabModel[] = [
        {
            id: 'market1',
            name: 'Market 1',
            data: []
        }
    ];
    public selectedMetricOption: any;
    public selectedOrderOption: any;
    private marketFilters: any = [];

    constructor(private inventorySvc: InventoryService, private heatmapSvc: HeatmapService,
                private tubemapSvc: TubemapService, private notificationSvc: NotificationPopupService) {
    }

    ngOnInit(): void {
        this.heatmapMetricOptions.loadAllValues(this.heatmapSvc.getHeatmapMetricOptions());
        this.heatMapOrderOptions.loadAllValues(this.heatmapSvc.getHeatmapOrderOptions());
    }

    public chartChange(event): void {
        this.tubemapSvc.closeTubemap();
    }

    public onHeatmapMetricChange(event): void {
        this.selectedMetricOption = event;
    }

    public onHeatmapOrderChange(event): void {
        this.selectedOrderOption = event;
    }


    private updateMarketTabs(filterOpts: any): void {
        if (filterOpts.portfolio.length === 0) {
            return;
        }
        this.heatmapLoading = true;
        this.heatmapMarketTabs = [];
        this.marketFilters = [];
        filterOpts.portfolio.forEach(market => {
            const tabId = market.toLowerCase().replace(/\s+/g, '');
            this.heatmapMarketTabs.push({
                id: tabId,
                name: market,
                data: []
            });
            this.marketFilters.push({
                ...filterOpts,
                market,
                tabId
            });
        });
        this.tubemapSvc.closeTubemap();
        this.heatmapSvc.getAllHeatmapData(this.marketFilters).subscribe(resp => {
            if (!this.heatmapMetricOptions.selectedValues?.id) {
                this.heatmapMetricOptions.selectedValues = this.heatmapMetricOptions.findById('capacityConstraint');
                this.selectedMetricOption = this.heatmapMetricOptions.selectedValues;
            }
            if (!this.heatMapOrderOptions.selectedValues?.id) {
                this.heatMapOrderOptions.selectedValues = this.heatMapOrderOptions.findById('trainDepartureTime');
                this.selectedOrderOption = this.heatMapOrderOptions.selectedValues;
            }
            this.heatmapMarketTabs.forEach(tab => {
                tab.data = resp[tab.id];
            });
            this.heatmapLoading = false;
        }, error => {
            this.notificationSvc.displayNotification(
                this.container,
                {
                    notificationType: 'warning',
                    message: 'An error occurred when retrieving inventory heatmap data.'
                });
            console.error(`Error retrieving heatmap data ${error}`);
            this.heatmapLoading = false;
        });
    }

    private updateMarketTabsByRSID(filterOpts: any): void {
        this.heatmapLoading = true;
        this.heatmapMarketTabs = [];
        this.tubemapSvc.closeTubemap();
        this.heatmapSvc.getHeatmapData(filterOpts).subscribe(resp => {
            const markets = {}
            resp.forEach(item => {
                if (markets.hasOwnProperty(item.portfolio)){
                    markets[item.portfolio].push(item)
                }else{
                    markets[item.portfolio] = [item]
                }
            })
            console.log(markets);
            if (!this.heatmapMetricOptions.selectedValues?.id) {
                this.heatmapMetricOptions.selectedValues = this.heatmapMetricOptions.findById('capacityConstraint');
                this.selectedMetricOption = this.heatmapMetricOptions.selectedValues;
            }
            if (!this.heatMapOrderOptions.selectedValues?.id) {
                this.heatMapOrderOptions.selectedValues = this.heatMapOrderOptions.findById('trainDepartureTime');
                this.selectedOrderOption = this.heatMapOrderOptions.selectedValues;
            }

            Object.keys(markets).forEach(marketKey => {
                const tabId = marketKey.toLowerCase().replace(/\s+/g, '');
                this.heatmapMarketTabs.push({
                    id: tabId,
                    name: marketKey,
                    data: markets[marketKey]
                });
            });
            this.heatmapLoading = false;

        }, error => {
            this.notificationSvc.displayNotification(
                this.container,
                {
                    notificationType: 'warning',
                    message: 'An error occurred when retrieving inventory heatmap data.'
                });
            console.error(`Error retrieving heatmap data ${error}`);
            this.heatmapLoading = false;
        });
    }

}

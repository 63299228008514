import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseNotification} from '../models/base-notification';
import {ReOptNotification} from '../models/reopt-notification';

@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
    public notification: BaseNotification;

    @Input() set notificationData(notification: any) {
        this.convertNotification(notification);
    }

    @Output() dismiss: EventEmitter<any> = new EventEmitter();
    @Output() notificationRoute: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public dismissNotification(): void {
        this.dismiss.emit(this.notification);
    }

    public viewNotification(): void {
        this.notificationRoute.emit(this.notification.getRouteInfo());
    }

    private convertNotification(notification): void {
        if (notification.notificationType === 'reOpt') {
            this.notification = new ReOptNotification(notification.data);
        }
    }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InventoryOverrideService} from '../../services/inventory-override.service';
import {Subscription} from 'rxjs';
import {ProfileSelectorService} from '../profile-selector/profile-selector.service';
import {DialogRef} from '@progress/kendo-angular-dialog';
import {OverrideType} from '@shared/enums';
import {BaseInventoryOverride} from '../../models/base-inventory-override.model';
import {OverrideLevel} from '../../../../inventory/inventory.model';
import {
    VariableProfileSelectorService
} from "@shared/inventory-override/components/variable-profile-selector/variable-profile-selector.service";

@Component({
    selector: 'app-bulk-inventory-override',
    templateUrl: './bulk-inventory-override.component.html',
    styleUrls: ['./bulk-inventory-override.component.scss']
})
export class BulkInventoryOverrideComponent extends BaseInventoryOverride implements OnInit, OnDestroy {
    @Input() bulkRequest: any;
    public loading = true;

    public bulkBucketToggle = true;
    public bulkStaticProfileToggle = false;
    public bulkVariableProfileToggle = false;

    public invOverride: any;
    public title = 'Bulk Override';
    private selectedProfile: any;
    private profileChangeSub: Subscription;
    public selectedSection: any = 1;
    public options = [
        {label: 'Bucket', value: 'bucket'},
        {label: 'Static Profile', value: 'staticProfile'},
        {label: 'Variable Profile', value: 'variableProfile'}
    ];

    public selectedOption: string;
    private variableProfileChangeValueType: any;
    private readonly variableProfileChangeType: Subscription;

    public onOptionClick(value: string) {
        this.selectedOption = value;
        console.log(this.selectedSection);

        if (value === 'bucket') {
            this.bulkBucketToggle = true;
            this.bulkStaticProfileToggle = false;
            this.bulkVariableProfileToggle = false;
        } else if (value === 'staticProfile') {
            this.bulkBucketToggle = false;
            this.bulkStaticProfileToggle = true;
            this.bulkVariableProfileToggle = false;
        } else if (value === 'variableProfile') {
            this.bulkBucketToggle = false;
            this.bulkStaticProfileToggle = false;
            this.bulkVariableProfileToggle = true;
        }
    }


    constructor(public dialog: DialogRef,
                private overrideSvc: InventoryOverrideService,
                private profileSelectSvc: ProfileSelectorService,
                private variableProfileSelectSvc: VariableProfileSelectorService) {
        super(dialog);
        this.selectedOption = 'bucket';
        this.profileChangeSub = this.profileSelectSvc.profileChange$.subscribe(this.selectedProfileChange.bind(this));
        this.variableProfileChangeType = this.variableProfileSelectSvc.variableProfileChangeType$.subscribe(this.variableProfileChange.bind(this));
    }

    ngOnInit(): void {
        this.getOverrideInfo();
    }

    ngOnDestroy(): void {
        this.profileChangeSub.unsubscribe();
    }

    public isValid(): boolean {
        if (this.bulkVariableProfileToggle) {
            return this.variableProfileChangeValueType !== undefined;
        } else {
            return !this.bulkBucketToggle ? this.selectedProfile : super.isValid();
        }
    }

    public isVariableProfileValid(): boolean {
        if (this.bulkVariableProfileToggle) {
            return this.variableProfileChangeValueType === undefined;
        }
        return false
    }

    public onApprove(): void {
        this.saveApprovals();
    }

    public onSave(): void {
        this.saveOverrides();
    }

    private getOverrideInfo(): void {
        this.overrideSvc.getBulkOverrideInfo(this.bulkRequest).subscribe(resp => {
            console.log(this.bulkRequest);
            this.loading = false;
            resp.overrideType = OverrideType.Bulk;
            resp.overrideLevel = this.bulkRequest.overrideLevel;
            resp.passengerClass = this.bulkRequest.passengerClass;
            if (this.bulkRequest.dataItem) {
                resp.origin = this.bulkRequest.dataItem.origin;
                resp.destination = this.bulkRequest.dataItem.destination;
            }

            if (resp.overrideLevel === 'od') {
                resp.journeyPairs = this.bulkRequest.journeyPairs;
            }
            this.invOverride = resp;
        }, error => {
            console.error(`Error when getting override info ${error}`);
            this.dialog.close();
        });
    }

    private saveApprovals(): void {
        this.loading = true;
        this.updateRequest();
        this.invOverride.buckets = [];
        if (this.bulkVariableProfileToggle) {
            const variableProfileChangeType = this.variableProfileChangeValueType.split('_')[0];
            const variableProfileChangeValue = this.variableProfileChangeValueType.split('_')[1];
            this.invOverride['variableProfileChangeType'] = variableProfileChangeType;
            this.invOverride['variableProfileChangeValue'] = parseInt(variableProfileChangeValue);
        }
        this.overrideSvc.approveRecommendation(this.invOverride).subscribe(resp => {
            if (resp) {
                this.closeDialog();
            } else {
                this.loading = false;
            }
        }, error => {
            console.error(`Error during bulk approve ${error}`);
            this.loading = false;
        }, () => {
            this.updateComplete.emit(true);
        });
    }

    private saveOverrides(): void {
        this.loading = true;
        if (this.bulkVariableProfileToggle) {
            const variableProfileChangeType = this.variableProfileChangeValueType.split('_')[0];
            const variableProfileChangeValue = this.variableProfileChangeValueType.split('_')[1];
            this.invOverride['variableProfileChangeType'] = variableProfileChangeType;
            this.invOverride['variableProfileChangeValue'] = parseInt(variableProfileChangeValue);
        }
        if (this.bulkStaticProfileToggle && this.selectedProfile) {
            this.invOverride.overrideProfile = this.selectedProfile.id;
        }
        this.updateRequest();
        this.overrideSvc.saveBulkOverride(this.invOverride).subscribe(resp => {
            if (resp) {
                this.closeDialog();
            } else {
                this.loading = false;
            }
        }, error => {
            console.error(`Error during bulk save ${error}`);
            this.loading = false;
        }, () => {
            this.updateComplete.emit(true);
        });
    }

    private selectedProfileChange(profile: any): void {
        this.selectedProfile = profile;
    }

    private variableProfileChange(type: any): void {
        this.variableProfileChangeValueType = type;
    }

    private updateRequest(): void {
        this.invOverride.additionalFilters = this.bulkRequest.additionalFilters;
        if (this.bulkRequest.overrideLevel === OverrideLevel.Leg) {
            this.invOverride.legOrigin = this.bulkRequest.journeyOrigin[0];
            this.invOverride.legDestination = this.bulkRequest.journeyDestination[0];
        }
    }

    public nestBuckets() {
        const bucketLength = this.invOverride.buckets.length - 1;
        console.log(this.invOverride.buckets);
        let highest = 0;
        for (let i = bucketLength; i > 0; i--) {
            const bucket = this.invOverride.buckets[i];
            if (bucket.override === null) {
                    bucket.override = bucket.override;
                    highest = bucket.override;
            }
            this.invOverride.buckets[i] = bucket;
            const nextBucketUp = this.invOverride.buckets[i - 1];
            if (highest > bucket.override) {
                nextBucketUp.override = highest;
            } else if (nextBucketUp.override > bucket.override) {
                    highest = nextBucketUp.override;
            } else {
                nextBucketUp.override = bucket.override;
                highest = bucket.override;
            }
            this.invOverride.buckets[i - 1] = nextBucketUp;
        }
        // Scanning up buckets to check if the bucket overrides are valid
        for (let i = bucketLength; i > 0; i--) {
            const bucket = this.invOverride.buckets[i];
            const nextBucketUp = this.invOverride.buckets[i - 1];
            if (bucket.override <= nextBucketUp.override) {
                nextBucketUp.isValid = true;
                bucket.isValid = true;
            }
            this.invOverride.buckets[i] = bucket;
        }
    }
}

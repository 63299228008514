<div class="customer-menu" (click)="onToggle()">
    <mat-icon class="menu-icon">swap_horiz</mat-icon>
    <kendo-popup #popup
                 [anchor]="elRef"
                 [anchorAlign]="anchorAlign"
                 [popupAlign]="popupAlign"
                 (anchorViewportLeave)="show = false"
                 *ngIf="show">
        <div #popupContainer class="popup-content">
            <ul>
                <li *ngFor="let item of customerMenuItems">
                    <app-customer-menu-item *ngIf="item.enabled"
                            [menuItem]="item"></app-customer-menu-item>
                </li>
            </ul>
        </div>
    </kendo-popup>
</div>

<kendo-grid [data]="buckets"
            [rowHeight]="5"
            [rowClass]="rowCallback"
            [scrollable]="'virtual'"
            [sortable]="false">
    <kendo-grid-column title="Bucket" field="name" [headerClass]="'ra-grid-header'"
                       [width]="70"></kendo-grid-column>
    <kendo-grid-column title="Override" field="override" [width]="85"
                       [headerClass]="'ra-grid-header'"
                       [class]="{'override-grid-input': true}">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <kendo-numerictextbox
                    [(value)]="dataItem.override"
                    (valueChange)="scanBucketsUp()"
                    [format]="'#\\%'"
                    [style.width.%]="100"
            ></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="20" [headerClass]="'ra-grid-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
            <i *ngIf="!dataItem.protected" class="fa fa-times fa-lg delete-icon"
               (click)="onClear(dataItem)"></i>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>

import {Injectable} from '@angular/core';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/Observable';
import {TubeMapChartResponse} from '../tubemap/tubemap.model';
import {InventoryGridRequest, InventoryModel, WalkUpOverride} from './inventory.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment as env} from '../../environments/environment';
import * as moment from 'moment';
import {UrlStore} from '../shared/url-store';

@Injectable({
    providedIn: 'root'
})
export class InventoryService {

    constructor(private http: HttpClient) {
    }

    public getCapacityChart(filter: any): Observable<any> {
        return this.http.post<any>(UrlStore.api.inventory.capacityChart, filter);
    }

    public getDefaultGridColumns(): Observable<any> {
        return this.http.get<any>(UrlStore.api.inventory.defaultGridColumns);
    }

    public getInventoryMetricColumnNames(): Observable<any> {
        return of({
            data: [
                {id: 'daypart', name: 'Daypart'},
                {id: 'forecastConfidence', name: 'Fcst Confidence'},
                {id: 'compPctDiff', name: 'Competition'},
                {id: 'revenuePacing', name: 'Rev Pacing'},
                {id: 'nextUpdateDate', name: 'Next Update'}
            ]
        });
    }

    public getInventoryFlags(): Observable<any> {
        return of({
            data: [
                {id: 'capacity_constraints', name: 'Capacity Constraints'},
                {id: 'pacing', name: 'Pacing'},
                {id: 'revenue_opportunity', name: 'Revenue Opportunity'},
                {id: 'walkupOverride', name: 'Walk-Up Override'}
            ]
        });
    }

    public getInventoryResults(filter: InventoryGridRequest): Observable<InventoryModel[]> {
        return this.http.post<any>(`${env.apiBaseUrl}/inventory/grid`, filter);
    }

    public getTubeMapData(filter: any): Observable<TubeMapChartResponse> {
        return this.http.post<any>(
            `${env.apiBaseUrl}/inventory/chart/tube`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }

    public getShoulderTrainLegList(rsid: string, effDepartureDate: any): Observable<any> {
        const params = new HttpParams()
            .set('rsid', rsid)
            .set('effDeptDt', effDepartureDate);
        return this.http.get<any>(UrlStore.api.inventory.shoulderChartLegList, {params});
    }

    public getShoulderTrainResults(filter, shoulderType: 'leg' | 'journey'): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/inventory/chart/shoulder_train/${shoulderType}`, filter);
    }

    public getInventoryOverride(filter): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/inventory/override_info`, filter);
    }

    public getWalkUpDemandData(filter: any): Observable<WalkUpOverride[]> {
        const params = new HttpParams().set('requestData', JSON.stringify(filter));
        return this.http.get<any>(UrlStore.api.inventory.walkUpOverride, {params});
    }

    public revertWalkUpOverride(revertRequest: any): Observable<any> {
        const params = new HttpParams().set('requestData', JSON.stringify(revertRequest));
        return this.http.delete<any>(UrlStore.api.inventory.walkUpOverride, {params});
    }

    public saveOverride(overrideData): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/inventory/override`, overrideData);
    }

    public saveBulkOverride(overrideData): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/inventory/bulk_override`, overrideData);
    }

    public saveReminderDates(reminderData): Observable<any> {
        return this.http.post<any>(
            `${env.apiBaseUrl}/inventory/reminders`,
            reminderData);
    }

    public saveWalkUpOverride(overrideData: WalkUpOverride[]): Observable<any> {
        return this.http.post<any>(UrlStore.api.inventory.walkUpOverride, overrideData);
    }

    public updateInventoryStatus(statusOverrideRequest: any): Observable<any> {
        return this.http.post<any>(
            `${env.apiBaseUrl}/inventory/update_status`,
            statusOverrideRequest);
    }

    public updateAutoPilot(request: any): Observable<any> {
        return this.http.post<any>(
            `${env.apiBaseUrl}/inventory/update_auto_pilot`,
            request);
    }

    public revertToSystemValue(request: any): Observable<any> {
        return this.http.post<any>(
            `${env.apiBaseUrl}/inventory/revert`,
            request);
    }

    public approveRecommendation(request: any): Observable<any> {
        return this.http.post<any>(
            `${env.apiBaseUrl}/inventory/approve`,
            request);
    }

    public checkClassConstraint(overrideCheckData): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/inventory/check_class_constraint`, overrideCheckData);
    }
}

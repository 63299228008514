import {AfterViewInit, Component, ElementRef, Input} from '@angular/core';
import {UserComment} from '../comments.model';

@Component({
    selector: 'app-user-comment',
    templateUrl: './user-comment.component.html',
    styleUrls: ['./user-comment.component.scss']
})
export class UserCommentComponent implements AfterViewInit {
    @Input() userComment: UserComment;

    constructor(private elRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.elRef.nativeElement.parentElement.parentElement.scrollTop =
            this.elRef.nativeElement.parentElement.parentElement.scrollHeight;
    }

}

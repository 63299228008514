import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ReminderRequest} from '../models/reminder.model';
import {Observable} from 'rxjs';
import {UrlStore} from '../url-store';

@Injectable({
    providedIn: 'root'
})
export class ReminderService {
    constructor(private http: HttpClient) {
    }

    public deleteReminder(reminderRequest: ReminderRequest): Observable<any> {
        const params = new HttpParams()
            .set('rsid', reminderRequest.rsid)
            .set('passengerClass', reminderRequest.passengerClass)
            .set('departureDate', reminderRequest.effDepartureDate)
            .set('origin', reminderRequest.origin)
            .set('destination', reminderRequest.destination);
        return this.http.delete<any>(`${UrlStore.api.inventory.reminders}`, {params});
    }
}

import {Component, OnInit} from '@angular/core';
import {BaseDashboardComponent} from '../base-dashboard-component';

interface DashComps {
    value: number;
    viewValue: string;
}

@Component({
    selector: 'app-combo-card-select',
    templateUrl: './combo-card-select.component.html',
    styleUrls: ['./combo-card-select.component.scss']
})
export class ComboCardSelectComponent extends BaseDashboardComponent implements OnInit {
    public comps: DashComps[] = [
        {value: 0, viewValue: 'Reminder Reports'},
        {value: 1, viewValue: 'Special Events Impact'},
        {value: 2, viewValue: 'Top Revenue Opportunities'},
        {value: 3, viewValue: 'Override Alerts'},
        {value: 4, viewValue: 'Pacing vs. Remaining Demand'},
        {value: 5, viewValue: 'Top Capacity Contraints'}
    ];
    public selectOpt = this.comps[0];
    public selectIndex = 0;

    selectOption(selection: DashComps) {
        this.selectIndex = selection.value;
    }

    constructor() {
        super();
    }

    ngOnInit() { }

}

<mat-toolbar class="top-bar">
    <div class="left-side">
        <div class="page-title-container">
            <div class="page-title">
                {{pageTitle}}
            </div>
            <div class="status-info">
                {{statusInfo}}
            </div>
        </div>
        
        <div class="left-curve"></div>
    </div>

    <div class="right-curve"></div>

    <div class="right-side">
        <div class="customer-logo-container" [style.display]="showLogo() ? 'inline' : 'none'">
            <div class="logo">
                <img src="{{appLogo}}" alt="{{appTitle}}" (error)="hideLogo()"/>
            </div>
        </div>
        <div class="app-container" [style.display]="!showLogo() ? 'inline' : 'none'">
            <span class="app-title">
                {{appTitle}}
            </span>
        </div>
        <div class="logo-container">
            <div class="logo-text">
                <p style="margin-bottom: -1.5em;">Powered</p>
                <p style="margin-bottom: 0;">by</p>
            </div>
            <div class="logo">
                <img src="../../../../assets/images/RA-Logo-Vert-Blue.png" alt="RA"/>
            </div>
        </div>
    </div>
</mat-toolbar>
import {ChartResult} from '../models/chart-configuration.model';
import {DateUtils} from '../date-utils';

export class ChartUtils {
    public static convertChartRange(chartData: ChartResult[], viewType: string): ChartResult[] {
      if (['Daily', 'Weekly'].includes(viewType)) {
          return chartData.map(item => {
              item.range = DateUtils.convertToLocaleDateString(item.range);
              return item;
          });
      }
      return chartData;
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {StatusReportModel, StatusReportService} from './status-report.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {
    NavigateWithFiltersService,
    SimpleNavigationFilters
} from '../../shared/navigate-with-filters.service';
import {UrlStore} from '../../shared/url-store';
import {ChartConfiguration} from '../../shared/models/chart-configuration.model';

@Component({
    selector: 'app-status-report',
    templateUrl: './status-report.component.html',
    styleUrls: ['./status-report.component.css']
})
export class StatusReportComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    public data = [];
    public totalLabel = '-';
    public statusType = 'Total';
    private totalRecords = 0;

    public chartConfig: ChartConfiguration = {
        items: {
            new: {
                name: 'New',
                color: '#05FF8E'
            },
            needsApp: {
                name: 'Needs Approval',
                color: '#FC6F60'
            },
            auto: {
                name: 'Auto Pilot',
                color: '#92CAFF'
            }
        }
    };

    constructor(private filterService: FilterService, private serv: StatusReportService,
                private navService: NavigateWithFiltersService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(searchOptions => {
            this.loadData(searchOptions);
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }

    public onSeriesClick(event: any): void {
        const filters: SimpleNavigationFilters = {};
        switch (event.series.name) {
            case this.chartConfig.items.new.name:
                filters.statusType = 'New';
                break;
            case this.chartConfig.items.needsApp.name:
                filters.statusType = 'Needs Approval';
                break;
            case this.chartConfig.items.auto.name:
                filters.statusType = 'Needs Approval';
                break;
            default:
                filters.statusType = '';
                break;
        }
        this.navService.navigateWithFilters(UrlStore.ui.inventory, filters);
    }

    public onSeriesHover(event: any): void {
        event.preventDefault();
        this.totalLabel = `${event.value}`;
        this.statusType = `${event.series.name}`;
    }

    public onSeriesLeave(): void {
        this.updateDefaultLabel();
    }

    _onLoadSuccess(resp: StatusReportModel) {
        this.totalRecords = resp.numRecords;
        this.updateDefaultLabel();
        this.data = [resp]; // Kendo UI expects data to be in an array
    }

    private loadData(searchOptions: any): void {
        this.serv.loadStatusReport(searchOptions).subscribe(this._onLoadSuccess.bind(this));
    }

    private updateDefaultLabel(): void {
        this.totalLabel = `${this.totalRecords}`;
        this.statusType = 'Total';
    }
}

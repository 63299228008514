import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {AppMenuItem} from '../core/models/menu/menu-item';
import {FilterService} from '../shared/filter.service';
import {LoadMaskService} from '../shared/load-mask-service';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {UrlStore} from '../shared/url-store';
import {ConfigService} from '../core/services/config/config.service';
import {AppConfiguration} from '../core/models/config';
import {NotificationPopupService} from '../shared/notification-popup/services/notification-popup.service';
import {NotificationPopupModel} from '../shared/notification-popup/shared/models/notification-popup.model';
import {BaseSearchRequest} from '../shared/filter-item.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [FilterService]
})
export class HomeComponent implements OnInit, AfterViewInit {
    @ViewChild('popupContainer', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
    @ViewChild('notificationContainer', {read: ViewContainerRef}) notificationContainer: ViewContainerRef;
    @ViewChild('filtermenu', {static: false}) filterMenu: MatSidenav;
    menuItems: AppMenuItem[];
    searchOptions: BaseSearchRequest;
    filterCollapsed = false;
    public screenLoading = false;
    public parentPopupContainer: any = null;
    private appConfig: AppConfiguration;

    constructor(
        private filterService: FilterService,
        private loadMaskServ: LoadMaskService,
        private router: Router,
        private configService: ConfigService,
        private elRef: ElementRef,
        private notificationPopupSvc: NotificationPopupService
    ) {
        this.filterService.filterTriggered$.subscribe(search => {
            this.searchOptions = search;
            if (this.checkPageFilter()) {
                this.displayFilterAlert();
            }
        });
        this.loadMaskServ.loadingStateChangeTriggered$.subscribe(showHide => {
            this.screenLoading = showHide;
        });
        this.appConfig = this.configService.getAppConfigObject();
        this.applyCssStyleProperties();
    }

    ngOnInit() {
        this.menuItems = [
            {
                title: 'Revenue Manager Dashboard',
                route: '/dashboard',
                visible: () => {
                    return true;
                }
            },
            {
                title: 'Forecast Workbench',
                route: '/forecast',
                visible: () => {
                    return true;
                }
            },
            {
                title: 'Inventory Workbench',
                route: '/inventory',
                visible: () => {
                    return true;
                }
            },
            {
                title: 'Group Sales Quote',
                route: `${UrlStore.ui.landing}${UrlStore.ui.groupSales}`
            },
            {
                title: 'Business Rules',
                route: `${UrlStore.ui.landing}${UrlStore.ui.businessRules}`
            },
            {
                title: 'Business Rules v2',
                route: `${UrlStore.ui.landing}${UrlStore.ui.businessRulesV2}`
            },
            {
                title: 'Status Update Center',
                route: `${UrlStore.ui.landing}${UrlStore.ui.status}`
            }
        ];
    }

    ngAfterViewInit(): void {
        // Hack to handle angular lifecycle ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.parentPopupContainer = this.popupContainer;
        }, 300);
        if (this.filterMenu && this.filterMenu.openedChange) {
            this.filterMenu.openedChange.subscribe(event => {
                this.filterService.toggleFilterExpand(event);
            });
        }
    }

    public toggleFilterMenu() {
        this.filterCollapsed = !this.filterCollapsed;
        if (this.filterCollapsed) {
            this.openFilterMenu();
        } else {
            this.closeFilterMenu();
        }
    }

    public getPageTitle(): string {
        return this.menuItems.find(m => m.route === this.router.url).title;
    }

    public getAppLogo(): string {
        return '../../../../assets/images/' + this.appConfig.customer_id + '_logo.png';
    }

    private applyCssStyleProperties(): void {
        if (!this.appConfig) {
            return;
        }
        this.appConfig.styles.forEach(style => {
            this.elRef.nativeElement.style.setProperty(style.id, style.value);
        });
    }

    private checkPageFilter(): boolean {
        let displayFilterAlert = false;
        const maxDays = this.appConfig.filter_warning_threshold;
        if (Math.abs(this.searchOptions.endDateRange - this.searchOptions.startDateRange + 1) > maxDays &&
            this.searchOptions.portfolio.length > 1) {
            let hasAdditionalFilters = false;
            for (const key of Object.keys(this.searchOptions)) {
                if (['endDateRange', 'startDateRange', 'portfolio'].includes(key)) {
                    continue;
                }
                if (Array.isArray(this.searchOptions[key])) {
                    hasAdditionalFilters = this.searchOptions[key].length > 0;
                }

                if (hasAdditionalFilters) {
                    break;
                }
            }
            displayFilterAlert = !hasAdditionalFilters;
        }
        return displayFilterAlert;
    }

    private displayFilterAlert(): void {
        const maxDays = this.appConfig.filter_warning_threshold.toString();
        const notificationPopup: NotificationPopupModel = {
            notificationType: 'warning',
            message: 'Your filter selections may result in a large set of results being returned ' +
            'and unexpected behavior to the system (charts/tables populating, bulk overrides, etc.). ' +
            'Please limit your search to ' + maxDays + ' days or fewer ' +
            'and a single market or leverage additional criteria to filter further.'
        };
        this.notificationPopupSvc.displayNotification(this.notificationContainer, notificationPopup);
    }

    private openFilterMenu() {
        this.filterMenu.open();
    }

    private closeFilterMenu() {
        this.filterMenu.close();
    }

    isFiltersHidden() {
        if (window.location.href.includes('/status')) {
            return true;
        } else {
            return false;
        }
    }
}

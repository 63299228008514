<div class="ra-radio-container">
    <label>
        <input type="radio" name="departure" value="departure" [(ngModel)]="filterValue.timeType" />
        Departure
    </label>
    <label>
        <input type="radio" name="departure" value="arrival" [(ngModel)]="filterValue.timeType" />
        Arrival
    </label>
</div>
<div class="ra-time-container">
    <kendo-timepicker [(value)]="filterValue.startTime"></kendo-timepicker>
    <kendo-timepicker [(value)]="filterValue.endTime"></kendo-timepicker>
</div>

<mat-card class="ra-fieldset-card ra-card" [style.height.px]="rowHt + 20">
    <mat-card-header class="chart-card-header">
        <mat-card-title class="chart-title">Key Metrics</mat-card-title>
        <div class="select-container">
            <kendo-dropdownlist [data]="comps"
                                [(value)]="selectOpt"
                                [popupSettings]="{appendTo: 'component'}"
                                [textField]="'viewValue'"
                                [valueField]="'value'"
                                (valueChange)="selectOption($event)"></kendo-dropdownlist>
        </div>
    </mat-card-header>
    <mat-card-content style="margin-top: 15px;">
        <app-remind-report [rowHt]="rowHt - 10"
                           [hidden]="selectIndex != 0"></app-remind-report>
        <app-sp-event-impact [rowHt]="rowHt - 10"
                             [hidden]="selectIndex != 1"></app-sp-event-impact>
        <app-top-rev-opp [rowHt]="rowHt - 10"
                         [hidden]="selectIndex != 2"></app-top-rev-opp>
        <app-ovrd-alerts [rowHt]="rowHt - 10"
                         [hidden]="selectIndex != 3"></app-ovrd-alerts>
        <app-pace-vs-rem-dem [rowHt]="rowHt - 10"
                             [hidden]="selectIndex != 4"></app-pace-vs-rem-dem>
        <app-top-cap-const [rowHt]="rowHt - 10"
                           [hidden]="selectIndex != 5"></app-top-cap-const>
    </mat-card-content>
</mat-card>

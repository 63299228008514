<div class="user-menu user-container" (click)="onToggle()">
    <a title="User">
        <img class="user-icon" [src]="this.profilePicUrl" [alt]="this.userInitials"/>
    </a>
    <kendo-popup #popup
                 [anchor]="elRef"
                 [anchorAlign]="anchorAlign"
                 [popupAlign]="popupAlign"
                 (anchorViewportLeave)="show = false"
                 *ngIf="show">
        <div #popupContainer class="popup-content">
            <ul>
                <li>
                    <div class="user-menu-item" (click)="logout()">
                        <div class="menu-item">
                            <mat-icon>logout</mat-icon>
                            <span>Logout</span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="user-menu-item" (click)="showSupport()">
                        <div class="menu-item">
                            <mat-icon>help_outline</mat-icon>
                            <span>Support</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </kendo-popup>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OverrideType} from '../../shared/enums';
import {OverrideRequest, OverrideResponse} from '../../shared/override.model';

@Component({
  selector: 'app-forecast-override',
  templateUrl: './forecast-override.component.html',
  styleUrls: ['./forecast-override.component.scss']
})
export class ForecastOverrideComponent {
  public active = false;
  public overrideObject;
  public overrideType: OverrideType = OverrideType.Single;
  public title: string;
  public overridePct: 0;
  public dmdOverride: 0;

  @Input() public set dataItem(dataItem: OverrideRequest) {
      this.active = dataItem !== undefined;
      if (this.active) {
          this.overrideObject = dataItem.data;
          this.overrideType = dataItem.overrideType;
          this.dmdOverride = dataItem.data.overrideDemand;
          this.setTitle();
      }
  }

  @Output() save: EventEmitter<OverrideResponse> = new EventEmitter();
  @Output() cancel: EventEmitter<OverrideResponse> = new EventEmitter();
  @Output() revert: EventEmitter<OverrideResponse> = new EventEmitter();

  constructor() {
  }

  public onSave(event): void {
      event.preventDefault();
      this.save.emit({
          overrideType: this.overrideType,
          data: [this.overrideObject],
          overridePercent: (this.overridePct / 100),
          overrideDemand: this.dmdOverride
      });
      this.clear();
  }

  public onCancel(event): void {
      event.preventDefault();
      this.closeDialog();
  }

  public closeDialog(): void {
      this.clear();
      this.cancel.emit();
  }

  public onRevert(event): void {
      event.preventDefault();
      this.revert.emit({
          overrideType: this.overrideType,
          data: [this.overrideObject]
      });
      this.clear();
  }

  public checkOverrideType(overrideType: any): boolean {
        return overrideType === this.overrideType;
  }

  public onPercentOverrideChange(input: any): void {
      if (input >= 0 || input <= 0) {
          const pct = input / 100;
          this.dmdOverride = (this.overrideObject.totalForecastDemand * pct) +
              this.overrideObject.totalForecastDemand;
      }
  }

  public bulkInputChange(isPctInput: boolean): void {
      if (isPctInput) {
          if (this.dmdOverride > 0 || this.dmdOverride < 0) {
              this.dmdOverride = 0;
          }
      } else {
          if (this.overridePct > 0 || this.overridePct < 0) {
              this.overridePct = 0;
          }
      }
  }

  private setTitle(): void {
      if (this.overrideType === OverrideType.Bulk) {
          this.title = 'Bulk Demand Overrides';
      } else {
          this.title = 'Demand Overrides';
      }
  }

  private clear(): void {
      this.active = false;
      this.overrideObject = null;
      this.overridePct = 0;
      this.dmdOverride = 0;
  }

}

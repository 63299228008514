import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

@Injectable({
    providedIn: 'root'
})
export class LoadMaskService {

    private loadingStateChangeSubject = new Subject<boolean>();
    public loadingStateChangeTriggered$ = this.loadingStateChangeSubject.asObservable();

    public showMask() {
        this.loadingStateChangeSubject.next(true);
    }

    public hideMask() {
        this.loadingStateChangeSubject.next(false);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabContentDirective} from './directives/tab-content.directive';
import {TabLabelDirective} from './directives/tab-label.directive';
import {TabLabelWrapperDirective} from './directives/tab-label-wrapper.directive';
import {SideTabComponent} from './side-tab/side-tab.component';
import {
    SideTabBodyComponent,
    SideTabBodyPortalDirective
} from './side-tab-body/side-tab-body.component';
import {SideTabGroupComponent} from './side-tab-group/side-tab-group.component';
import {SideTabHeaderComponent} from './side-tab-header/side-tab-header.component';
import {PortalModule} from '@angular/cdk/portal';


@NgModule({
    imports: [
        CommonModule,
        PortalModule
    ],
    exports: [
        SideTabComponent,
        SideTabGroupComponent,
        TabLabelDirective
    ],
    declarations: [
        SideTabBodyComponent,
        SideTabBodyPortalDirective,
        SideTabGroupComponent,
        SideTabComponent,
        TabLabelDirective,
        TabContentDirective,
        SideTabHeaderComponent,
        TabLabelWrapperDirective
    ]
})
export class SideMenuModule {
}

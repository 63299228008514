import {
    Component,
    Input, OnChanges,
    OnInit, SimpleChanges,
} from '@angular/core';
import {RecordCommentDetails, SaveCommentRequest} from '../comments.model';
import {CommentsService} from '../comments.service';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges {
    @Input() commentDetails: RecordCommentDetails;
    public isLoading = true;

    constructor(private commentService: CommentsService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.commentDetails.currentValue === null && changes.commentDetails.firstChange) {
            return;
        }
        if (this.commentDetails.comments === null || this.commentDetails.comments === undefined ||
            this.commentDetails.comments.length === 0) {
            this.commentService.getRecordComments(this.commentDetails).subscribe(results => {
                this.commentDetails.comments = results;
            }, error => {
                console.error(`Error retrieving user comments ${error.message}`);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
        } else {
            this.isLoading = false;
        }
    }

    ngOnInit(): void {
    }

    public onPaste(event: any): void {
        const pasteText = event.clipboardData.getData('text');
        const selection = window.getSelection();
        if (!selection.rangeCount) {
            return;
        }
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(pasteText));
        event.preventDefault();
    }

    public saveComment(event: any, element: any): void {
        event.preventDefault();
        const commentText = element.innerText.trim();
        if (commentText !== '') {
            this.saveUserComment(commentText);
        }
        element.innerText = '';
    }

    private saveUserComment(commentText: string): void {
        this.isLoading = true;
        const saveUserRequest = new SaveCommentRequest(this.commentDetails, commentText);
        this.commentService.saveUserComment(saveUserRequest).subscribe(response => {
            this.commentDetails.comments.push(response);
        }, error => {
            console.error(`Error while saving user comment ${error.message}`);
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

}

import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    Input,
    OnDestroy,
    QueryList,
    ViewChild
} from '@angular/core';
import {coerceNumberProperty} from '@angular/cdk/coercion';
import {Subscription} from 'rxjs/Subscription';
import {DialogComponent} from '@progress/kendo-angular-dialog';
import {SideTabComponent} from '../side-tab/side-tab.component';
import {SideTabHeaderComponent} from '../side-tab-header/side-tab-header.component';

let nextId = 0;

@Component({
    selector: 'app-side-tab-group',
    templateUrl: './side-tab-group.component.html',
    styleUrls: ['./side-tab-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideTabGroupComponent implements AfterContentInit,
    AfterContentChecked, OnDestroy, AfterViewInit {
    @ContentChildren(SideTabComponent) sideTabComponents: QueryList<SideTabComponent>;
    @ViewChild('tabBodyWrapper', {static: false}) tabBodyWrapper: ElementRef;
    @ViewChild('tabHeader', {static: false}) sideTabHeaderComponent: SideTabHeaderComponent;

    private indexToSelect: number | null = 0;
    private tabBodyWrapperHeight = 0;
    public isClosed = true;

    private tabsSubscription = Subscription.EMPTY;
    private tabLabelSubscription = Subscription.EMPTY;

    private dynamicHeight = true;

    @Input()
    get selectedIndex(): number | null {
        return this.pSelectedIndex;
    }

    set selectedIndex(value: number | null) {
        this.indexToSelect = coerceNumberProperty(value, null);
    }

    private pSelectedIndex: number | null = null;

    @Input()
    get animationDuration(): string {
        return this.pAnimationDuration;
    }

    set animationDuration(value: string) {
        this.pAnimationDuration = /^\d+$/.test(value) ? value + 'ms' : value;
    }

    @Input() parentElement?: DialogComponent;

    private pAnimationDuration: string;

    private groupId: number;

    constructor(private elementRef: ElementRef,
                private changeDetectorRef: ChangeDetectorRef) {
        this.groupId = nextId++;
        this.animationDuration = '400ms';
    }

    ngAfterContentChecked() {
        const indexToSelect = this.indexToSelect = this._clampTabIndex(this.indexToSelect);
        if (this.pSelectedIndex !== indexToSelect) {
            Promise.resolve().then(() => {
                this.sideTabComponents.forEach((tab, index) => tab.isActive = index === indexToSelect);
            });
        }

        this.sideTabComponents.forEach((tab: SideTabComponent, index: number) => {
            tab.position = index - indexToSelect;
            if (this.pSelectedIndex != null && tab.position === 0 && !tab.origin) {
                tab.origin = indexToSelect - this.pSelectedIndex;
            }
        });

        if (this.pSelectedIndex !== indexToSelect) {
            this.pSelectedIndex = indexToSelect;
            this.changeDetectorRef.markForCheck();
        }
    }

    ngAfterContentInit() {
        this.tabsSubscription = this.sideTabComponents.changes.subscribe(() => {
            const indexToSelect = this._clampTabIndex(this.indexToSelect);
            if (indexToSelect === this.pSelectedIndex) {
                const tabs = this.sideTabComponents.toArray();
                for (let i = 0; i < tabs.length; i++) {
                    if (tabs[i].isActive) {
                        this.indexToSelect = this.pSelectedIndex = i;
                        break;
                    }
                }
            }

            this.changeDetectorRef.markForCheck();
        });
    }

    ngAfterViewInit() {
        if (this.parentElement) {
            this.elementRef.nativeElement.style.left = this.parentElement.width + 'px';
            this.elementRef.nativeElement.style.top = '0px';
        } else if (this.elementRef.nativeElement.parentElement) {
            this.elementRef.nativeElement.style.left = this.elementRef.nativeElement.parentElement.offsetWidth + 'px';
            this.elementRef.nativeElement.style.top = '0px';
        }
    }

    ngOnDestroy() {
        this.tabsSubscription.unsubscribe();
        this.tabLabelSubscription.unsubscribe();
    }

    private _clampTabIndex(index: number | null): number {
        return Math.min(this.sideTabComponents.length - 1, Math.max(index || 0, 0));
    }

    _getTabLabelId(i: number): string {
        return `side-tab-label-${this.groupId}-${i}`;
    }

    _getTabContentId(i: number): string {
        return `side-tab-content-${this.groupId}-${i}`;
    }

    _setTabBodyWrapperHeight(tabHeight: number): void {
        if (!this.dynamicHeight || !this.tabBodyWrapperHeight) {
            return;
        }

        const wrapper: HTMLElement = this.tabBodyWrapper.nativeElement;
        wrapper.style.height = this.tabBodyWrapperHeight + 'px';
        if (this.tabBodyWrapper.nativeElement.offsetHeight) {
            wrapper.style.height = tabHeight + 'px';
        }
    }

    _removeTabBodyWrapperHeight(): void {
        const wrapper = this.tabBodyWrapper.nativeElement;
        this.tabBodyWrapperHeight = wrapper.clientHeight;
        wrapper.style.height = '';
    }

    _handleClick(tab: SideTabComponent, tabHeader: any, index: number) {
        if (tab.isActive && this.selectedIndex === index && !this.isClosed) {
            this.isClosed = !this.isClosed;
            tab.isActive = !this.isClosed;
        } else {
            this.isClosed = false;
            tab.isActive = true;
        }
        if (!this.isClosed) {
            this.selectedIndex = tabHeader.focusIndex = index;
        }
    }

}

<kendo-dialog *ngIf="active" (close)="closeDialog()">
    <kendo-dialog-titlebar>
        {{title}}
    </kendo-dialog-titlebar>
    <div *ngIf="checkOverrideType('single')">
        <div class="row">
            <div class="col-4">
                <label [for]="sysForecast">System Forecast</label>
                <span #sysForecast>{{overrideObject.totalForecastDemand}}</span>
            </div>
            <div class="col-4">
                <label [for]="override">Numeric Override</label>
                <input #override title="" [(ngModel)]="dmdOverride" style="width: inherit;">
            </div>
            <div class="col-4">
                <label [for]="dmdPct">% Override</label>
                <input #dmdPct title="" [(ngModel)]="overridePct" style="width: inherit;"
                       (ngModelChange)="onPercentOverrideChange($event)">
            </div>
        </div>
    </div>
    <div *ngIf="checkOverrideType('bulk')">
        <div class="row">
            <div class="col-6">
                <label [for]="override">Numeric Override</label>
                <input #override title="" [(ngModel)]="dmdOverride"
                       (ngModelChange)="bulkInputChange(false)"
                       [disabled]="overridePct > 0"
                       style="width: inherit;">
            </div>
            <div class="col-6">
                <label [for]="overrideFactor">% Override</label>
                <input #overrideFactor title="" [(ngModel)]="overridePct"
                       (ngModelChange)=bulkInputChange(true)
                       [disabled]="dmdOverride > 0"
                       style="width: inherit;">
            </div>
        </div>
    </div>
    <kendo-dialog-actions class="ra-dialog-button-group">
        <button class="k-button ra-dialog-button" (click)="onCancel($event)">Cancel</button>
        <button class="k-button ra-dialog-button" (click)="onSave($event)"
                [disabled]="overridePct <= 0 && dmdOverride <= 0">Save</button>
        <button class="k-button ra-dialog-button" (click)="onRevert($event)">Revert Forecast</button>
    </kendo-dialog-actions>
</kendo-dialog>

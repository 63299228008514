import {TooltipTemplatePoint} from '@progress/kendo-angular-charts/dist/es2015/chart/tooltip/tooltip-template-point';
import {IntlService} from '@progress/kendo-angular-intl';

export interface ChartItemConfiguration {
    name: string; // label to display
    color: string; // color of chart element
}

export interface ChartConfiguration {
    chartTitle?: string;
    items: {
        [item: string]: ChartItemConfiguration;
    };
}

export class ChartResult {
    public range: string;
}

export class BaseChart {
    public defaultChartPopupSettings = {
        popupClass: ['default-chart-tooltip-settings']
    };
    private chartFieldTypes = {
        realizedDemand: 'number',
        forecastDemand: 'number',
        forecastOverride: 'number',
        allocation: 'number',
        projectedYield: 'currency',
        lyYield: 'currency',
        tyYield: 'currency',
        revenue: 'currency',
        projectedRevenue: 'currency',
        realizedCumulativeRev: 'currency',
        projectedCumulativeRev: 'currency',
        pacing: 'percent',
        realizedCumulativeBookings: 'number',
        projectedCumulativeBookings: 'number',
        bookings: 'number',
        projectedBookings: 'number',
        actualBookings: 'number',
        forecastBookings: 'number',
        averageBookings: 'number',
        minBookings: 'number',
        maxBookings: 'number'
    };

    constructor(public intl: IntlService) {
    }

    public formatChartTooltipValue(point: TooltipTemplatePoint): string {
        if (point.series.type === 'rangeArea') {
            return `${point.value.from} - ${point.value.to}`;
        }

        if (!this.chartFieldTypes[point.series.field]) {
            return point.value;
        }

        switch (this.chartFieldTypes[point.series.field]) {
            case 'currency':
                return this.intl.formatNumber(point.value, 'c0');
            case 'number':
                return this.intl.formatNumber(point.value, 'n0');
            case 'percent':
                return this.intl.formatNumber(point.value, 'p2');
            default:
                return point.value;
        }
    }
}

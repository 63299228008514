import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {environment as env} from '../../../environments/environment';
import {mergeMap, toArray} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';
import {ConfigService} from '../../core/services/config/config.service';
import {ComponentConfiguration} from '../../core/models/config';

@Injectable({
    providedIn: 'root'
})
export class TopCapConstService {
    private defaultConfig = {
        id: 'topCapConst',
        name: 'Top Capacity Constraints',
        type: 'chart',
        config: {
            metric: {
                fcstPctDmd: {
                    name: 'Top Capacity Constraint',
                    style: {color: '#0B3EF9'}
                }
            }
        }
    };

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    public getComponentConfig(): ComponentConfiguration {
        const config = this.configService.getComponentConfiguration('dashboard', 'topCapConst');
        return config ? config : this.defaultConfig;
    }

    public getTopCapacityConstraints(request: any): Observable<TopCapConstModel[]> {
        return this.http.post<RoseTopCapConstModel[]>(`${env.apiBaseUrl}/dashboard/top_cap_constraints`,
            {
                ...request,
                startDate: moment(request.startDate).format('YYYY-MM-DD'),
                endDate: moment(request.endDate).format('YYYY-MM-DD')
            })
            .pipe(mergeMap(r => this.convertFromRose(r)),
                toArray());
    }

    private convertFromRose(records: RoseTopCapConstModel[]): TopCapConstModel[] {
        const results: TopCapConstModel[] = [];
        records.forEach(record => {
            const effDt = DateUtils.convertToLocaleDateString(record.effDt);
            results.push({
                key: `${record.rsid}::${record.od}::${effDt}`,
                rsid: record.rsid,
                fcstPctOfDmd: record.fcstPctOfDmd,
                od: record.od,
                depDt: effDt
            });
        });
        return results;
    }
}

export class TopCapConstModel {
    rsid: string;
    fcstPctOfDmd: number;
    od: string;
    depDt: string | Date;
    key?: string;
}

class RoseTopCapConstModel {
    rsid: string;
    fcstPctOfDmd: number;
    od: string;
    effDt: string;
}

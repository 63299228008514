import {Injectable, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {NotificationService} from '@progress/kendo-angular-notification';
import {NotificationPopupModel} from '../shared/models/notification-popup.model';

@Injectable({
    providedIn: 'root'
})
export class NotificationPopupService {
    private displayNotificationSubject = new Subject<any>();
    displayNotification$ = this.displayNotificationSubject.asObservable();

    constructor(private notificationSvc: NotificationService) {
    }

    public displayComponentNotification(notification: NotificationPopupModel): void {
        this.displayNotificationSubject.next(notification);
    }

    public displayPageNotification(container: ViewContainerRef, notification: NotificationPopupModel): void {
        this.notificationSvc.show({
            content: notification.message,
            appendTo: container,
            animation: {type: 'fade', duration: 400},
            position: {horizontal: 'center', vertical: 'top'},
            type: {style: notification.notificationType, icon: true},
            closable: true,
            hideAfter: 1000
        });
    }

    public displayNotification(container: ViewContainerRef, notification: NotificationPopupModel): void {
        this.notificationSvc.show({
            content: notification.message,
            appendTo: container,
            animation: {type: 'fade', duration: 400},
            position: {horizontal: 'center', vertical: 'top'},
            type: {style: notification.notificationType, icon: true},
            closable: true,
            hideAfter: 1000
        });
    }
}

import {Injectable} from '@angular/core';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/Observable';
import {
    BookingCurveChartResult,
    BookingCurveDateResult,
    DemandBookingsChartResult,
    DemandForecastChartResult,
    DemandRevenueChartResult,
    DemandYieldChartResult,
    ForecastModel
} from './forecast.model';
import {HttpClient} from '@angular/common/http';
import {environment as env} from '../../environments/environment';
import * as moment from 'moment';
import {ConfigService} from '../core/services/config/config.service';
import {ComponentConfiguration} from '../core/models/config';
import {mergeMap, reduce} from 'rxjs/operators';
import {from} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ForecastService {
    private defaultChartConfigs = {
        dmdFcst: {
            id: 'dmdFcst',
            name: 'Demand Forecast Chart',
            type: 'chart',
            config: {
                metric: {
                    bkdDmd: {
                        name: 'Bkd Dmd',
                        style: {color: '#E6E7E8'}
                    },
                    fcstDmd: {
                        name: 'Fcst Dmd',
                        style: {color: '#0B3EF9'}
                    },
                    fcstOverride: {
                        name: 'Fcst Override',
                        style: {color: '#05053A'}
                    },
                    allocation: {
                        name: 'Allocation',
                        style: {color: '#333333'}
                    },
                    tyYield: {
                        name: 'TY Yield',
                        style: {color: '#71E2AF'}
                    },
                    lyYield: {
                        name: 'LY Yield',
                        style: {color: '#EE7E99'}
                    },
                    events: {
                        name: 'Events'
                    }
                }
            }
        },
        bkgCurve: {
            id: 'bkgCurve',
            name: 'Booking Curve',
            type: 'chart',
            config: {
                metric: {
                    actual: {
                        name: 'Actual',
                        style: {color: '#C7C7C7'}
                    },
                    fcst: {
                        name: 'Forecasted',
                        style: {color: '#0B3EF9'}
                    },
                    avgBkg: {
                        name: 'Average Bookings',
                        style: {color: '#EE7E99'}
                    },
                    bkgRange: {
                        name: 'Booking Range',
                        style: {color: '#EEE8A9'}
                    }
                }
            }
        },
        bookings: {
            id: 'bookings',
            name: 'Bookings',
            type: 'chart',
            config: {
                metric: {
                    booked: {
                        name: 'Booked',
                        style: {color: '#E6E7E8'}
                    },
                    projBookings: {
                        name: 'Proj Bookings',
                        style: {color: '#0B3EF9'}
                    },
                    cumuBookings: {
                        name: 'Cumulative Bookings',
                        style: {color: '#C7C7C7'}
                    },
                    projCumuBookings: {
                        name: 'Proj Cumulative Bookings',
                        style: {color: '#101D91'}
                    },
                    pacing: {
                        name: 'Pacing %',
                        style: {color: '#C7C7C7'}
                    }
                }
            }
        },
        rev: {
            id: 'rev',
            name: 'Revenue',
            type: 'chart',
            config: {
                metric: {
                    bkdRev: {
                        name: 'Bkd Rev',
                        style: {color: '#E6E7E8'}
                    },
                    projRev: {
                        name: 'Proj Rev',
                        style: {color: '#0B3EF9'}
                    },
                    bkdCumuRev: {
                        name: 'Bkd Cumulative Rev',
                        style: {color: '#C7C7C7'}
                    },
                    projCumuRev: {
                        name: 'Proj Cumulative Rev',
                        style: {color: '#101D91'}
                    },
                    pacing: {
                        name: 'Pacing %',
                        style: {color: '#C7C7C7'}
                    }
                }
            }
        },
        yield: {
            id: 'yield',
            name: 'Yield',
            type: 'chart',
            config: {
                metric: {
                    tyYield: {
                        name: 'TY Yield',
                        style: {color: '#71E2AF'}
                    },
                    lyYield: {
                        name: 'LY Yield',
                        style: {color: '#EE7E99'}
                    }
                }
            }
        }
    };

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    public getComponentConfig(componentId: string): ComponentConfiguration {
        const config = this.configService.getComponentConfiguration('forecast', componentId);
        return config ? config : this.defaultChartConfigs[componentId];
    }

    public getDefaultGridColumns(): Observable<any> {
        return this.http.get<any>(`${env.apiBaseUrl}/forecast/default_grid_columns`);
    }

    public getForecastResults(filter: any): Observable<ForecastModel[]> {
        return this.http.post<ForecastModel[]>(
            `${env.apiBaseUrl}/forecast/grid`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }

    public getForecastMetricColumnNames(): Observable<any> {
        return of({
            data: [
                {id: 'projectedDemandPacing', name: 'Projected Demand Pacing'},
                {id: 'projectedYield', name: 'Projected Yield'},
                {id: 'lyFinalYield', name: 'LY Final Yield'},
                {id: 'intradayOTB', name: 'OTB Δ'}
            ]
        });
    }

    public update(item: any): Observable<any> {
        const chunkSize = 8000;
        const overrideRequests = [];
        if (item.data.length > 1) {
            const overrideChunks = [];
            let i = 0;
            const chunks = Math.ceil(item.data.length / chunkSize);
            while (i < chunks) {
                overrideChunks[i] = item.data.splice(0, chunkSize);
                i++;
            }
            for (i = 0; i < overrideChunks.length; i++) {
                overrideRequests[i] = {...item};
                overrideRequests[i].data = overrideChunks[i];
            }
        } else {
            overrideRequests.push(item);
        }
        return from(overrideRequests).pipe(mergeMap(request =>
            this.http.post<any>(`${env.apiBaseUrl}/forecast/override`, request), overrideRequests.length),
            reduce((acc, value) => acc, undefined));
    }

    public revert(item: any): Observable<any> {
        item.minDate = moment(item.minDate).format('YYYY-MM-DD');
        item.maxDate = moment(item.maxDate).format('YYYY-MM-DD');
        return this.http.post<any>(`${env.apiBaseUrl}/forecast/revert`, item);
    }

    public getRevenueChartData(filter: any): Observable<DemandRevenueChartResult[]> {
        return this.http.post<DemandRevenueChartResult[]>(
            `${env.apiBaseUrl}/forecast/chart/revenue`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }

    public getYieldChartData(filter: any): Observable<DemandYieldChartResult[]> {
        return this.http.post<DemandYieldChartResult[]>(
            `${env.apiBaseUrl}/forecast/chart/yield`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }

    public getBookingsChartData(filter: any): Observable<DemandBookingsChartResult[]> {
        return this.http.post<DemandBookingsChartResult[]>(
            `${env.apiBaseUrl}/forecast/chart/bookings`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }

    public getBookingCurveDates(filter: any): Observable<BookingCurveDateResult[]> {
        return this.http.post<BookingCurveDateResult[]>(
            `${env.apiBaseUrl}/forecast/bookingcurvedates`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }

    public getBookingCurveChartData(filter: any): Observable<BookingCurveChartResult[]> {
        return this.http.post<BookingCurveChartResult[]>(
            `${env.apiBaseUrl}/forecast/chart/bookingcurve`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD'),
                departureDate: moment(filter.departureDate).format('YYYY-MM-DD')
            });
    }

    public getForecastChartData(filter: any): Observable<DemandForecastChartResult[]> {
        return this.http.post<DemandForecastChartResult[]>(
            `${env.apiBaseUrl}/forecast/chart/forecast`,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            });
    }
}

<ng-container #popupContainer></ng-container>
<div class="nocontainer">
  <app-forecast-breadcrumb [style.padding-left.px]="16"></app-forecast-breadcrumb>
  <div class="row">
    <div class="col col-50pct">
        <app-forecast-chart #forecastChart></app-forecast-chart>
    </div>
    <div class="col col-50pct">
      <mat-card class="ra-card" [style.height.px]="430">
        <mat-card-title class="card-title">
          Performance Trends
        </mat-card-title>
        <mat-card-content class="chart-card-content">
          <div class="spinner-wrapper">
            <mat-tab-group style="height: 100%;">
              <mat-tab label="Booking Curve">
                <ng-template matTabContent>
                  <div>
                    <div style="position: relative; display: flex">
                      <kendo-dropdownlist
                        [data]="bookingCurveDates.allValues"
                        [(value)]="bookingCurveDates.selectedValues"
                        [popupSettings]="{appendTo: 'component'}"
                        [textField]="'name'"
                        [valueField]="'id'"
                        class="filter-drop-down"
                        [style.margin-left.%]="77"
                        (selectionChange)="bookingCurveDateChange($event)"
                      >
                      </kendo-dropdownlist>
                    </div>
                    <div class="bc-chart-container">
                      <kendo-chart class="forecast-chart" [popupSettings]="trendChartPopupSettings">
                        <kendo-chart-tooltip [shared]="true">
                          <ng-template
                            kendoChartSharedTooltipTemplate
                            let-category="category"
                            let-points="points"
                          >
                              <div class="chart-tooltip-category">{{category}}</div>
                              <div class="chart-tooltip-container">
                                  <ng-container *ngFor="let point of points">
                                      <div class="chart-tooltip-point-color">
                                          <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                                      </div>
                                      <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                                      <div class="chart-tooltip-point-value">{{formatChartTooltipValue(point)}}</div>
                                  </ng-container>
                              </div>
                          </ng-template>
                        </kendo-chart-tooltip>
                        <kendo-chart-legend
                          position="top"
                          orientation="horizontal"
                        ></kendo-chart-legend>
                        <kendo-chart-category-axis>
                          <kendo-chart-category-axis-item
                            [majorGridLines]="{ visible: false }"
                            [labels]="{ visible: true, step: 2 }"
                          >
                          </kendo-chart-category-axis-item>
                        </kendo-chart-category-axis>
                        <kendo-chart-series>
                          <kendo-chart-series-item
                            [style]="'smooth'"
                            type="line"
                            [data]="bookingCurveChartData"
                            [name]="bkgCurvChartConfig.config.metric.actual.name"
                            [color]="bkgCurvChartConfig.config.metric.actual.style.color"
                            [categoryField]="'range'"
                            [field]="'actualBookings'"
                            [labels]="{ step: 2 }"
                            [markers]="{size: 3}"
                          >
                          </kendo-chart-series-item>
                          <kendo-chart-series-item
                            [style]="'smooth'"
                            type="line"
                            dashType="dash"
                            [data]="bookingCurveChartData"
                            [name]="bkgCurvChartConfig.config.metric.fcst.name"
                            [color]="bkgCurvChartConfig.config.metric.fcst.style.color"
                            [categoryField]="'range'"
                            [field]="'forecastBookings'"
                            [markers]="{size: 3}"
                          >
                          </kendo-chart-series-item>
                          <kendo-chart-series-item
                            [style]="'smooth'"
                            type="line"
                            [data]="bookingCurveChartData"
                            [name]="bkgCurvChartConfig.config.metric.avgBkg.name"
                            [color]="bkgCurvChartConfig.config.metric.avgBkg.style.color"
                            [categoryField]="'range'"
                            [field]="'averageBookings'"
                            [markers]="{size: 3}"
                          >
                          </kendo-chart-series-item>
                          <kendo-chart-series-item
                            type="rangeArea"
                            [data]="bookingCurveChartData"
                            [name]="bkgCurvChartConfig.config.metric.bkgRange.name"
                            [color]="bkgCurvChartConfig.config.metric.bkgRange.style.color"
                            fromField="minBookings"
                            toField="maxBookings"
                            categoryField="range"
                          >
                          </kendo-chart-series-item>
                        </kendo-chart-series>
                      </kendo-chart>
                        <div *ngIf="bookingCurveChartLoading" class="k-i-loading"></div>
                    </div>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="Bookings">
                <ng-template matTabContent>
                    <kendo-chart class="forecast-chart" [popupSettings]="trendChartPopupSettings">
                    <kendo-chart-tooltip [shared]="true">
                      <ng-template
                        kendoChartSharedTooltipTemplate
                        let-category="category"
                        let-points="points"
                      >
                        <div class="chart-tooltip-category">{{category}}</div>
                          <div class="chart-tooltip-container">
                              <ng-container *ngFor="let point of points">
                                  <div class="chart-tooltip-point-color">
                                      <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                                  </div>
                                  <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                                  <div class="chart-tooltip-point-value">{{formatChartTooltipValue(point)}}</div>
                              </ng-container>
                          </div>
                      </ng-template>
                    </kendo-chart-tooltip>
                    <kendo-chart-panes>
                      <kendo-chart-pane name="top"></kendo-chart-pane>
                      <kendo-chart-pane name="bottom"></kendo-chart-pane>
                    </kendo-chart-panes>
                    <kendo-chart-legend
                      position="top"
                      orientation="horizontal"
                    ></kendo-chart-legend>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item
                        [title]="{ text: 'Bookings' }"
                        [name]="'bookings'"
                        [labels]="{ step: 2 }"
                        [minorGridLines]="{ step: 2 }"
                        [majorGridLines]="{ step: 2 }"
                        pane="top"
                      ></kendo-chart-value-axis-item>
                      <kendo-chart-value-axis-item
                        [title]="{ text: 'Cumulative Bookings' }"
                        [name]="'cumulativeBookings'"
                        [labels]="{ step: 2 }"
                        [minorGridLines]="{ step: 2 }"
                        [majorGridLines]="{ step: 2 }"
                        pane="top"
                      ></kendo-chart-value-axis-item>
                      <kendo-chart-value-axis-item
                        [title]="{ text: 'Pacing %' }"
                        [name]="'pacing'"
                        [minorGridLines]="{ step: 3 }"
                        [majorGridLines]="{ step: 3 }"
                        pane="bottom">
                          <kendo-chart-value-axis-item-labels format="p" step="3"></kendo-chart-value-axis-item-labels>
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item
                        [axisCrossingValue]="[0, 100]"
                        pane="bottom"
                        [majorGridLines]="{ visible: false }"
                      >
                        <kendo-chart-category-axis-item-labels
                          [rotation]="270"
                          [step]="1"
                          [position]="'start'"
                        ></kendo-chart-category-axis-item-labels>
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item
                        [data]="bookingChartData"
                        [name]="bkgsChartConfig.config.metric.booked.name"
                        [categoryField]="'range'"
                        [axis]="'bookings'"
                        [field]="'bookings'"
                        [color]="bkgsChartConfig.config.metric.booked.style.color"
                        [stack]="true"
                      >
                      </kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="bookingChartData"
                        [name]="bkgsChartConfig.config.metric.projBookings.name"
                        [categoryField]="'range'"
                        [axis]="'bookings'"
                        [field]="'projectedBookings'"
                        [color]="bkgsChartConfig.config.metric.projBookings.style.color"
                        [stack]="true"
                      >
                      </kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="bookingChartData"
                        [name]="bkgsChartConfig.config.metric.cumuBookings.name"
                        [color]="bkgsChartConfig.config.metric.cumuBookings.style.color"
                        [categoryField]="'range'"
                        [axis]="'cumulativeBookings'"
                        [field]="'realizedCumulativeBookings'"
                        [style]="'smooth'"
                        type="line"
                        dashType="dash"
                      ></kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="bookingChartData"
                        [name]="bkgsChartConfig.config.metric.projCumuBookings.name"
                        [color]="bkgsChartConfig.config.metric.projCumuBookings.style.color"
                        [categoryField]="'range'"
                        [axis]="'cumulativeBookings'"
                        [field]="'projectedCumulativeBookings'"
                        [style]="'smooth'"
                        type="line"
                      ></kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="bookingChartData"
                        [name]="bkgsChartConfig.config.metric.pacing.name"
                        [color]="bkgsChartConfig.config.metric.pacing.style.color"
                        [categoryField]="'range'"
                        [axis]="'pacing'"
                        [field]="'pacing'"
                      ></kendo-chart-series-item>
                    </kendo-chart-series>
                  </kendo-chart>
                    <div *ngIf="bookingChartLoading" class="k-i-loading"></div>
                </ng-template>
              </mat-tab>
              <mat-tab label="Revenue">
                <ng-template matTabContent>
                    <kendo-chart class="forecast-chart" [popupSettings]="trendChartPopupSettings">
                    <kendo-chart-tooltip [shared]="true">
                      <ng-template
                        kendoChartSharedTooltipTemplate
                        let-category="category"
                        let-points="points"
                      >
                        <div class="chart-tooltip-category">{{category}}</div>
                          <div class="chart-tooltip-container">
                              <ng-container *ngFor="let point of points">
                                  <div class="chart-tooltip-point-color">
                                      <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                                  </div>
                                  <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                                  <div class="chart-tooltip-point-value">{{formatChartTooltipValue(point)}}</div>
                              </ng-container>
                          </div>
                      </ng-template>
                    </kendo-chart-tooltip>
                    <kendo-chart-panes>
                      <kendo-chart-pane name="top"></kendo-chart-pane>
                      <kendo-chart-pane name="bottom"></kendo-chart-pane>
                    </kendo-chart-panes>
                    <kendo-chart-legend
                      position="top"
                      orientation="horizontal"
                    ></kendo-chart-legend>
                    <kendo-chart-value-axis>
                      <kendo-chart-value-axis-item
                        [title]="{ text: 'Bkd Rev' }"
                        [name]="'revenue'"
                        [labels]="{ step: 2 }"
                        [minorGridLines]="{ step: 2 }"
                        [majorGridLines]="{ step: 2 }"
                        pane="top"
                      ></kendo-chart-value-axis-item>
                      <kendo-chart-value-axis-item
                        [title]="{ text: 'Cumulative Revenue' }"
                        [name]="'cumulativeRevenue'"
                        [labels]="{ step: 2 }"
                        [minorGridLines]="{ step: 2 }"
                        [majorGridLines]="{ step: 2 }"
                        pane="top"
                      ></kendo-chart-value-axis-item>
                      <kendo-chart-value-axis-item
                        [title]="{ text: 'Pacing %' }"
                        [name]="'pacing'"
                        [minorGridLines]="{ step: 3 }"
                        [majorGridLines]="{ step: 3 }"
                        pane="bottom">
                          <kendo-chart-value-axis-item-labels format="p" step="3"></kendo-chart-value-axis-item-labels>
                      </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                      <kendo-chart-category-axis-item
                        [axisCrossingValue]="[0, 100]"
                        pane="bottom"
                        [majorGridLines]="{ visible: false }"
                      >
                        <kendo-chart-category-axis-item-labels
                          [rotation]="270"
                          [step]="1"
                          [position]="'start'"
                        ></kendo-chart-category-axis-item-labels>
                      </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                      <kendo-chart-series-item
                        [data]="revenueChartData"
                        [name]="revChartConfig.config.metric.bkdRev.name"
                        [categoryField]="'range'"
                        [axis]="'revenue'"
                        [field]="'revenue'"
                        [color]="revChartConfig.config.metric.bkdRev.style.color"
                        [stack]="true"
                      >
                      </kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="revenueChartData"
                        [name]="revChartConfig.config.metric.projRev.name"
                        [categoryField]="'range'"
                        [axis]="'revenue'"
                        [field]="'projectedRevenue'"
                        [color]="revChartConfig.config.metric.projRev.style.color"
                        [stack]="true"
                      >
                      </kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="revenueChartData"
                        [name]="revChartConfig.config.metric.bkdCumuRev.name"
                        [color]="revChartConfig.config.metric.bkdCumuRev.style.color"
                        [categoryField]="'range'"
                        [axis]="'cumulativeRevenue'"
                        [field]="'realizedCumulativeRev'"
                        [style]="'smooth'"
                        type="line"
                        dashType="dash"
                      ></kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="revenueChartData"
                        [name]="revChartConfig.config.metric.projCumuRev.name"
                        [color]="revChartConfig.config.metric.projCumuRev.style.color"
                        [categoryField]="'range'"
                        [axis]="'cumulativeRevenue'"
                        [field]="'projectedCumulativeRev'"
                        [style]="'smooth'"
                        type="line"
                      ></kendo-chart-series-item>
                      <kendo-chart-series-item
                        [data]="revenueChartData"
                        [name]="revChartConfig.config.metric.pacing.name"
                        [color]="revChartConfig.config.metric.pacing.style.color"
                        [categoryField]="'range'"
                        [axis]="'pacing'"
                        [field]="'pacing'"
                        axis="pacing"
                      ></kendo-chart-series-item>
                    </kendo-chart-series>
                  </kendo-chart>
                    <div *ngIf="revenueChartLoading" class="k-i-loading"></div>
                </ng-template>
              </mat-tab>
              <mat-tab label="Yield">
                <ng-template matTabContent>
                    <kendo-chart class="forecast-chart" [popupSettings]="defaultChartPopupSettings">
                    <kendo-chart-tooltip [shared]="true">
                      <ng-template
                        kendoChartSharedTooltipTemplate
                        let-category="category"
                        let-points="points"
                      >
                        <div class="chart-tooltip-category">{{category}}</div>
                          <div class="chart-tooltip-container">
                              <ng-container *ngFor="let point of points">
                                  <div class="chart-tooltip-point-color">
                                      <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                                  </div>
                                  <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                                  <div class="chart-tooltip-point-value">{{formatChartTooltipValue(point)}}</div>
                              </ng-container>
                          </div>
                      </ng-template>
                    </kendo-chart-tooltip>
                    <kendo-chart-legend
                      position="top"
                      orientation="horizontal"
                    ></kendo-chart-legend>
                    <kendo-chart-series>
                      <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item>
                          <kendo-chart-category-axis-item-labels
                            [rotation]="270"
                            [step]="1"
                            [position]="'start'"
                          ></kendo-chart-category-axis-item-labels>
                        </kendo-chart-category-axis-item>
                      </kendo-chart-category-axis>
                      <kendo-chart-series-item
                        type="line"
                        [data]="yieldChartData"
                        [name]="yieldChartConfig.config.metric.tyYield.name"
                        [categoryField]="'range'"
                        [field]="'tyYield'"
                        [color]="yieldChartConfig.config.metric.tyYield.style.color"
                      ></kendo-chart-series-item>
                      <kendo-chart-series-item
                        type="line"
                        [data]="yieldChartData"
                        [name]="yieldChartConfig.config.metric.lyYield.name"
                        [categoryField]="'range'"
                        [field]="'lyYield'"
                        [color]="yieldChartConfig.config.metric.lyYield.style.color"
                        dashType="dash"
                      ></kendo-chart-series-item>
                    </kendo-chart-series>
                  </kendo-chart>
                    <div *ngIf="yieldChartLoading" class="k-i-loading"></div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card class="ra-card">
        <mat-card-content>
          <div class="spinner-wrapper">
            <div class="grid-toolbar">
              <label [style.font-weight]="'bold'" [style.margin-right.px]="4"
                >Metrics:
              </label>
              <kendo-multiselect
                [data]="fcstMetricColumns.allValues"
                [(value)]="fcstMetricColumns.selectedValues"
                [popupSettings]="{appendTo: 'component'}"
                [textField]="'name'"
                [valueField]="'id'"
                [autoClose]="false"
                (valueChange)="onValueChange($event)"
                class="filter-drop-down multi-select"
              ></kendo-multiselect>
              <button kendoButton (click)="bulkOverride()">
                Bulk Overrides
              </button>
              <button kendoButton (click)="excelExport.openDialog()">
                Export View
              </button>
              <button
                kendoButton
                class="btn-pull-right"
                [disabled]="disableReOpt()"
                (click)="triggerReOpt()"
              >
                Re-Optimize
              </button>

              <kendo-dialog *ngIf="opened">
                <kendo-dialog-titlebar>
                  <div style="font-size: 18px; line-height: 1.3em;">
                    <span class="k-icon k-i-warning"></span> Re-Optimization
                  </div>
                </kendo-dialog-titlebar>
                <p style="margin: 30px; text-align: center;">
                  Optimization with override completed.
                </p>
                <kendo-dialog-actions [layout]="actionsLayout">
                  <button kendoButton (click)="onDialogClose()">Close</button>
                  <button kendoButton (click)="onDeleteData()" primary="true">
                    Go to Inventory
                  </button>
                </kendo-dialog-actions>
              </kendo-dialog>
            </div>
            <div>
                <div kendoTooltip
                     showOn="none"
                     filter=".k-grid td"
                     position="right"
                     [showAfter]="275"
                     [titleTemplate]="titleTemplate"
                     [tooltipTemplate]="template">
                    <ng-template #titleTemplate>
                        {{ hoveredGrid?.rsid }}
                    </ng-template>
                    <ng-template #template>
                        {{ hoveredGrid?.trainDepartureTime }} {{ hoveredGrid?.trainOriginDestination }}
                    </ng-template>
                    <kendo-grid
                [data]="gridView"
                [skip]="skip"
                [pageSize]="pageSize"
                (pageChange)="pageChange($event)"
                [sortable]="true"
                [sort]="gridSort"
                (sortChange)="sortChange($event)"
                [selectable]="tableGrid.selectableSettings"
                [rowHeight]="tableGrid.options.rowHeight"
                [height]="tableGrid.options.height"
                [kendoGridSelectBy]="selectionKey"
                [selectedKeys]="selectedRows"
                [scrollable]="'virtual'"
                (cellClick)="cellClickHandler($event)"
                (selectedKeysChange)="onSelectedKeysChange($event)"
                (mouseover)="onMouseOver($event)"
              >
                <kendo-grid-checkbox-column
                  showSelectAll="true"
                  *ngIf="tableGrid.options.selectAble"
                  sortable="false"
                  [headerClass]="'ra-grid-header ra-grid-checkbox'"
                  [class]="'ra-grid-checkbox'"
                  [width]="30"
                >
                  <ng-template kendoGridHeaderTemplate>
                    <input
                      class="k-checkbox"
                      kendoGridSelectAllCheckbox
                      id="selectAllCheckboxId"
                      [state]="selectAllState"
                      (selectAllChange)="onSelectAllChange($event)"
                    />
                    <label
                      class="k-checkbox-label"
                      for="selectAllCheckboxId"
                    ></label>
                  </ng-template>
                </kendo-grid-checkbox-column>
                <kendo-grid-column
                  title="Date"
                  field="gridDate"
                  [headerClass]="'ra-grid-header'"
                  format="d"
                  [width]="90"
                  [hidden]="getDefaultGridColumnVal('gridDate')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Service"
                  field="rsid"
                  [headerClass]="'ra-grid-header'"
                  [width]="85"
                  [hidden]="getDefaultGridColumnVal('rsid')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Journey"
                  field="originDestination"
                  [headerClass]="'ra-grid-header'"
                  [width]="85"
                  [hidden]="!checkViewLevelColumn() || getDefaultGridColumnVal('originDestination')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Leg"
                  field="leg"
                  [headerClass]="'ra-grid-header'"
                  [hidden]="checkViewLevelColumn()"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Fcst Conf"
                  field="forecastConfidence"
                  [headerClass]="'ra-grid-header'"
                  [width]="75"
                  [style]="{ 'text-align': 'center' }"
                >
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="{{ getConfidenceIcon(dataItem) }}"></span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  title="Sp Events"
                  [width]="85"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="specialEvents"
                  [hidden]="getDefaultGridColumnVal('specialEvents')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="VLY Dmd Pace"
                  format="{0:p}"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="vlyDemandPacing"
                  [hidden]="getDefaultGridColumnVal('vlyDemandPacing')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="LY Adv Bkgs"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="lyAdvancedBookings"
                  [hidden]="getDefaultGridColumnVal('lyAdvancedBookings')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Bkd Ind Dmd"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="otbIndividualDemand"
                  [hidden]="getDefaultGridColumnVal('otbIndividualDemand')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Bkd NonFcst Dmd"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="otbGroupDemand"
                  [hidden]="getDefaultGridColumnVal('otbGroupDemand')"
                ></kendo-grid-column>
                 <kendo-grid-column
                  title="OTB Δ"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="intradayOTB"
                  [hidden]="getDefaultGridColumnVal('intradayOTB') || checkHiddenColumn('intradayOTB')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Fcst Rem Dmd"
                  format="{0:0}"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="remainingForecastDemand"
                  [hidden]="getDefaultGridColumnVal('remainingForecastDemand')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Fcst Total Dmd"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true, 'col-clickable': true}"
                  field="totalForecastDemand"
                  [hidden]="getDefaultGridColumnVal('totalForecastDemand')"
                  [style]="{ cursor: 'pointer' }"
                >
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div
                      style="width: 100%;"
                      (click)="singleOverride(dataItem)"
                    >
                      <span class="{{ dataItem.overridden ? 'override' : '' }}">
                        {{ getTotalForecastDmd(dataItem) }}
                      </span>
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column
                  title="Lead Bucket"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="leadBucket"
                  [hidden]="getDefaultGridColumnVal('leadBucket')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Fcst Total Dmd Ovrd"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="overrideDemand"
                  [hidden]="true"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Proj Bkd %"
                  [width]="85"
                  format="{0:p}"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="forecastedBookedPercent"
                  [hidden]="getDefaultGridColumnVal('forecastedBookedPercent')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Proj Rev"
                  [width]="95"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  format="{0:c0}"
                  field="projectedRevenue"
                  [hidden]="getDefaultGridColumnVal('projectedRevenue')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Proj Rev Pace"
                  format="{0:p}"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="projectedRevenuePacing"
                  [hidden]="getDefaultGridColumnVal('projectedRevenuePacing')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Unconstraining Level"
                  [headerClass]="'ra-grid-header'"
                  field="unconstrainingLevel"
                  width="0"
                  [hidden]="getDefaultGridColumnVal('unconstrainingLevel') || checkHiddenColumn('unconstrainingLevel')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Competitor Price Threat"
                  [headerClass]="'ra-grid-header'"
                  field="competitorPriceThreat"
                  width="0"
                  [hidden]="getDefaultGridColumnVal('competitorPriceThreat') || checkHiddenColumn('competitorPriceThreat')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Proj Dmd Pace"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  field="projectedDemandPacing"
                  format="{0:p}"
                 [hidden]="getDefaultGridColumnVal('projectedDemandPacing') || checkHiddenColumn('projectedDemandPacing')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="Proj Yield"
                  field="projectedYield"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  format="{0:c0}"
                  [hidden]="getDefaultGridColumnVal('projectedYield') || checkHiddenColumn('projectedYield')"
                ></kendo-grid-column>
                <kendo-grid-column
                  title="LY Final Yield"
                  field="lyFinalYield"
                  [headerClass]="['ra-grid-header', 'col-pull-right']"
                  [class]="{'col-pull-right': true}"
                  format="{0:c0}"
                  [hidden]="getDefaultGridColumnVal('lyFinalYield') || checkHiddenColumn('lyFinalYield')"
                ></kendo-grid-column>
              </kendo-grid>
                </div>
              <app-forecast-override
                [dataItem]="overrideRequestItem"
                (save)="saveHandler($event)"
                (cancel)="cancelHandler()"
                (revert)="revertHandler($event)"
              >
              </app-forecast-override>
              <app-excel-export
                #excelExport
                [gridData]="gridData"
                [gridColumns]="gridColumns"
                [filterData]="filterData"
                [filterColumns]="filterColumns"
              >
              </app-excel-export>
            </div>
            <div *ngIf="this.forecastGridLoading" class="k-i-loading"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
<div kendoDialogContainer></div>

import {Component, Input, OnInit} from '@angular/core';
import {DialogAction} from '@progress/kendo-angular-dialog';
import {RowArgs, SelectableSettings} from '@progress/kendo-angular-grid';
import {FilterToolbarService} from '../shared/filter-toolbar.service';
import {FilterView} from '../shared/filter-view.model';

@Component({
    selector: 'app-open-filter-btn',
    templateUrl: './open-filter-btn.component.html',
    styleUrls: ['./open-filter-btn.component.scss']
})
export class OpenFilterBtnComponent implements OnInit {
    public filtersData: FilterView[];
    @Input() set filters(filterViews: FilterView[]) {
        this.filtersData = filterViews;
        this.gridLoading = false;
    }
    public dialogActions = [
        {text: 'Cancel'},
        {text: 'Load'}
    ];
    public deleteDialogActions = [
        {text: 'Cancel'},
        {text: 'OK'}
    ];

    public deleteDialogOpened = false;
    public dialogOpened = false;
    public gridLoading = false;
    public selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: 'single'
    };
    public selectedFilter: FilterView[] = [];

    constructor(private filterToolbarService: FilterToolbarService) { }

    ngOnInit() {
    }

    public onAction(action: DialogAction): void {
        if (action.text === 'Load') {
            this.loadFilter();
        } else if (action.text === 'OK') {
            this.deleteFilter();
        } else {
            this.cancel();
        }
    }

    public close(): void {
        if (this.deleteDialogOpened) {
            this.deleteDialogOpened = false;
        } else {
            this.closeAndClearSelected();
        }
    }

    public confirmDeleteFilter(filterItem: FilterView): void {
        this.selectedFilter = [filterItem];
        this.deleteDialogOpened = true;
    }

    public favoriteFilter(filterItem: FilterView): void {
        filterItem.isFavorite = !filterItem.isFavorite;
        this.filterToolbarService.favoriteFilter(filterItem);
    }

    public gridSelectionKey(context: RowArgs): any {
        return context.dataItem;
    }

    public open(): void {
        this.dialogOpened = true;
    }

    private cancel(): void {
        this.close();
    }

    private closeAndClearSelected(): void {
        this.dialogOpened = false;
        this.selectedFilter = [];
    }

    private deleteFilter(): void {
        this.filterToolbarService.deleteFilter(this.selectedFilter[0].name);
        this.deleteDialogOpened = false;
        this.selectedFilter = [];
        this.gridLoading = true;
    }

    private loadFilter(): void {
        if (!(this.selectedFilter.length > 0)) {
            return;
        }
        this.filterToolbarService.loadFilter(this.selectedFilter[0]);
        this.closeAndClearSelected();
    }

}

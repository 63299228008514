import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {UrlStore} from '../../shared/url-store';
import {DashboardRevenueChart} from './dashboard.model';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {ChartUtils} from '../../shared/helpers/chart-utils';
import {ConfigService} from '../../core/services/config/config.service';
import {ComponentConfiguration} from '../../core/models/config';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private defaultRevChartConfig = {
        id: 'revenue',
        name: 'Revenue',
        type: 'chart',
        config: {
            metric: {
                rev: {
                    name: 'Bkd Rev',
                    style: {color: '#E6E7E8'}
                },
                projRev: {
                    name: 'Proj Rev',
                    style: {color: '#0B3EF9'}
                },
                lyYield: {
                    name: 'LY Yield',
                    style: {color: '#EE7E99'}
                },
                tyYield: {
                    name: 'TY Yield',
                    style: {color: '#71E2AF'}
                },
                pacing: {
                    name: 'Pacing %',
                    style: {color: '#C7C7C7'}
                }
            }
        }
    };

    constructor(private http: HttpClient, private configService: ConfigService) {
    }

    public getComponentConfig(): ComponentConfiguration {
        // Used to resolve the revenue chart configuration. Will need to move to separate component later
        const config = this.configService.getComponentConfiguration('dashboard', 'revenue');
        return config ? config : this.defaultRevChartConfig;
    }

    public getRevenueChartData(filter: any): Observable<DashboardRevenueChart[]> {
        return this.http.post<DashboardRevenueChart[]>(
            UrlStore.api.dashboard.revenueChart,
            {
                ...filter,
                startDate: moment(filter.startDate).format('YYYY-MM-DD'),
                endDate: moment(filter.endDate).format('YYYY-MM-DD')
            }).pipe(
                switchMap(results => {
                    return of(ChartUtils.convertChartRange(results, filter.viewType) as DashboardRevenueChart[]);
                })
        );
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../core/services/config/config.service';
import {ComponentConfiguration} from '../../../core/models/config';
import {Observable} from 'rxjs';
import {UrlStore} from '../../url-store';
import {
    JourneyShoulderTrainRequest,
    LegShoulderTrainRequest, ShoulderTrain
} from '../shared/models/shoulder-chart.model';

@Injectable({
    providedIn: 'root'
})
export class ShoulderChartService {
    constructor(private http: HttpClient, private configSvc: ConfigService) {
    }

    public getComponentConfig(): ComponentConfiguration {
        return this.configSvc.getComponentConfiguration('inventory', 'shoulder');
    }

    public getShoulderChartData(chartRequest: LegShoulderTrainRequest | JourneyShoulderTrainRequest,
                                trainType: 'leg' | 'journey'): Observable<ShoulderTrain[]> {
        return this.http.post<ShoulderTrain[]>(`${UrlStore.api.inventory.shoulderChart}/${trainType}`, chartRequest);
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {SharedGrid} from '../../shared/grid.model';
import {TopRevOppModel, TopRevOppService} from './top-rev-opp.service';
import {NavigateWithFiltersService} from '../../shared/navigate-with-filters.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {DateUtils} from '../../shared/date-utils';

@Component({
    selector: 'app-top-rev-opp',
    templateUrl: './top-rev-opp.component.html',
    styleUrls: ['./top-rev-opp.component.css']
})
export class TopRevOppComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    public tableGrid = new SharedGrid();

    constructor(private filterService: FilterService, private serv: TopRevOppService, private navService: NavigateWithFiltersService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }

    _loadData(searchOptions: any) {
        this.serv.getTopRevenueOpportunity(searchOptions).subscribe(this._onLoadSuccess.bind(this));
    }

    _onLoadSuccess(resp: TopRevOppModel[]) {
        this.tableGrid.gridData = resp;
        this.tableGrid.gridSort = [];
    }

    gridUserSelectionChange(selection: any) {
        const selectedData = selection.selectedRows[0].dataItem;
        const odParts = selectedData.od.split('-');
        const filters = {
            rsid: selectedData.rsid,
            date: DateUtils.convertLocalDateStringToDate(selectedData.dt)
        };
        this.navService.navigateWithFilters('inventory', filters);
    }
}

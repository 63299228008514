import {InventoryOverrideService} from '../services/inventory-override.service';
import {OverrideType} from '../../enums';
import {BaseSearchRequest} from '../../filter-item.model';
import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import {Observable} from 'rxjs';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';

@Directive()
export abstract class BaseInventoryOverride extends DialogContentBase {
    @Input() dialogRequest: any;
    @Output() updateComplete?: EventEmitter<boolean> = new EventEmitter();

    public active = false;
    public loading = true;
    public title = 'Inventory Override';
    public invOverride: InventoryOverride | any;

    protected request: any;

    protected constructor(public dialog: DialogRef) {
        super(dialog);
    }

    public closeDialog(): void {
        this.dialog.close();
    }


    public isValid(): boolean {
        return this.invOverride?.buckets.every(bucket => bucket.isValid === true);
    }

    public abstract onApprove(): void;

    public onCancel(): void {
        this.closeDialog();
    }

    public abstract onSave(): void;

}

export class InventoryOverride {
    public rsid: string;
    public departureDate: Date;
    public effDepartureDate: Date;
    public passengerClass: string;
    public origin: string;
    public destination: string;
    public comment?: string;
    public recommendedProfile?: string;
    public currentProfile?: string;
    public overrideProfile?: string;
    public metrics?: ODMetrics;
    public buckets: Bucket[];
    public profiles: InventoryProfile[];
    public overrideType: OverrideType;
    public overrideLevel: OverrideLevel;
    public isIgnored: boolean;
    public reminderDate: Date;
    public includeInWorkflow?: boolean;
    public additionalFilters?: BaseSearchRequest;
    public legOrigin?: string;
    public legDestination?: string;
    public comments: any;
    public journeyPairs?: any;
}

export class Bucket {
    public name: string;
    public override: number;
    public booked?: number;
    public recommended?: number;
    public current?: number;
    public isProtected?: boolean;
    public isFlex?: boolean;
    public isValid?: boolean;
    public isClosed?: boolean;
    public fare?: number;
}

export class ODMetrics {
    public pacing: number;
    public sensitivity: string;
    public competition: number;
    public compPctDiff: number;
    public booked: number;
    public capacity: number;
    public projectedYield: number;
    public lyYield: number;
    public reservableCapacity: number;
    public totalAllocation: number;
    public otbTotalDemand: number;
    public fcstDemand: number;
}

export class InventoryProfile {
    public id: string;
    public name: string;
    public isRecommended?: boolean;
    public buckets: InventoryProfileBucket[];
}

export class InventoryProfileBucket {
    public bucket: string;
    public percentage: number;
}

export enum OverrideLevel {
    Train = 'train',
    Leg = 'leg',
    OD = 'od'
}

export class BulkInfoResponse {
    buckets: any;
    profiles: any;
    request?: any;
}

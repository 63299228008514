import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Align} from '@progress/kendo-angular-popup';
import {UrlStore} from '../../../shared/url-store';
import {MenuItem} from '../../models/menu-item.model';

@Component({
    selector: 'app-settings-menu',
    templateUrl: './settings-menu.component.html',
    styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent implements OnInit {
    @ViewChild('popup', {read: ElementRef}) private popup: ElementRef;
    public show = false;
    public anchorAlign: Align = {horizontal: 'right', vertical: 'bottom'};
    public popupAlign: Align = {horizontal: 'left', vertical: 'bottom'};
    public settingsMenuItems: MenuItem[] = [
        {
            title: 'Business Rules',
            routerLink: `${UrlStore.ui.landing}${UrlStore.ui.businessRules}`,
            menuIcon: 'corporate_fare',
            enabled: true
        },
        {
            title: 'Business Rules v2',
            routerLink: `${UrlStore.ui.landing}${UrlStore.ui.businessRulesV2}`,
            menuIcon: 'corporate_fare',
            enabled: false
        }
    ];

    constructor(public elRef: ElementRef) {
    }

    ngOnInit(): void {
    }

    public onToggle(show?: boolean): void {
        this.show = show !== undefined ? show : !this.show;
    }

    @HostListener('document:click', ['$event'])
    private documentClick(event: any): void {
        if (!this.popupContains(event.target)) {
            this.onToggle(false);
        }
    }

    private popupContains(target: any): boolean {
        return this.elRef.nativeElement.parentElement.contains(target) ||
            (this.popup ? this.popup.nativeElement.contains(target) : false);
    }

}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {LookupService} from '../../../shared/lookup.service';
import {UrlStore} from '../../../shared/url-store';

@Injectable({
    providedIn: 'root'
})

export class TopBarService {
    constructor(private http: HttpClient, private lookupService: LookupService) {
    }

    public getStatusInfo(): Observable<StatusInfo[]> {
        return this.http.get<StatusInfo[]>(UrlStore.api.status.grid, {});
    }

}

export class StatusInfo {
    statusEvent: string;
    rars2Import: Date;
    rars2ImportStatus: string;
    uiRefresh: Date;
    uiRefreshStatus: string;
    repExtract: Date;
    repExtractStatus: string;
    rars2Export: Date;
    rars2ExportStatus: string;
}

import {
    Component,
    OnDestroy
} from '@angular/core';
import {FilterService} from '../../../shared/filter.service';
import {ComponentConfiguration} from '../../../core/models/config';
import {Subscription} from 'rxjs';
import {BaseChart} from '../../../shared/models/chart-configuration.model';
import {IntlService} from '@progress/kendo-angular-intl';
import {Group, Circle as Cir} from '@progress/kendo-drawing';
import {Circle, Point} from '@progress/kendo-drawing/geometry';
import {ForecastChartService} from './forecast-chart.service';
import {
    NavigateWithFiltersService,
    SimpleNavigationFilters
} from '../../../shared/navigate-with-filters.service';
import {DateUtils} from '../../../shared/date-utils';
import {SeriesClickEvent} from '@progress/kendo-angular-charts';

@Component({
    selector: 'app-forecast-chart',
    templateUrl: './forecast-chart.component.html',
    styleUrls: ['./forecast-chart.component.scss']
})
export class ForecastChartComponent extends BaseChart implements OnDestroy {
    public chartConfig: ComponentConfiguration;
    public chartData: any = [];
    public popupSettings: any;
    public isLoading = false;

    private filterSub: Subscription;
    private filterData: any;

    constructor(public intl: IntlService, private filterSvc: FilterService,
                private forecastChartSvc: ForecastChartService, private navSvc: NavigateWithFiltersService) {
        super(intl);
        this.popupSettings = {popupClass: ['dmd-fcst-chart-tooltip']};
        this.filterSub = this.filterSvc.filterTriggered$.subscribe(this.loadChartData.bind(this));
        this.chartConfig = this.forecastChartSvc.getComponentConfig();
    }

    ngOnDestroy(): void {
        this.filterSub.unsubscribe();
    }

    public eventsVisual(e: any) {
        if (e.value === 0) {
            return null;
        }
        const yPoint = e.value > 0 ? e.rect.bottomLeft().y : e.rect.topLeft().y;
        const targetPoint = {x: e.rect.center().x, y: yPoint};
        const circle = new Cir(
            new Circle(new Point(targetPoint.x, targetPoint.y - 10), 4),
            {
                stroke: {
                    color: e.value > 0 ? '#71E2AF' : '#FC6F60',
                    width: 2
                },
                fill: {
                    opacity: 0
                }
            }
        );
        return new Group().append(circle);
    }

    public onSeriesClick(e: SeriesClickEvent): void {
        if (this.filterData.viewType === 'Train') {
            return;
        }
        const filters = new SimpleNavigationFilters();
        const startDate = DateUtils.convertLocalDateString(e.category);
        if (this.filterData.viewType === 'Weekly') {
            filters.viewType = 'Daily';
            filters.startDate = startDate.toDate();
            filters.endDate = startDate.add(6, 'days').toDate();
            this.navSvc.navigateWithFilters('forecast', filters);
        } else if (this.filterData.viewType === 'Daily') {
            filters.viewType = 'Train';
            filters.date = startDate.toDate();
            this.navSvc.navigateWithFilters('forecast', filters);
        }
    }

    public refreshChart(): void {
        this.loadChartData(this.filterData);
    }

    private loadChartData(filterOptions: any): void {
        this.filterData = filterOptions;
        this.isLoading = true;
        this.forecastChartSvc.getChartData(filterOptions).subscribe(resp => {
            this.chartData = resp;
            this.isLoading = false;
        }, error => {
            console.error(`Error occurred loading forecast chart data`, error);
            this.isLoading = false;
        });
    }

}

import { NgModule } from '@angular/core';
import { AuthService } from './auth.service';
import { TokenInterceptorService } from './token-interceptor.service';

@NgModule({
  declarations: [],
  providers: [
    AuthService,
    TokenInterceptorService
  ]
})
export class AuthModule { }

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {environment as env} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';

@Injectable({
    providedIn: 'root'
})
export class RemindReportService {
    constructor(private http: HttpClient) {
    }

    public getReminderReport(request: any): Observable<RemindReportModel[]> {
        return this.http.post<RemindReportModel[]>(`${env.apiBaseUrl}/dashboard/reminder_report`, {
            ...request,
            startDate: moment(request.startDate).format('YYYY-MM-DD'),
            endDate: moment(request.endDate).format('YYYY-MM-DD')
        }).pipe(map(resp => {
            resp.forEach(item => {
                item.dt = DateUtils.convertToLocaleDateString(item.effDt as string);
            });
            return resp;
        }));
    }
}

export class RemindReportModel {
    dt: string | Date;
    effDt: string | Date;
    rsid: string;
    od: string;
    curr: string;
    rec: string;
    remFcst: number;
    avgYield: number;
    origin: string;
    destination: string;
    passengerClass: string;
}

<div class="profile-selector-title">
    <span>Select a profile</span>
</div>
<div class="profile-selector-container radio-toolbar">
    <ng-template ngFor let-item [ngForOf]="profiles" let-i="index">
        <input type="radio" [id]="item.id" name="profiles" [value]="item.id"
               [(ngModel)]="selectedProfile" (change)="onProfileChange($event, item)">
        <div [class]="getProfileClass(item)">
            <label [for]="item.id">{{item.name}}</label>
            <label [for]="item.id" [class]="'profile-icon ' + item.id"></label>
        </div>
    </ng-template>
</div>

import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(public auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let customerId = null;
        if ('appConfig' in localStorage){
            const appConfig = JSON.parse(localStorage.getItem('appConfig'));
            customerId = btoa(appConfig['customer_id']);
        }
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getAccessToken()}`,
                IdToken: `${this.auth.getIdToken()}`,
                IdCustomer: `${customerId}`,
            }
        });
        return next.handle(request);
    }
}

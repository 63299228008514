<kendo-chart [popupSettings]="defaultChartPopupSettings"
             [style.height.%]="100">
    <kendo-chart-tooltip [shared]="true">
        <ng-template kendoChartSharedTooltipTemplate let-category="category" let-points="points">
            <div class="chart-tooltip-category">{{category}}</div>
              <div class="chart-tooltip-container">
                  <ng-container *ngFor="let point of points">
                      <div class="chart-tooltip-point-color">
                          <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                      </div>
                      <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                      <div class="chart-tooltip-point-value">{{point.value | number:'1.0-0'}}</div>
                  </ng-container>
              </div>
        </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend position="top" orientation="horizontal"></kendo-chart-legend>
    <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [min]="0"></kendo-chart-value-axis-item>
    </kendo-chart-value-axis>
    <kendo-chart-series>
        <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [majorGridLines]="{ visible: false }">
                <kendo-chart-category-axis-item-labels [rotation]="315"
                                                       [step]="1"
                                                       [position]="'start'"
                ></kendo-chart-category-axis-item-labels>
            </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-series-item [data]="capacityData"
                                 [name]="chartConfig.config.metric.capacity.name"
                                 [field]="'capacity'"
                                 [categoryField]="'leg'"
                                 [color]="chartConfig.config.metric.capacity.style.color"
                                 type="line"
                                 dashType="dash"></kendo-chart-series-item>
        <kendo-chart-series-item [data]="capacityData"
                                 [name]="chartConfig.config.metric.allocation.name"
                                 [field]="'allocation'"
                                 [categoryField]="'leg'"
                                 [color]="chartConfig.config.metric.allocation.style.color"
                                 [stack]="true"></kendo-chart-series-item>
        <kendo-chart-series-item [data]="capacityData"
                                 [name]="chartConfig.config.metric.excess.name"
                                 [field]="'excessAllocation'"
                                 [categoryField]="'leg'"
                                 [color]="chartConfig.config.metric.excess.style.color"
                                 [stack]="true"></kendo-chart-series-item>
        <kendo-chart-series-item [data]="capacityData"
                                 [name]="chartConfig.config.metric.flexFcst.name"
                                 [field]="'flexForecast'"
                                 [categoryField]="'leg'"
                                 [color]="chartConfig.config.metric.flexFcst.style.color"
                                 [stack]="true"></kendo-chart-series-item>
        <kendo-chart-series-item [data]="capacityData"
                                 [name]="chartConfig.config.metric.walkUpDmd.name"
                                 [field]="'walkUpDemand'"
                                 [categoryField]="'leg'"
                                 [color]="chartConfig.config.metric.walkUpDmd.style.color"
                                 [stack]="true"></kendo-chart-series-item>
        <kendo-chart-series-item [data]="capacityData"
                                 [name]="chartConfig.config.metric.walkUpOvr.name"
                                 [field]="'override'"
                                 [categoryField]="'leg'"
                                 [color]="chartConfig.config.metric.walkUpOvr.style.color"
                                 [stack]="true"></kendo-chart-series-item>
    </kendo-chart-series>
</kendo-chart>

<ng-template #template let-anchor>
    <div [innerHTML]="eventsHoverContent"></div>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     position="left"
     [showAfter]="275">
    <kendo-grid [data]="tableGrid.gridData"
                [sortable]="true"
                [sort]="tableGrid.gridSort"
                (sortChange)="tableGrid.sortChange($event)"
                [selectable]="true"
                [rowHeight]="tableGrid.options.rowHeight"
                [height]="rowHt - 70"
                [scrollable]="'virtual'"
                (selectionChange)="gridUserSelectionChange($event)">
        <kendo-grid-column title="Dep Date" field="effDt" format="{0:d}" [width]="100"
                           [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="# of Events" field="eventsCount" [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div style="width: 100%;" (mouseenter)="onEventsCellMouseEnter($event, 0, dataItem)"
                     (mouseleave)="onEventsCellMouseLeave()">
                        <span>
                            {{dataItem.eventsCount}}
                        </span>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="% of Demand" field="pctDemand" [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div style="width: 100%;" (mouseenter)="onEventsCellMouseEnter($event, 1, dataItem)"
                     (mouseleave)="onEventsCellMouseLeave()">
                        <span>
                            {{dataItem.pctDemand | percent:'1.2-2'}}
                        </span>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Rem Dmd Impact" field="impact" [hidden]="showTotalRemDem"
                           [width]="130"
                           format="{0:0}"
                           [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'">
            <ng-template kendoGridHeaderTemplate let-column>
                <div class="ra-toggle-column" (contextmenu)="toggleColumn($event, column.field)">
                    {{column.title}}
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Total Rem Dmd" field="totalRemDem" [hidden]="!showTotalRemDem"
                           [width]="130"
                           format="{0:0}"
                           [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'">
            <ng-template kendoGridHeaderTemplate let-column>
                <div class="ra-toggle-column" (contextmenu)="toggleColumn($event, column.field)">
                    {{column.title}}
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>
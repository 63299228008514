<div #tubemap class="ra-tubemap-container"></div>
<kendo-contextmenu [target]="tubemap"
                   [items]="menuItems"
                   filter=".menu-hook"
                   showOn="click"
                   (select)="processMenuItemClick($event)"></kendo-contextmenu>
<kendo-contextmenu [target]="tubemap"
                   [items]="tubeMenuItems"
                   filter=".train-axis .tick"
                   showOn="click"
                   (popupOpen)="menuOpen($event)"
                   (select)="processMenuItemClick($event)"></kendo-contextmenu>
import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {BaseInventoryOverride} from '../../models/base-inventory-override.model';
import {InventoryOverrideService} from '../../services/inventory-override.service';
import {Bucket, ClassContraintRequest} from '../../../../inventory/inventory.model';
import {JourneyShoulderTrainRequest} from '../../../charts/shared/models/shoulder-chart.model';
import {Observable} from 'rxjs';
import {DialogCloseResult, DialogRef} from '@progress/kendo-angular-dialog';
import {map} from 'rxjs/operators';
import {AlertDialogService} from '../../services/alert-dialog.service';
import {DateUtils} from '../../../date-utils';
import {ReminderService} from '../../../services/reminder.service';
import {ReminderRequest} from '../../../models/reminder.model';
import {BaseBucketOverrideGrid} from '../../models/base-bucket-override-grid.model';

@Component({
    selector: 'app-single-inventory-override',
    providers: [ BaseBucketOverrideGrid ],
    templateUrl: './single-inventory-override.component.html',
    styleUrls: ['./single-inventory-override.component.scss']
})
export class SingleInventoryOverrideComponent extends BaseInventoryOverride implements OnInit {
    @ViewChild('alertContainer', { read: ViewContainerRef }) alertContainer: ViewContainerRef;
    public shoulderChartRequest: JourneyShoulderTrainRequest;
    public selectedProfile = '';
    private initBuckets: any;

    constructor(public dialog: DialogRef, private overrideSvc: InventoryOverrideService,
                private alertDialogSvc: AlertDialogService, private reminderSvc: ReminderService,
                private baseBucketOverrideGrid: BaseBucketOverrideGrid) {
        super(dialog);
    }

    ngOnInit(): void {
        this.getOverrideInfo(this.dialogRequest).subscribe(resp => {
            this.invOverride = resp;
            this.loading = false;
        }, error => {
            console.error(`Error occurred during get_override_info ${error}`);
            this.closeDialog();
        });
    }

    public ignoredChanged(isIgnored: boolean): void {
        this.invOverride.isIgnored = isIgnored;
    }

    public onApprove(): void {
        this.loading = true;
        this.invOverride.buckets = this.invOverride.buckets.filter(b =>
            this.initBuckets.find(ib => ib.name === b.name && ib.isProtected
                && (ib.override !== null && ib.override !== b.override)));
        const approvalRequest = this.buildApprovalRequest();
        if (approvalRequest['reminderDate'] !== null &&
            approvalRequest['reminderDate'] !== undefined) {
            approvalRequest['reminderDate'] = DateUtils.convertToLocaleDateString(
                approvalRequest['reminderDate'])
        }
        this.overrideSvc.approveRecommendation(approvalRequest).subscribe(resp => {
            if (resp) {
                this.closeDialog();
            } else {
                this.loading = false;
            }
        }, error => {
            console.error(`Error during approval: ${error}`);
        }, () => {
            this.updateComplete.emit(true);
        });
    }

    public ShowBuckets(): void {
        const value = localStorage.getItem('showHidden');
        if (value === '0') {
            localStorage.setItem('showHidden', '1');
        } else {
             localStorage.setItem('showHidden', '0');
        }
    }

    public onDeleteReminder(): void {
        const alertDialog = this.alertDialogSvc.openReminderDeleteAlertDialog(this.alertContainer);
        alertDialog.result.subscribe(dialogResult => {
            if (dialogResult instanceof DialogCloseResult || dialogResult.text === 'No') {
                return;
            }
            this.deleteReminder();
        });
    }

    public onSave(): void {
        this.checkClassConstraint().subscribe(canSave => {
            if (canSave) {
                this.saveOverride();
            }
        }, error => {
            console.error(`Error during class constraint check ${error}`);
        });
    }

    private buildApprovalRequest(): any {
        return {
            origin: this.invOverride.origin,
            destination: this.invOverride.destination,
            reminderDate: this.invOverride.reminderDate,
            journeyPairs: [{
                origin: this.invOverride.origin,
                destination: this.invOverride.destination
            }],
            buckets: this.invOverride.buckets,
            rsid: this.invOverride.rsid,
            departureDate: this.invOverride.departureDate,
            effDepartureDate: this.invOverride.effDepartureDate,
            passengerClass: this.invOverride.passengerClass
        };
    }

    private buildClassConstraintRequest(): ClassContraintRequest {
        let request: ClassContraintRequest;
        let leadBucket: Bucket;

        const filteredBuckets = this.invOverride.buckets.filter((bucket) => {
            let otb = this.invOverride.intradayOtb;
            if (otb === 0) {
                otb = this.invOverride.otbTotalDemand;
            }
            if (bucket.isFlex || bucket.isProtected) {
                return false;
            } else {
                return bucket.override > otb;
            }
        });

        if (filteredBuckets.length > 0) {
            leadBucket = filteredBuckets.reduce((p, c) => p.override < c.override ? p : c);
            if (leadBucket !== undefined) {
                request = new ClassContraintRequest();
                request.rsid = this.invOverride.rsid;
                request.effDepartureDate = this.invOverride.effDepartureDate;
                request.bucketCode = leadBucket.name;
                request.journeyDestination = this.invOverride.destination;
                request.fare = leadBucket.fare;
                request.journeyOrigin = this.invOverride.origin;
                request.passengerClass = this.invOverride.passengerClass;
                request.override = leadBucket.override;
            }
        }
        return request;
    }

    private buildCommentDetails(resp: any): void {
        if (resp.comments === null || resp.comments === undefined) {
            resp.comments = {
                service: resp.rsid,
                passengerClass: resp.passengerClass,
                journeyOrigin: resp.origin,
                journeyDestination: resp.destination,
                departureDate: resp.effDepartureDate,
                comments: []
            };
        }
    }

    private buildTitle(resp: any): string {
        return ` ${resp.effDepartureDate} ${resp.rsid} (${resp.origin} - ${resp.destination}) Override`;
    }

    private buildShoulderChartRequest(resp: any): JourneyShoulderTrainRequest {
        return {
            shoulderRsid: resp.rsid,
            effDepartureDate: resp.effDepartureDate,
            passengerClass: resp.passengerClass,
            journeyOrigin: resp.origin,
            journeyDestination: resp.destination
        };
    }

    private checkClassConstraint(): any {
        return new Observable(subscriber => {
            const request = this.buildClassConstraintRequest();
            if (request !== undefined) {
                this.overrideSvc.checkClassConstraint(request).subscribe(resp => {
                    if (resp.error !== undefined && resp.error.length > 0) {
                        const alertDialog = this.alertDialogSvc.openConstraintAlertDialog(resp, this.alertContainer);
                        alertDialog.result.subscribe(dialogResult => {
                            const canContinue = !(dialogResult instanceof DialogCloseResult ||
                                dialogResult.text === 'Cancel');
                            subscriber.next(canContinue);
                        });
                    } else {
                        subscriber.next(true);
                    }
                }, error => {
                    console.error(`Error occured during class constraint API call: ${error}`);
                });
            } else {
                subscriber.next(true);
            }
        });
    }

    private deleteReminder(): void {
        const reminderRequest: ReminderRequest = {
            rsid: this.invOverride.rsid,
            passengerClass: this.invOverride.passengerClass,
            effDepartureDate: DateUtils.convertDateToStringFormat(this.invOverride.effDepartureDate),
            origin: this.invOverride.origin,
            destination: this.invOverride.destination
        };

        this.invOverride.reminderDate = null;
        this.reminderSvc.deleteReminder(reminderRequest).subscribe(() => {
        }, error => {
            console.error(`Error occurred during revert reminder`, error);
        });
    }

    private finishLoad(resp: any): void {
        this.initBuckets = JSON.parse(JSON.stringify(resp.buckets));
        this.buildCommentDetails(resp);
        this.shoulderChartRequest = this.buildShoulderChartRequest(resp);
        this.title = this.buildTitle(resp);
    }

    private getOverrideInfo(request: any): Observable<any> {
        return this.overrideSvc.getInventoryOverride(request).pipe(map(resp => {
            resp.profiles.forEach(profile => {
                profile.isRecommended = profile.name === resp.recommendedProfile;
            });
            resp.reminderDate = resp.reminderDate ? DateUtils.convertToDate(resp.reminderDate) : null;
            this.finishLoad(resp);
            return resp;
        }));
    }

    private saveOverride(): void {
        this.loading = true;
        this.invOverride.journeyPairs = [{
            origin: this.invOverride.origin,
            destination: this.invOverride.destination
        }];
        if (this.selectedProfile) {
            this.invOverride.overrideProfile = this.selectedProfile;
        }
        if (this.invOverride['reminderDate'] !== null &&
            this.invOverride['reminderDate'] !== undefined) {
            this.invOverride['reminderDate'] = DateUtils.convertToLocaleDateString(
                this.invOverride['reminderDate'])
        }
        this.overrideSvc.saveSingleOverride(this.invOverride).subscribe(resp => {
            if (resp) {
                this.closeDialog();
            } else {
                this.loading = false;
            }
        }, error => {
            console.error(`Error during save ${error}`);
            this.loading = false;
        }, () => {
            this.updateComplete.emit(true);
        });
    }

    public nestBuckets() {
        const bucketLength = this.invOverride.buckets.length - 1;
        let highest = 0;
        for (let i = bucketLength; i > 0; i--) {
            const bucket = this.invOverride.buckets[i];
            if (bucket.override === null) {
                    bucket.override = bucket.current;
                    highest = bucket.current;
            }
            this.invOverride.buckets[i] = bucket;
            const prevBucket = this.invOverride.buckets[i - 1];
            if (bucket.isProtected) {
                if (prevBucket.override < highest) {
                    prevBucket.override = highest;
                }
                continue;
            }
            if (prevBucket.isFlex) {
                prevBucket.override = prevBucket.current;
            } else if (prevBucket.isProtected) {
                if (!(prevBucket.override > prevBucket.current)) {
                    prevBucket.override = prevBucket.current;
                }
            } else {
                if (highest > bucket.override) {
                    prevBucket.override = highest;
                } else if (prevBucket.override > bucket.override) {
                        if (!(bucket.isProtected)) {
                            highest = prevBucket.override;
                        }
                } else {
                        prevBucket.override = bucket.override;
                        if (!(bucket.isProtected)) {
                            highest = bucket.override;
                        }
                    }
            }
            this.invOverride.buckets[i - 1] = prevBucket;
        }
        this.baseBucketOverrideGrid.buckets = this.invOverride.buckets;
        this.baseBucketOverrideGrid.overrideMetrics = this.invOverride.metrics;
        this.baseBucketOverrideGrid.scanBucketsUp();
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SettingsMenuComponent} from './components/settings-menu/settings-menu.component';
import {MatIconModule} from '@angular/material/icon';
import {PopupModule} from '@progress/kendo-angular-popup';
import { SettingsMenuItemComponent } from './components/settings-menu-item/settings-menu-item.component';
import {RouterModule} from '@angular/router';


@NgModule({
    declarations: [
        SettingsMenuComponent,
        SettingsMenuItemComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        PopupModule,
        RouterModule
    ],
    exports: [
        SettingsMenuComponent
    ]
})
export class SettingsModule {
}

import {
    ChangeDetectionStrategy,
    Component, ContentChild, Input,
    OnInit, TemplateRef, ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import {TemplatePortal} from '@angular/cdk/portal';
import {TabLabelDirective} from '../directives/tab-label.directive';
import {TabContentDirective} from '../directives/tab-content.directive';

@Component({
    selector: 'app-side-tab',
    templateUrl: './side-tab.component.html',
    styleUrls: ['./side-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})
export class SideTabComponent implements OnInit {
    @ContentChild(TabLabelDirective) templateLabel: TabLabelDirective;
    @ContentChild(TabContentDirective, {read: TemplateRef, static: true}) explictContent: TemplateRef<any>;
    @ViewChild(TemplateRef, {static: true}) implicitContent: TemplateRef<any>;

    @Input() textLabel = '';

    private contentPortal: TemplatePortal | null = null;

    get content(): TemplatePortal | null {
        return this.contentPortal;
    }

    position: number | null = null;
    origin: number | null = null;
    isActive = false;

    constructor(private viewContainerRef: ViewContainerRef) {
    }

    ngOnInit() {
        this.contentPortal = new TemplatePortal(
            this.explictContent || this.implicitContent, this.viewContainerRef);
    }

}

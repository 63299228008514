import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationModule} from '@progress/kendo-angular-notification';


@NgModule({
    declarations: [],
    exports: [],
    imports: [
        CommonModule,
        NotificationModule
    ]
})
export class NotificationPopupModule {
}

<div class="toolbar col-12">
    <div class="row">
        <div class="btn-container">
            <app-save-filter-btn class="toolbar-btn" [filters]="getFilterData()"></app-save-filter-btn>
            <app-open-filter-btn class="toolbar-btn" [filters]="getFilterData()"></app-open-filter-btn>
            <app-pinned-filter-btn class="toolbar-btn"
                                   (clicked)="onPinnedClick($event)"
                                   [canPin]="isFilterViewValid"></app-pinned-filter-btn>
        </div>
        <div class="selector-container">
            <app-favorite-filter-selector class="toolbar-filter-selector" [filters]="getFilterData()"></app-favorite-filter-selector>
        </div>
    </div>
</div>

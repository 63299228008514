import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {UrlStore} from '../../url-store';
import {AuthService} from '../../../auth/auth.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {

  constructor(private router: Router, private authService: AuthService) { }

  public goHome(): void {
      this.router.navigate([UrlStore.ui.landing]);
  }

  public logout(): void {
      this.authService.logout();
  }

}

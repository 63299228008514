import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSearchRequest} from '../../shared/filter-item.model';
import {
    NavigateWithFiltersService,
    SimpleNavigationFilters
} from '../../shared/navigate-with-filters.service';
import {FilterService} from '../../shared/filter.service';
import {Subscription} from 'rxjs';

export interface Breadcrumb {
    viewType: string;
    filter: BaseSearchRequest;
}

@Component({
    selector: 'app-forecast-breadcrumb',
    templateUrl: './forecast-breadcrumb.component.html',
    styleUrls: ['./forecast-breadcrumb.component.scss']
})
export class ForecastBreadcrumbComponent implements OnInit, OnDestroy {
    public breadcrumbs: Breadcrumb[] = [];
    private filterSubscription: Subscription;
    constructor(private filterService: FilterService, private navService: NavigateWithFiltersService) {
    }

    ngOnInit(): void {
        this.filterSubscription = this.filterService.filterTriggered$.subscribe(this.getBreadcrumbs.bind(this));
    }

    ngOnDestroy(): void {
        this.filterSubscription.unsubscribe();
    }

    public onFilterClick(breadCrumbIndex: number): void {
        const selectedBreadCrumb = this.breadcrumbs[breadCrumbIndex];
        const simpleNavFilter = this.createNavigationFilter(selectedBreadCrumb);
        this.navService.navigateWithFilters('forecast', simpleNavFilter);
    }

    private addDailyBreadcrumb(breadcrumb: Breadcrumb): void {
        const lastIndex = this.breadcrumbs.length - 1;
        if (this.breadcrumbs.length === 0) {
            this.breadcrumbs.push(breadcrumb);
        } else if (this.breadcrumbs.length > 0 && this.breadcrumbs[lastIndex].viewType !== 'Train' &&
            this.breadcrumbs[lastIndex].viewType !== 'Daily') {
            this.breadcrumbs.push(breadcrumb);
        } else if (this.breadcrumbs.length === 1 && this.breadcrumbs[lastIndex].viewType === 'Train') {
            this.breadcrumbs.pop();
            this.breadcrumbs.push(breadcrumb);
        } else if (this.breadcrumbs[lastIndex].viewType === 'Train') {
            this.breadcrumbs.pop();
        }
    }

    private addTrainBreadcrumb(breadcrumb: Breadcrumb): void {
        if (this.breadcrumbs.length === 0 || this.breadcrumbs[this.breadcrumbs.length - 1].viewType !== 'Train') {
            this.breadcrumbs.push(breadcrumb);
        }
    }

    private addWeeklyBreadcrumb(breadcrumb: Breadcrumb): void {
        this.breadcrumbs[0] = breadcrumb;
        if (this.breadcrumbs.length === 2) {
            this.breadcrumbs.pop();
        } else if (this.breadcrumbs.length === 3) {
            this.breadcrumbs.pop();
            this.breadcrumbs.pop();
        }
    }

    private getBreadcrumbs(filters: BaseSearchRequest): void {
        if (filters.isNewSearch) {
            this.breadcrumbs = [];
        }

        const breadcrumb: Breadcrumb = {
            viewType: filters.viewType,
            filter: filters
        };

        if (breadcrumb.viewType === 'Train') {
            this.addTrainBreadcrumb(breadcrumb);
        } else if (breadcrumb.viewType === 'Daily') {
            this.addDailyBreadcrumb(breadcrumb);
        } else if (breadcrumb.viewType === 'Weekly') {
            this.addWeeklyBreadcrumb(breadcrumb);
        } else {
            this.breadcrumbs = [];
        }
    }

    private createNavigationFilter(breadcrumb: Breadcrumb): SimpleNavigationFilters {
        const simpleNavFilter = new SimpleNavigationFilters();
        simpleNavFilter.viewType = breadcrumb.filter.viewType;
        simpleNavFilter.startDate = breadcrumb.filter.startDate;
        simpleNavFilter.endDate = breadcrumb.filter.endDate;
        return simpleNavFilter;
    }


}

import {OverrideType} from '../shared/enums';
import {BaseSearchRequest} from '../shared/filter-item.model';

export class InventoryModel {
    public rsid: string;
    public departureDate: string;
    public effDepartureDate: string;
    public passengerClass: string;
    public status: string;
    public origin: string;
    public destination: string;
    public daypart: string;
    public forecastConfidence: number;
    public otbBookings: number;
    public intradayOTB: number;
    public totalRecDemand: number;
    public totalAllocations: number;
    public availableAllocations: number;
    public excessAllocations: number;
    public maxExcess: number;
    public sensitivity: string;
    public compPctDiff: number;
    public compPriceDiff: number;
    public flexDemand: number;
    public currentProfile: string;
    public recommendedProfile: string;
    public overriddenProfile: string;
    public reason: string;
    public refPrice: number;
    public projectedBooked: number;
    public projectedYield: number;
    public projectedRevenue: number;
    public revenuePacing: number;
    public overridden: boolean;
    public businessRuleIndicator: string;
    public businessRuleName: string;
    public lastUpdated: Date | string;
    public journey: string;
    public ovntLeadBucket: string;
    public leadBucket: string;
    public flexForecast: number;
    public flexOTB: number;
    public nextUpdateDate: Date | string;
}

export class InventoryOverride {
    public rsid: string;
    public departureDate: Date;
    public effDepartureDate: Date;
    public passengerClass: string;
    public origin: string;
    public destination: string;
    public comment?: string;
    public recommendedProfile?: string;
    public currentProfile?: string;
    public overrideProfile?: string;
    public metrics?: ODMetrics;
    public buckets: Bucket[];
    public profiles: InventoryProfile[];
    public overrideType: OverrideType;
    public overrideLevel: OverrideLevel;
    public isIgnored: boolean;
    public reminderDate: Date;
    public includeInWorkflow?: boolean;
    public additionalFilters?: BaseSearchRequest;
    public legOrigin?: string;
    public legDestination?: string;
    public comments: any;
}

export class Bucket {
    public name: string;
    public override: number;
    public booked?: number;
    public recommended?: number;
    public current?: number;
    public isProtected?: boolean;
    public isFlex?: boolean;
    public isValid?: boolean;
    public isClosed?: boolean;
    public fare?: number;
    public recOrOveride?: number;
    public bucketIndex?: number;
    public leadBucketDistance?: number;
    public highest?: boolean;
    public maxBucket?: boolean;
}

export class ODMetrics {
    public pacing: number;
    public sensitivity: string;
    public competition: number;
    public compPctDiff: number;
    public booked: number;
    public capacity: number;
    public projectedYield: number;
    public lyYield: number;
    public reservableCapacity: number;
    public totalAllocation: number;
    public otbTotalDemand: number;
    public intradayOtb: number;
    public fcstDemand: number;
}

export class InventoryProfile {
    public id: string;
    public name: string;
    public isRecommended?: boolean;
    public buckets: InventoryProfileBucket[];
}

export class InventoryProfileBucket {
    public bucket: string;
    public percentage: number;
}

export class InventoryGridRequest {
    public rsid: string;
    public departureDate: Date;
    public effDepartureDate: Date;
    public journeyOrigin: string[];
    public journeyDestination: string[];
    public legOrigin: string[];
    public legDestination: string[];
    public passengerClass: string;
    public recordStatus: string[];
    public autoPilotDisabled: boolean;
}

export class ApprovalRequest {
    public rsid: string;
    public departureDate: Date;
    public passengerClass: string;
    public overrideType: string;
    public additionalFilters: BaseSearchRequest;
    public legOrigin?: string;
    public legDestination?: string;
    public origin?: string | string[];
    public destination?: string | string[];
    public journeyPairs?: any;
    public reminderDate?: Date;
    public comment?: Date;
}

export enum OverrideLevel {
    Train = 'train',
    Leg = 'leg',
    OD = 'od'
}

export class BaseInventoryOverrideRequest {
    rsid: string;
    departureDate: string | Date;
    effDepartureDate: string | Date;
    passengerClass: string;
    journeyPairs: any[];
    additionalFilters: BaseSearchRequest;
    origin?: string;
    destination?: string;
}

export class SingleOverrideRequest extends BaseInventoryOverrideRequest {
    reminderDate: string | Date;
    comment: string;
    buckets: Bucket;
}

export class BulkOverrideRequest extends BaseInventoryOverrideRequest {
    legOrigin: string;
    legDestination: string;
}

export class SingleApprovalRequest extends SingleOverrideRequest {
    recordStatus: string;
}

export class BulkApprovalRequest extends BulkOverrideRequest {
    recordStatus: string;
}

export class ShoulderTrainRequest {
    shoulderRsid: string;
    effDepartureDate: string | Date;
    passengerClass: string;
    legOrigin?: string;
    legDestination?: string;
    journeyOrigin?: string;
    journeyDestination?: string;
}

export class ClassContraintRequest {
    rsid: string;
    effDepartureDate: Date;
    journeyOrigin: string;
    journeyDestination: string;
    bucketCode: string;
    passengerClass: string;
    override: number;
    fare: number;
}

export class PassengerClass {
    bucketCode: string;
    fare: number;
}

export class WalkUpOverride {
    rsid: string;
    effDepartureDate: string | Date;
    leg: string;
    legOrigin: string;
    legDestination: string;
    walkUpDemand: number;
    excessAllocation: number;
    override: number;
}

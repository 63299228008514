<div class="row" style="margin-top: -10px;  width: 100%; height: 100%; box-sizing: border-box; display: inline-flex">
    <div class="vertical-text">Remaining Forecast</div>
    <div class="col-12 paceVsRemDemChartContainer">
        <div class="legend-container" style="padding-bottom: 10px;">
            <div class="row" style="font-size: smaller; margin-top: -10px; ">
                <div class="col-4" style="text-align: left; padding: 0px;">
                    <span style="font-size: large;">&larr;</span>
                    <span style="background-color: white;">&nbsp; Decrease Profile ―</span>
                </div>
            <div class="col-4 paceVsRemDemLinearChartLegendCont">
                <ul style="margin-bottom: 0;">
                    <li class="paceVsRemDemLinearChartLegendLIPink">No Inv. Overrides</li>
                    <li class="paceVsRemDemLinearChartLegendLIGrey">Inv. Overrides</li>
                </ul>
            </div>
                <div class="col-4" style="text-align: right; padding: 0px;">
                    <span style="background-color: white;">― Cancel Overrides &nbsp;</span>
                    <span style="font-size: large;">&rarr;</span>
                </div>
            </div>
        </div>
        <kendo-chart class="paceVsRemDemChart"
                     [popupSettings]="chartPopupSettings"
                     [style.height.px]="rowHt - 70"
                     (seriesClick)="onSeriesClick($event)">
            <kendo-chart-series>
                <kendo-chart-series-item
                        type="scatter"
                        [data]="chartData"
                        xField="paceDelta"
                        yField="remFcstDem"
                        colorField="col">
                    <kendo-chart-series-item-tooltip [background]="'#FFFFFF'">
                        <ng-template let-value="dataItem">
                            <div class="tooltip-container">
                                <div class="tooltip-row">
                                    <div class="tooltip-label">Service:</div>
                                    <div class="tooltip-value">{{value.rsid}}</div>
                                </div>
                                <div class="tooltip-row">
                                    <div class="tooltip-label">Journey:</div>
                                    <div class="tooltip-value">{{value.od}}</div>
                                </div>
                                <div class="tooltip-row">
                                    <div class="tooltip-label">Departure Date:</div>
                                    <div class="tooltip-value">{{value.effDt}}</div>
                                </div>
                                <div class="tooltip-row">
                                    <div class="tooltip-label">Curr Profile:</div>
                                    <div class="tooltip-value">{{value.curProf}}</div>
                                </div>
                                <div class="tooltip-row">
                                    <div class="tooltip-label">Rec Profile:</div>
                                    <div class="tooltip-value">{{value.recProf}}</div>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-x-axis>
                <kendo-chart-x-axis-item
                        [title]="{ text: 'Pacing vs. Booking Curve' }"
                        [axisCrossingValue]="0">
                </kendo-chart-x-axis-item>
            </kendo-chart-x-axis>
            <kendo-chart-y-axis>
                <kendo-chart-y-axis-item>
                    <kendo-chart-x-axis-item-title visible="false">
                    </kendo-chart-x-axis-item-title>
                </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
        </kendo-chart>
    </div>
</div>

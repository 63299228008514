import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/Subject';
import {BaseSearchRequest} from './filter-item.model';

@Injectable({
    providedIn: 'root'
})
export class NavigateWithFiltersService {

    private filtersChangeSubject = new Subject<BaseSearchRequest>();
    public filtersChangeTriggered$ = this.filtersChangeSubject.asObservable();

    constructor(private router: Router) {
    }

    public navigateWithFilters(pageName: string, filters: SimpleNavigationFilters) {
        localStorage.setItem('skipFiltersOnNavigate', 'Y');
        setTimeout(() => {
            this.router.navigate([pageName]);
            setTimeout(this.applyFilters.bind(this, filters), 500);
        }, 100);
    }

    private applyFilters(filters: SimpleNavigationFilters) {
        const newFilters = this.buildSearchRequest(filters);
        this.filtersChangeSubject.next(newFilters);
    }

    private buildSearchRequest(filters: SimpleNavigationFilters): BaseSearchRequest {
        // For multiple 'origin' and 'dest' values, pass as comma separated values.
        const req = new BaseSearchRequest();
        req.rsid = filters.rsid ? [filters.rsid] : null;
        req.origin = filters.origin ? ((filters.origin.indexOf(',') > 0) ? filters.origin.split(',') : [filters.origin]) : null;
        req.destination = filters.dest ? ((filters.dest.indexOf(',') > 0) ? filters.dest.split(',') : [filters.dest]) : null;
        req.startDate = filters.startDate ? filters.startDate : filters.date;
        req.endDate = filters.endDate ? filters.endDate : filters.date;
        req.viewType = filters.viewType;
        req.statusType = filters.statusType;
        req.portfolio = filters.portfolio ? filters.portfolio : null;
        return req;
    }
}

export class SimpleNavigationFilters {
    public rsid?: string;
    public origin?: string;
    public dest?: string;
    public date?: Date;
    public startDate?: Date;
    public endDate?: Date;
    public viewType?: string;
    public statusType?: string;
    public portfolio?: string[];
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogsModule} from '@progress/kendo-angular-dialog';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {SwitchModule, TextBoxModule} from '@progress/kendo-angular-inputs';
import {ExcelExportComponent} from './excel-export.component';
import {ExcelExportModule} from '@progress/kendo-angular-excel-export';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        ExcelExportComponent
    ],
    imports: [
        ButtonsModule,
        CommonModule,
        DialogsModule,
        FormsModule,
        SwitchModule,
        TextBoxModule,
        ExcelExportModule
    ],
    exports: [
        ExcelExportComponent
    ]
})
export class AppExcelExportModule {}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {SeriesClickEvent} from '@progress/kendo-angular-charts';
import {TopCapConstModel, TopCapConstService} from './top-cap-const.service';
import {NavigateWithFiltersService} from '../../shared/navigate-with-filters.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {DateUtils} from '../../shared/date-utils';
import {ComponentConfiguration} from '../../core/models/config';

@Component({
    selector: 'app-top-cap-const',
    templateUrl: './top-cap-const.component.html',
    styleUrls: ['./top-cap-const.component.scss']
})
export class TopCapConstComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    public chartConfig: ComponentConfiguration;
    chartData: TopCapConstModel[];
    public chartPopupSettings = {
      popupClass: ['default-chart-tooltip-settings ']
    };

    constructor(private filterService: FilterService, private serv: TopCapConstService, private navService: NavigateWithFiltersService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
        this.chartConfig = this.serv.getComponentConfig();
    }

    ngOnInit() {
    }
    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }
    _loadData(searchOptions: any) {
        this.serv.getTopCapacityConstraints(searchOptions).subscribe(this._onLoadSuccess.bind(this));
    }
    _onLoadSuccess(resp: TopCapConstModel[]) {
        this.chartData = resp;
    }

    onSeriesClick(e: SeriesClickEvent | any) {
        const dataItem = e.dataItem;
        const filters = {
            rsid: dataItem.rsid,
            origin: null,
            dest: null,
            date: DateUtils.convertLocalDateStringToDate(dataItem.depDt)
        };
        this.navService.navigateWithFilters('inventory', filters);
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../core/services/config/config.service';
import {ComponentConfiguration} from '../../core/models/config';

@Component({
    selector: 'app-capacity-chart',
    templateUrl: './capacity-chart.component.html',
    styleUrls: ['./capacity-chart.component.scss']
})
export class CapacityChartComponent implements OnInit {
    @Input() capacityData: any;
    @Input() height?: number;
    public chartConfig: ComponentConfiguration;
    public defaultChartPopupSettings = {
        popupClass: ['wide-chart-tooltip-settings ']
    };
    private defaultConfig = {
        id: 'capacity',
        name: 'Capacity Chart',
        type: 'chart',
        config: {
            metric: {
                capacity: {
                    name: 'Capacity',
                    style: {color: '#05FF8E'}
                },
                allocation: {
                    name: 'Allocation',
                    style: {color: '#FC6F60'}
                },
                excess: {
                    name: 'Excess',
                    style: {color: '#0B3EF9'}
                },
                flexFcst: {
                    name: 'Flex Forecast',
                    style: {color: '#E6E7E8'}
                },
                walkUpDmd: {
                    name: 'Walk-Up Demand',
                    style: {color: '#C7C7C7'}
                },
                walkUpOvr: {
                    name: 'Walk-Up Override',
                    style: {color: '#05053A'}
                }
            }
        }
    };

    constructor(private configService: ConfigService) {
        const config = this.configService.getComponentConfiguration('inventory', 'capacity');
        this.chartConfig = config ? config : this.defaultConfig;
    }

    ngOnInit(): void {
    }

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SaveCommentRequest, UserComment} from './comments.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UrlStore} from '../../url-store';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CommentsService {
    constructor(private http: HttpClient) {
    }

    public getRecordComments(request: any): Observable<UserComment[]> {
        return this.http.get<UserComment[]>(UrlStore.api.comment,
            {
                params: {
                    service: request.service,
                    pax_class: request.passengerClass,
                    journey_origin: request.journeyOrigin,
                    journey_destination: request.journeyDestination,
                    departure_date: moment(request.departureDate).format('YYYY-MM-DD')
                }
            }).pipe(map(res => {
                res.forEach(item => {
                    item.date = moment.utc(item.date).local().toDate();
                    return item;
                });
                return res;
        }));
    }

    public saveUserComment(request: SaveCommentRequest): Observable<UserComment> {
        return this.http.post<UserComment>(UrlStore.api.comment, request).pipe(map(resp => {
            resp.date = moment.utc(resp.date).local().toDate();
            return resp;
        }));
    }
}

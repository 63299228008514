<mat-card class="ra-fieldset-card simple-card info-card">
    <mat-card-title class="card-title">
        Projected Revenue
    </mat-card-title>
    <mat-card-content>
        <div class="row">
            <div class="col-auto" style="width: 100%;">
                <div>
                    <div class="value-text">
                        {{ svModel?.value }}
                    </div>
                    <div class="sub-value-text" [ngClass]="{'sub-value-positive': isSubValuePositive, 'sub-value-negative': !isSubValuePositive}">
                        <i [ngClass]="subIcon.class" [ngStyle]="subIcon.style"></i>
                        <span>{{ svModel?.sub }}</span>
                    </div>
                </div>
                <div class="label-text">since last year</div>
            </div>
            <div class="col-3">
                <div *ngIf="hasAction" class="pull-right action-text">
                    {{ action }}
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment as env} from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LookupService {

    constructor(private http: HttpClient) {
    }

    public loadBuckets(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/buckets`);
    }

    public loadPortfolio(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/portfolios`);
    }

    public loadOrigins(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/origins`);
    }

    public loadDestinations(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/destinations`);
    }

    public loadStations(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/stations`);
    }

    public loadJourneyTypes(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/journey_types`);
    }

    public loadDayparts(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/dayparts`);
    }

    public loadDaysOfWeek(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/days_of_week`);
    }

    public loadRSIDs(): Observable<any> {
        return this.http.post(`${env.apiBaseUrl}/dim_lookup/rsids`, {});
    }

    public loadViewTypes(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/view_types`);
    }

    public loadCustomerTypes(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/get_tenants`);
    }

    public loadClassTypes(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/pax_classes`);
    }

    public loadPeriods(isStart: boolean): Observable<any> {
        const params = new HttpParams().set('isStart', isStart.toString());
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/periods`, {params});
    }

    public getStatusTypes(): Observable<any> {
        return this.http.get(`${env.apiBaseUrl}/dim_lookup/status_types`);
    }

}

import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { FilterService } from '../shared/filter.service';
import { SharedGrid } from '../shared/grid.model';
import { ForecastModel } from '../forecast/forecast.model';

import { TooltipTemplatePoint } from '@progress/kendo-angular-charts/dist/es2015/chart/tooltip/tooltip-template-point';
import {SimpleValueService} from './simple-value/simple-value.service';
import {DashboardService} from './shared/dashboard.service';
import {DashboardRevenueChart} from './shared/dashboard.model';
import {IntlService} from '@progress/kendo-angular-intl';
import {ComponentConfiguration} from '../core/models/config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    public revChartConfig: ComponentConfiguration;
    public topLineMetrics: any;
    public chartPopupSettings = {
      popupClass: ['default-chart-tooltip-settings ']
    };

  constructor(private filterService: FilterService, public dashboardSvc: DashboardService,
              private intl: IntlService, private simpleValueService: SimpleValueService) {
    DashboardComponent.staticObject = this;
    this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(
      searchOptions => {
        this.getRevenueChartResults(searchOptions);
      }
    );
    this.revChartConfig = this.dashboardSvc.getComponentConfig();
  }
  private static staticObject: DashboardComponent;
  private applyFilterSubscription: any;
  private filterLayout = {
    screenName: 'dashboard',
    journeyFilterConfig: {
            dropDownType: 'multi',
            isRequired: false
    },
    hidden: [{ name: 'statusTypeData' },
            {name: 'timeData'},
            {name: 'groupSize'}]
  };
  private mainHt: number;
  public rowHt: number;
  public revenueChartData: DashboardRevenueChart[] = [];

  private chartFieldTypes = {
    realizedDemand: 'number',
    forecastDemand: 'number',
    forecastOverride: 'number',
    allocation: 'number',
    projectedYield: 'currency',
    lyYield: 'currency',
    tyYield: 'currency',
    revenue: 'currency',
    projectedRevenue: 'currency',
    realizedCumulativeRev: 'currency',
    projectedCumulativeRev: 'currency',
    pacing: 'percent',
    realizedCumulativeBookings: 'number',
    projectedCumulativeBookings: 'number',
    bookings: 'number',
    projectedBookings: 'number',
    actualBookings: 'number',
    forecastBookings: 'number',
    averageBookings: 'number',
    minBookings: 'number',
    maxBookings: 'number'
  };
  public tableGrid = new SharedGrid();
  public gridData: ForecastModel[];
  revenueChartLoading = false;
  public viewLevel = false;

  public selectedRows: any[] = [];
  public formatChartTooltipValue(point: TooltipTemplatePoint): string {
    if (point.series.type === 'rangeArea') {
      return `${point.value.from} - ${point.value.to}`;
    }

    if (!this.chartFieldTypes[point.series.field]) {
      return point.value;
    }

    switch (this.chartFieldTypes[point.series.field]) {
      case 'currency':
        return this.intl.formatNumber(point.value, 'c0');
      case 'number':
        return this.intl.formatNumber(point.value, 'n0');
      case 'percent':
        return this.intl.formatNumber(point.value, 'p2');
      default:
        return point.value;
    }
  }

  private getRevenueChartResults(filter: any): void {
    this.revenueChartLoading = true;
    this.dashboardSvc.getRevenueChartData(filter).subscribe(
      results => {
        this.revenueChartData = results;
        this.revenueChartLoading = false;
      },
      error => {
        this.revenueChartLoading = false;
      }
    );
  }

  ngAfterViewInit(): void {
      this.filterService.updateFilterLayout(this.filterLayout);
  }

    ngOnInit() {
    this.onResize(null);
  }
  ngOnDestroy() {
    this.applyFilterSubscription.unsubscribe();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mainHt = window.innerHeight - 100;
    const rHt = Math.round(this.mainHt / 3) - 44;
    this.rowHt = Math.max(rHt, 190);
  }

  static setTopLineMetricsData(data: any): void {
    // This method is responsible for setting the top line metrics on Dashboard.
    DashboardComponent.staticObject.topLineMetrics = data;
  }
}

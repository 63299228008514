<div>
    <kendo-grid
            [data]="tableGrid.gridData"
            [sortable]="true"
            [sort]="tableGrid.gridSort"
            (sortChange)="tableGrid.sortChange($event)"
            [selectable]="tableGrid.selectableSettings"
            [rowHeight]="tableGrid.options.rowHeight"
            [loading]="isLoading"
            [height]="rowHt - 70"
            [scrollable]="'virtual'">
        <kendo-grid-column title="Date" field="dt" format="{0:d}"
                           [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Service" field="rsid"
                           [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Journey" field="od"
                           [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Prev Profile" field="curr"
                           [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Rec Profile" field="rec"
                           [headerClass]="'ra-grid-header'"></kendo-grid-column>
        <kendo-grid-column title="Rem Fcst" field="remFcst" format="{0:n0}"
                           [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'"></kendo-grid-column>
        <kendo-grid-column title="Proj Yield" field="avgYield" format="{0:c}"
                           [headerClass]="['ra-grid-header', 'col-pull-right']"
                           [class]="'col-pull-right'"></kendo-grid-column>
        <kendo-grid-column
                title="Fcst"
                [width]="55"
                [style]="{ cursor: 'pointer', 'text-align': 'center' }"
                [headerClass]="['ra-grid-header', 'col-pull-center']">
            <ng-template kendoGridCellTemplate let-dataItem>
                <img src="{{ '../../assets/images/navigate-rt.png' }}"
                     (click)="onFcstImgClick(dataItem)"
                     height="16"
                     width="20"
                     alt="Link to Forecast"/>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
                title="Inv"
                [width]="55"
                [style]="{ cursor: 'pointer', 'text-align': 'center' }"
                [headerClass]="['ra-grid-header', 'col-pull-center']">
            <ng-template kendoGridCellTemplate let-dataItem>
                <img src="{{ '../../assets/images/navigate-rt.png' }}"
                     (click)="onInvImgClick(dataItem)"
                     height="16"
                     width="20"
                     alt="Link to Inventory"/>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
                title=""
                [width]="30"
                [style]="{ cursor: 'pointer', 'text-align': 'center' }"
                [headerClass]="'ra-grid-header'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <i class="fa fa-times fa-lg delete-icon"
                   (click)="deleteReminderClick(dataItem)"></i>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>
<div kendoDialogContainer></div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {OvrdAlertsModel, OvrdAlertsService} from './ovrd-alerts.service';
import {SeriesClickEvent} from '@progress/kendo-angular-charts';
import {NavigateWithFiltersService} from '../../shared/navigate-with-filters.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {DateUtils} from '../../shared/date-utils';
import {ComponentConfiguration} from '../../core/models/config';

@Component({
    selector: 'app-ovrd-alerts',
    templateUrl: './ovrd-alerts.component.html',
    styleUrls: ['./ovrd-alerts.component.css']
})
export class OvrdAlertsComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    public chartConfig: ComponentConfiguration;
    chartData: OvrdAlertsModel[];

    constructor(private filterService: FilterService, private serv: OvrdAlertsService, private navService: NavigateWithFiltersService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
        this.chartConfig = this.serv.getComponentConfig();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }

    _loadData(searchOptions: any) {
        this.serv.getOverrideAlerts(searchOptions).subscribe(this._onLoadSuccess.bind(this));
    }

    _onLoadSuccess(resp: OvrdAlertsModel[]) {
        this.chartData = resp;
    }

    onSeriesClick(e: SeriesClickEvent) {
        const dataItem = e.dataItem;
        const parts = dataItem.key.split('::');
        const odParts = parts[1].split('-');
        const filters = {
            rsid: parts[0],
            origin: odParts[0],
            dest: odParts[1],
            date: DateUtils.convertLocalDateStringToDate(dataItem.effDt),
            viewType: 'Train'
        };
        this.navService.navigateWithFilters('forecast', filters);
    }
}

import {ColumnSortSettings, SelectableSettings} from '@progress/kendo-angular-grid';
import {orderBy, SortDescriptor} from '@progress/kendo-data-query';

export class SharedGrid {
  public options = {
      rowHeight: null,
      height: null,
      selectAble: true
  };
  public columnSortSettings: ColumnSortSettings = {
      allowUnsort: false,
      initialDirection: 'desc'
  };
  public selectableSettings: SelectableSettings;
  public gridTitle: string;
  public gridSort: SortDescriptor[] = [];
  public gridData: any[] = [];

  constructor() {
    this.options.rowHeight = 21;
    this.options.height = 300;
    this.selectableSettings = {
        checkboxOnly: this.options.selectAble,
        mode: 'multiple'
    };
  }

  public sortChange(sort: SortDescriptor[]): void {
      this.gridSort = sort;
      this.gridData = orderBy(this.gridData, this.gridSort);
  }
}

<kendo-dialog-titlebar>
    Bulk Override
</kendo-dialog-titlebar>
<div *ngIf="!loading" class="dialog-content-container">
    <div [style.text-align]="'center'" [style.padding-bottom.px]="5">
        <div class='radio-container' *ngFor="let option of options">
            <div><input
                    type="radio"
                    [value]="option.value"
                    [checked]="selectedOption === option.value"
                    (click)="onOptionClick(option.value)"
            />
            <label>{{ option.label }}</label></div>
        </div>
    </div>
    <div [hidden]="!bulkBucketToggle">
        <app-bulk-bucket-grid [buckets]="invOverride.buckets"></app-bulk-bucket-grid>
    </div>
    <div [hidden]="!bulkStaticProfileToggle">
        <app-profile-selector [profiles]="invOverride.profiles"></app-profile-selector>
    </div>
    <div [hidden]="!bulkVariableProfileToggle">
        <app-variable-profile-selector></app-variable-profile-selector>
    </div>
</div>
<div *ngIf="loading" class="loading-overlay">
    <div class="loading-background"></div>
    <div class="loading-icon">
        <i class="fas fa-2x fa-spinner fa-spin"></i>
    </div>
</div>
<kendo-dialog-actions class="bulk-override-kendo-dialog-actions">
    <button class="k-button ra-dialog-button" (click)="closeDialog()">Cancel</button>
    <button class="k-button ra-dialog-button" (click)="onSave()" [disabled]="!isValid()">
        Override
    </button>
    <button class="k-button ra-dialog-button" (click)="onApprove()" [disabled]="isVariableProfileValid()">Approve</button>
    <button class="k-button ra-dialog-button" (click)="nestBuckets()">Autonest</button>
</kendo-dialog-actions>
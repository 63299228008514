import {Component, EventEmitter, Output, OnInit} from '@angular/core';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
    @Output() sideNavAction = new EventEmitter();
    public groupSalesKeyName = 'hasGroupSalesAccess';
    public showGroupSales = false;

    public showCustomerMenuKeyName = 'customerMenuHidden';
    public showCustomerMenu = false;

    constructor() {
    }

    ngOnInit() {
        this.getGroupSalesAndCustomerMenuAccess();
    }

    public toggleSideNav(): void {
        this.sideNavAction.emit();
    }

    public getGroupSalesAndCustomerMenuAccess(): boolean {
        const value = JSON.parse(localStorage.getItem('userConfig'));
        this.showGroupSales = (value[this.groupSalesKeyName] === false);
        console.log(value[this.showCustomerMenuKeyName]);
        this.showCustomerMenu = (value[this.showCustomerMenuKeyName] === true);
        return this.showGroupSales;
    }

}

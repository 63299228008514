import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ProfileSelectorService} from './profile-selector.service';

@Component({
    selector: 'app-profile-selector',
    templateUrl: './profile-selector.component.html',
    styleUrls: ['./profile-selector.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileSelectorComponent implements OnInit {
    @Input() profiles: any;
    public selectedProfile: any;

    constructor(private selectorSvc: ProfileSelectorService) {
    }

    ngOnInit(): void {
    }

    public getProfileClass(profile: any): string {
        let profileClass = 'profile';
        if (profile.isRecommended) {
            profileClass += ' recommended';
        }
        return profileClass;
    }

    public onProfileChange(event: any, profile: any): void {
        this.selectorSvc.changeSelectedProfile(profile);
    }

}

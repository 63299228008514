import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-filter-time-picker',
    templateUrl: './filter-time-picker.component.html',
    styleUrls: ['./filter-time-picker.component.scss']
})
export class FilterTimePickerComponent implements OnInit {
    @Input() filterValue: FilterTimePickerValue;
    public filterTimePicker = {
        timeType: 'departure',
        startTime: null,
        endTime: null
    };

    constructor() {
    }

    ngOnInit(): void {
    }

}

export class FilterTimePickerValue {
    public timeType: string | 'departure' | 'arrival';
    public startTime: Date | string | null;
    public endTime: Date | string | null;
}

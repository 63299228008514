import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {environment as env} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SimpleValueService {

    constructor(private http: HttpClient) {
    }

    public getTopMetrics(request: any): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/dashboard/top_line_metrics`,
            {
                ...request,
                startDate: moment(request.startDate).format('YYYY-MM-DD'),
                endDate: moment(request.endDate).format('YYYY-MM-DD')
            });
    }

}

export class SimpleValueModel {
    hdr?: string;
    value: string;
    sub: string;
}

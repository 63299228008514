import {Component, Input, OnInit} from '@angular/core';
import {SimpleValueModel} from './simple-value.service';
import {IntlService} from '@progress/kendo-angular-intl';

@Component({
    selector: 'app-simple-value',
    templateUrl: './simple-value.component.html',
    styleUrls: ['./simple-value.component.css']
})
export class SimpleValueComponent implements OnInit {
    @Input() dataKey: string;
    @Input() hdr: string;
    @Input() valFmt = 'num'; // 'pct', 'curr' or 'num'
    @Input() subType = 'val'; // 'val' or 'target'
    @Input() label: string;
    public isSubValuePositive = true;
    public subIcon = {
        class: '',
        style: {
            'vertical-align': '',
            verticalAlign: 0.2
        }
    };

    svModel: SimpleValueModel;
    hasAction: boolean;
    private pAction: string;

    get action(): string {
        return this.pAction;
    }

    @Input('action')
    set action(value: string) {
        this.pAction = value;
        this.hasAction = !!value;
    }

    @Input() set allValues(values: any) {
        this._loadAllValues(values);
    }

    constructor(private intl: IntlService) {
    }

    ngOnInit() {
    }

    _loadAllValues(arr: any) {
        if (arr && arr.hasOwnProperty(this.dataKey) && typeof (arr[this.dataKey].value) !== 'undefined') {
            const resp = arr[this.dataKey];
            const val = parseFloat(resp.value);
            let subVal;
            if (resp.sub === '-') {
                subVal = resp.sub;
            } else {
                subVal = parseFloat(resp.sub);
                this.isSubValuePositive = this.subType === 'val' ? (subVal >= 0) : (subVal < val);
            }
            const sub = this.intl.formatNumber(subVal, 'p2');
            const isValPct = this.valFmt === 'pct';
            const isValCur = this.valFmt === 'curr';
            const isValInt = this.valFmt === 'num';
            let value = '';
            if (isValCur) {
                value = this.intl.formatNumber(val, 'c2');
            } else if (isValPct) {
                value = this.intl.formatNumber(val, 'p0');
            } else {
                value = this.intl.formatNumber(val, 'n0');
            }
            this.setSubIcon();
            this.svModel = {
                sub,
                value
            };
        }
    }

    private setSubIcon(): void {
        if (this.subType === 'target') {
            this.subIcon.class = 'far fa-dot-circle';
        } else {
            this.subIcon.class = `fas fa-sort-${this.isSubValuePositive ? 'up' : 'down'}`;
            this.subIcon.style['vertical-align'] = `${this.subIcon.style.verticalAlign * (this.isSubValuePositive ? -1 : 1)}em`;
        }
    }
}

import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appTabContent]'
})
export class TabContentDirective {

  constructor(public template: TemplateRef<any>) { }

}

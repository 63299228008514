<div class="status-selector-container">
    <div>Override Watch</div>
    <div>
        <kendo-switch [checked]="isIgnored"
                      [(ngModel)]="isIgnored"
                      [onLabel]="' '"
                      [offLabel]="' '"
                      (valueChange)="valueChanged($event)"></kendo-switch>
    </div>
    <div>Override Ignore</div>
</div>

<i class="fas fa-folder-open" (click)="open()"></i>
<kendo-dialog *ngIf="dialogOpened"
              [minWidth]="250"
              [width]="450"
              [actions]="dialogActions"
              (action)="onAction($event)"
              (close)="close()">
    <kendo-dialog-titlebar>Load Filter</kendo-dialog-titlebar>
    <div style="padding-top: 1em;">
        <kendo-grid [data]="filtersData"
                    [selectable]="selectableSettings"
                    [kendoGridSelectBy]="gridSelectionKey"
                    [selectedKeys]="selectedFilter"
                    [height]="400">
            <kendo-grid-checkbox-column [width]="35" [headerClass]="'ra-grid-header'"></kendo-grid-checkbox-column>
            <kendo-grid-column field="name" title="Filter Name" [headerClass]="'ra-grid-header'"></kendo-grid-column>
            <kendo-grid-column [width]="35"[headerClass]="'ra-grid-header'">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <i [ngClass]="{'fa-star fa-lg': true, 'far': !dataItem.isFavorite, 'fas': dataItem.isFavorite}"
                       class="favorite-icon"
                       (click)="favoriteFilter(dataItem)"></i>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="35" [headerClass]="'ra-grid-header'">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <i class="fa fa-times fa-lg delete-icon"
                       (click)="confirmDeleteFilter(dataItem)"></i>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
        <div *ngIf="gridLoading" class="k-i-loading"></div>
    </div>
</kendo-dialog>
<kendo-dialog *ngIf="deleteDialogOpened"
              [actions]="deleteDialogActions"
              (action)="onAction($event)"
              (close)="close()">
    <kendo-dialog-titlebar>Delete Filter</kendo-dialog-titlebar>
    <div style="padding-top: 1em;">
        Are you sure you want to delete selected filter <b>{{selectedFilter[0]?.name}}</b>
    </div>
</kendo-dialog>
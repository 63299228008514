export class SharedChart {
    public static getMetricColorClass(value: any, metricId: string): string {
        const appConfig = JSON.parse(localStorage.getItem('appConfig'));

        let capacityConstraints: number[] = [.9, .8, .7, .6, 0];

        if ('capacity_constraints' in appConfig) {
            capacityConstraints = appConfig['capacity_constraints'];
        }
        
        let baseCssClass = '';
        if (value === null || value === undefined) {
            return 'unavailable';
        }

        if (metricId === 'capacityConstraint') {
            baseCssClass = 'cap-con';
            switch (true) {
                case (value > capacityConstraints[0]):
                    return `${baseCssClass}-high`;
                case (value > capacityConstraints[1]):
                    return `${baseCssClass}-med-high`;
                case (value > capacityConstraints[2]):
                    return `${baseCssClass}-med`;
                case (value > capacityConstraints[3]):
                    return `${baseCssClass}-med-low`;
                case (value >= capacityConstraints[4]):
                    return `${baseCssClass}-low`;
                default:
                    return 'unavailable';
            }
        } else if (metricId === 'pacing') {
            baseCssClass = 'pacing';
            switch (true) {
                case (value > 20):
                    return `${baseCssClass}-high`;
                case (value > 10):
                    return `${baseCssClass}-med-high`;
                case (value > 0):
                    return `${baseCssClass}-med`;
                case (value > -10):
                    return `${baseCssClass}-med-low`;
                case (value > -20):
                    return `${baseCssClass}-low`;
                case (value < -20):
                    return `${baseCssClass}-lowest`;
                default:
                    return 'unavailable';
            }
        } else if (metricId === 'revenueOpportunity') {
            baseCssClass = 'rev-opp';
            switch (true) {
                case (value > 1500):
                    return `${baseCssClass}-high`;
                case (value > 750):
                    return `${baseCssClass}-med-high`;
                case (value > 250):
                    return `${baseCssClass}-med`;
                case (value >= 0):
                    return `${baseCssClass}-med-low`;
                case (value < 0):
                    return `${baseCssClass}-low`;
                default:
                    return 'unavailable';
            }
        } else if (metricId === 'walkUpOverride') {
            return value ? 'walk-up-override' : 'unavailable';
        }
    }
}

export class SharedChartMenuEvent {
    action: SharedChartMenuAction;
    data: any;
}

export enum SharedChartMenuAction {
    viewDetails,
    viewTubeChart, // currently not using this enum, using viewDetails instead
    viewODs,
    overrideAdvanced,
    overrideWalkUp,
    toggleAutoPilot
}

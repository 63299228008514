<div class="spinner-wrapper">
  <div class="col-md-3 left-col">
    <app-nav (sideNavAction)="toggleFilterMenu()"></app-nav>
  </div>
  <div class="right-col">
    <mat-sidenav-container>
      <mat-sidenav #filtermenu mode="side" style="position: fixed !important;">
        <app-sidebar-nav [hidden]="isFiltersHidden()" [parentPopupContainer]="parentPopupContainer"></app-sidebar-nav>
      </mat-sidenav>

      <mat-sidenav-content #notificationContainer class="page-container" style="overflow-x: inherit; min-height: 100vh;">
        <app-top-bar [pageTitle]="getPageTitle()" [appLogo]="getAppLogo()"></app-top-bar>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div
    *ngIf="this.screenLoading"
    class="k-i-loading"
    style="z-index: 99999;"
  ></div>
</div>
<ng-template #popupContainer></ng-template>
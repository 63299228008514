import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ComponentConfiguration} from '../../../core/models/config';
import {ShoulderChartService} from './shoulder-chart.service';
import {
    JourneyShoulderTrainRequest,
    LegShoulderTrainRequest, ShoulderTrain
} from '../shared/models/shoulder-chart.model';

@Component({
    selector: 'app-shoulder-chart',
    templateUrl: './shoulder-chart.component.html',
    styleUrls: ['./shoulder-chart.component.scss']
})
export class ShoulderChartComponent implements OnChanges {
    @Input() chartRequest: LegShoulderTrainRequest | JourneyShoulderTrainRequest;
    @Input() trainType: 'leg' | 'journey';
    @Input() width?: number;
    @Input() height?: number;
    @Input() padding?: number;
    @Input() title?: string;

    public chartConfig: ComponentConfiguration;
    public chartData: ShoulderTrain[] = [];
    public defaultChartPopupSettings = {
        popupClass: ['default-chart-tooltip-settings']
    };
    private defaultConfig = {
        id: 'shoulder',
        name: 'Shoulder Chart',
        type: 'chart',
        config: {
            metric: {
                bkdDmd: {
                    name: 'Bkd Dmd',
                    style: {color: '#E6E7E8'}
                },
                fcstDmd: {
                    name: 'Fcst Dmd',
                    style: {color: '#0B3EF9'}
                }
            }
        }
    };

    constructor(private chartSvc: ShoulderChartService) {
        const config = this.chartSvc.getComponentConfig();
        this.chartConfig = config ? config : this.defaultConfig;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.chartRequest.currentValue === null || changes.chartRequest.currentValue === undefined) {
            this.resetChart();
        } else {
            this.getChartData();
        }
    }

    public resetChart(): void {
        this.chartData = null;
    }

    private getChartData(): void {
        this.chartSvc.getShoulderChartData(this.chartRequest, this.trainType).subscribe(resp => {
            this.chartData = resp;
        }, error => {
            console.error(`Error when getting shoulder chart data: ${error}`);
        });
    }

}

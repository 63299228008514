import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-pinned-filter-btn',
  templateUrl: './pinned-filter-btn.component.html',
  styleUrls: ['./pinned-filter-btn.component.scss'],
  animations: [
      trigger('pinUnpin', [
          state('unpinned', style({
              color: 'inherit'
          })),
          state('pinned', style({
              color: '#FC6F60',
              transform: 'rotate(90deg)'
          })),
          transition('unpinned => pinned', [
              animate('.3s')
          ]),
          transition('pinned => unpinned', [
              animate('.3s')
          ])
      ])
  ]
})
export class PinnedFilterBtnComponent implements OnInit {
  @Input() canPin: any;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter();
  public isPinned = false;

  constructor() { }

  ngOnInit() {
  }

  @HostListener('click')
  public togglePin(): void {
    if (this.canPin || this.isPinned) {
        this.isPinned = !this.isPinned;
        this.clicked.emit(this.isPinned);
    }
  }

}

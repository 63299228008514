<div class="side-tab-group">
    <app-side-tab-header class="tab-label-container" #tabHeader>
        <div class="tab-label" appTabLabelWrapper
             *ngFor="let tab of sideTabComponents; let i = index"
             [id]="_getTabLabelId(i)"
             [class.side-tab-label-active]="tab.isActive"
             (click)="_handleClick(tab, tabHeader, i)">
            <div class="tab-label-content">
                <ng-template [ngIf]="tab.templateLabel">
                    <ng-template [cdkPortalOutlet]="tab.templateLabel"></ng-template>
                </ng-template>
                <ng-template [ngIf]="!tab.templateLabel">{{tab.textLabel}}</ng-template>
            </div>
        </div>
    </app-side-tab-header>

    <div class="tab-body-wrapper" #tabBodyWrapper>
        <app-side-tab-body *ngFor="let tab of sideTabComponents; let i = index"
                           [id]="_getTabContentId(i)"
                           [class.side-tab-body-active]="tab.isActive"
                           [content]="tab.content"
                           [position]="tab.position"
                           [origin]="tab.origin"
                           [active]="!isClosed"
                           [animationDuration]="animationDuration"
                           (centered)="_removeTabBodyWrapperHeight()"
                           (centering)="_setTabBodyWrapperHeight($event)"
        ></app-side-tab-body>
    </div>
</div>
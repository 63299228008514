<div class="side-tab-body-content" #content
     [@changeTab]="{
        value: positionState,
        params: {animationDuration: animationDuration}
     }"
     (@changeTab.start)="_onChangeTabStarted($event)"
     (@changeTab.done)="changeTabCompleted.next($event)"
     [@expandCollapseTrigger]="{
        value: '',
        params: {animationDuration: animationDuration}
     }" *ngIf="active">
    <ng-template appSideTabBodyHost></ng-template>
</div>
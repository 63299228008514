import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent {
  @Input() gridData: any;
  @Input() gridColumns: any;
  @Input() filterData: any;
  @Input() filterColumns: {field: any, title: any}[];

  public dialogVisible = false;
  public fileName = '';
  public styleConfig: any;

  constructor() {
  }

  public onSave(gridExport: any, filterExport: any): void {
    this.dialogVisible = false;
    Promise.all([gridExport.workbookOptions(), filterExport.workbookOptions()]).then((workbooks) => {
      workbooks[0].sheets[0].name = 'Grid Data';
      workbooks[1].sheets[0].name = 'Filters';
      workbooks[1].sheets[0].rows = this.buildFilterSheet();
      workbooks[0].sheets = workbooks[0].sheets.concat(workbooks[1].sheets);
      gridExport.fileName = this.validateFileName();
      gridExport.save(workbooks[0]);
    });
  }

  public onCancel(event): void {
    event.preventDefault();
    this.closeDialog();
  }

  public closeDialog(): void {
    this.dialogVisible = false;
  }

  public openDialog(): void {
    this.dialogVisible = true;
  }

  private validateFileName(): string {
    if (this.fileName === '' || this.fileName === undefined) {
      return 'excelExport.xlsx';
    }

    if (!this.fileName.endsWith('.xlsx')) {
      return this.fileName + '.xlsx';
    }

    return this.fileName;
  }

  private buildFilterSheet(): any {
    const rows = [{cells: [{ value: 'Filter Name' }, { value: 'Filter Values' }]}];
    if (this.filterData) {
      this.filterColumns.forEach(col => {
        const row = this.buildFilterRow(col);
        if (row.cells.length > 1) {
          rows.push(this.buildFilterRow(col));
        }
      });
    }
    return rows;
  }

  private buildFilterRow(filterColumn: any): any {
    const row = {cells: []};
    const filterValues = this.filterData[filterColumn.field];
    if (!(filterValues === null || filterValues === undefined)) {
      row.cells = [{ value: filterColumn.title }].concat(this.buildFilterCells(filterValues));
    }
    return row;
  }

  private buildFilterCells(filterValues: any): any {
    let cells = [];
    if (filterValues instanceof Array) {
      cells = cells.concat(filterValues.map(val => {
        return { value: val };
      }));
    } else if (filterValues.hasOwnProperty('name')) {
      cells.push({ value: filterValues.name });
    } else if (filterValues.hasOwnProperty('timeType')) {
        cells.push({ value: `${filterValues.timeType}
        ${filterValues.startTime} - ${filterValues.endTime}`});
    } else {
      cells.push({ value: filterValues });
    }
    return cells;
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-reminder-selector',
    templateUrl: './reminder-selector.component.html',
    styleUrls: ['./reminder-selector.component.scss']
})
export class ReminderSelectorComponent implements OnInit {
    @Input() reminderDate: any;
    @Output() reminderDateChange = new EventEmitter<Date>();
    @Output() delete: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    public deleteReminderClick(): void {
        if (this.reminderDate) {
            this.delete.emit();
        }
    }

    public onValueChange(changedDate: any): void {
        this.reminderDateChange.emit(changedDate);
    }

}

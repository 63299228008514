import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {DialogModule} from '@progress/kendo-angular-dialog';
import {GridModule} from '@progress/kendo-angular-grid';
import {ComboBoxModule} from '@progress/kendo-angular-dropdowns';
import {PopupModule} from '@progress/kendo-angular-popup';
import {SplitButtonModule} from '@progress/kendo-angular-buttons';
import {SwitchModule} from '@progress/kendo-angular-inputs';

import {SaveFilterBtnComponent} from './save-filter-btn/save-filter-btn.component';
import {OpenFilterBtnComponent} from './open-filter-btn/open-filter-btn.component';
import {FilterToolbarComponent} from './filter-toolbar.component';
import {PinnedFilterBtnComponent} from './pinned-filter-btn/pinned-filter-btn.component';
import { FavoriteFilterSelectorComponent } from './favorite-filter-selector/favorite-filter-selector.component';


@NgModule({
    declarations: [
        FilterToolbarComponent,
        SaveFilterBtnComponent,
        OpenFilterBtnComponent,
        PinnedFilterBtnComponent,
        FavoriteFilterSelectorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ComboBoxModule,
        DialogModule,
        GridModule,
        PopupModule,
        SplitButtonModule,
        SwitchModule
    ],
    exports: [
        FilterToolbarComponent
    ]
})
export class FilterToolbarModule {
}

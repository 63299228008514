import { Component } from '@angular/core';

@Component({
  selector: 'app-side-tab-header',
  templateUrl: './side-tab-header.component.html',
  styleUrls: ['./side-tab-header.component.scss']
})
export class SideTabHeaderComponent {

  constructor() { }

}

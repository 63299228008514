import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ForecastService } from './forecast.service';
import { Subscription } from 'rxjs/Subscription';
import { FilterService } from '../shared/filter.service';
import { SharedGrid } from '../shared/grid.model';
import { BaseSearchRequest, FilterItem } from '../shared/filter-item.model';
import { ForecastModel } from './forecast.model';
import { OverrideType } from '../shared/enums';
import { OverrideRequest, OverrideResponse } from '../shared/override.model';
import {
    CellClickEvent,
    GridDataResult,
    PageChangeEvent,
    RowArgs,
    SelectAllCheckboxState
} from '@progress/kendo-angular-grid';
import {orderBy, SortDescriptor} from '@progress/kendo-data-query';
import { IntlService } from '@progress/kendo-angular-intl';
import { TooltipTemplatePoint } from '@progress/kendo-angular-charts/dist/es2015/chart/tooltip/tooltip-template-point';
import {
    NavigateWithFiltersService,
} from '../shared/navigate-with-filters.service';
import * as moment from 'moment';
import {TooltipDirective} from '@progress/kendo-angular-tooltip';
import {ReOptimizationService} from '../core/services/reopt/re-optimization.service';
import {map} from 'rxjs/operators';
import {DateUtils} from '../shared/date-utils';
import {ChartUtils} from '../shared/helpers/chart-utils';
import {ComponentConfiguration} from '../core/models/config';
import {ForecastChartComponent} from './components/forecast-chart/forecast-chart.component';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss']
})
export class ForecastComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    public forecastSvc: ForecastService,
    private filterService: FilterService,
    private intl: IntlService,
    private navService: NavigateWithFiltersService,
    private reOptService: ReOptimizationService
  ) {
      ForecastComponent.staticObject = this;
      this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(searchOptions => {
            this.viewLevel = searchOptions.viewLevel;
            this.filterData = searchOptions;
            this.createDateList(searchOptions);
            this.getForecastResults(searchOptions, true);
            this.getRevenueChartResults(searchOptions);
            this.getYieldChartResults(searchOptions);
            this.getBookingChartResults(searchOptions);
          },
        );
      this.bkgCurvChartConfig = this.forecastSvc.getComponentConfig('bkgCurve');
      this.bkgsChartConfig = this.forecastSvc.getComponentConfig('bookings');
      this.revChartConfig = this.forecastSvc.getComponentConfig('rev');
      this.yieldChartConfig = this.forecastSvc.getComponentConfig('yield');
      this.getDefaultGridColumns();
  }
  private static staticObject: ForecastComponent;
  @ViewChild('popupContainer' , { read: ViewContainerRef, static: true})
  public popupContainer: ViewContainerRef;
  @ViewChild('forecastChart') public forecastChart: ForecastChartComponent;

  public trendChartPopupSettings = {
      popupClass: ['wide-chart-tooltip-settings ']
  };
  public defaultChartPopupSettings = {
      popupClass: ['default-chart-tooltip-settings']
  };

  public tableGrid = new SharedGrid();
  public gridData: ForecastModel[];
  public gridView: GridDataResult;
  public overrideRequestItem: OverrideRequest;
  public fcstMetricColumns = new FilterItem();
  public bookingCurveDates = new FilterItem();
  public viewLevel = false;
  public currentChart = 'vlyrev';
  public revenueChartData: any;
  public yieldChartData: any;
  public bookingChartData: any;
  public bookingCurveChartData: any;
  public forecastChartData: any[] = [];
  public selectedRows: any[] = [];
  public pageSize = 50;
  public skip = 0;
  public gridSort: SortDescriptor[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public defaultGridColumns = {};

  public gridColumns: any[] = [
    { field: 'departureDate', title: 'Date' },
    { field: 'rsid', title: 'RSID' },
    { field: 'origin', title: 'Origin' },
    { field: 'destination', title: 'Destination' },
    { field: 'leg', title: 'Leg' },
    { field: 'forecastConfidence', title: 'Fcst Conf' },
    { field: 'specialEvents', title: 'Sp Events' },
    {
      field: 'vlyDemandPacing',
      title: 'VLY Dmd Pace',
      cellOpt: { format: '#,##0%' }
    },
    { field: 'lyAdvancedBookings', title: 'LY Adv Bkgs' },
    { field: 'otbIndividualDemand', title: 'Bkd Ind Dmd' },
    { field: 'otbGroupDemand', title: 'Bkd NonFcst Dmd' },
    { field: 'intradayOTB', title: 'OTB Δ'},
    { field: 'remainingForecastDemand', title: 'Fcst Rem Dmd' },
    { field: 'totalForecastDemand', title: 'Fcst Total Dmd' },
    { field: 'leadBucket', title: 'Lead Bucket' },
    { field: 'overrideDemand', title: 'Total Fcst Dmd Ovrd' },
    {
      field: 'forecastedBookedPercent',
      title: 'Proj Bkd %',
      cellOpt: { format: '#,##0%' }
    },
    {
      field: 'projectedRevenue',
      title: 'Proj Rev',
      cellOpt: { format: '#,##0.00' }
    },
    {
      field: 'projectedRevenuePacing',
      title: 'Proj Rev Pace',
      cellOpt: { format: '#,##0%' }
    },
    { field: 'competitorPriceThreat', title: 'Competitor Price Threat' },
    {
      field: 'projectedDemandPacing',
      title: 'Proj Dmd Pace',
      cellOpt: { format: '#,##0%' }
    },
    {
      field: 'projectedYield',
      title: 'Proj Yield',
      cellOpt: { format: '#,##0.00' }
    },
    {
      field: 'lyFinalYield',
      title: 'LY Final Yield',
      cellOpt: { format: '#,##0.00' }
    }
  ];
  public filterData: BaseSearchRequest;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public filterColumns: any[] = [
    { field: 'passengerClass', title: 'Passenger Class' },
    { field: 'viewLevel', title: 'View Level' },
    { field: 'viewType', title: 'View Type' },
    { field: 'portfolio', title: 'Portfolio' },
    { field: 'origin', title: 'Origin' },
    { field: 'destination', title: 'Destination' },
    { field: 'journeyType', title: 'Journey Type' },
    { field: 'daypart', title: 'Daypart' },
    { field: 'dayOfWeek', title: 'Day of Week' },
    { field: 'rsid', title: 'RSID' },
    { field: 'startDate', title: 'Start Date' },
    { field: 'endDate', title: 'End Date' },
    { field: 'startRange', title: 'Start Range' },
    { field: 'endRange', title: 'End Range' },
    { field: 'startPeriod', title: 'Start Period' },
    { field: 'endPeriod', title: 'End Period' }
  ];
  public hoveredGrid: any;
  private applyFilterSubscription: Subscription;
  private filterLayout = {
    screenName: 'forecast',
    journeyFilterConfig: {
        dropDownType: 'multi',
        isRequired: false
    },
    hidden: [
        { name: 'statusTypeData' },
        { name: 'timeData' },
        { name: 'groupSize' }
        ]
  };

  private chartFieldTypes = {
    realizedDemand: 'number',
    forecastDemand: 'number',
    forecastOverride: 'number',
    allocation: 'number',
    projectedYield: 'currency',
    lyYield: 'currency',
    tyYield: 'currency',
    revenue: 'currency',
    projectedRevenue: 'currency',
    realizedCumulativeRev: 'currency',
    projectedCumulativeRev: 'currency',
    pacing: 'percent',
    realizedCumulativeBookings: 'number',
    projectedCumulativeBookings: 'number',
    bookings: 'number',
    projectedBookings: 'number',
    actualBookings: 'number',
    forecastBookings: 'number',
    averageBookings: 'number',
    minBookings: 'number',
    maxBookings: 'number'
  };
  forecastGridLoading = false;
  forecastChartLoading = false;
  revenueChartLoading = false;
  yieldChartLoading = false;
  bookingChartLoading = false;
  bookingCurveChartLoading = false;

  // Just testing kendo dialog box...
  public actionsLayout = 'normal';
  public opened = false;

  public bkgCurvChartConfig: ComponentConfiguration;
  public bkgsChartConfig: ComponentConfiguration;
  public revChartConfig: ComponentConfiguration;
  public yieldChartConfig: ComponentConfiguration;

  static loadSelectedColumns(values: any[]) {
        ForecastComponent.staticObject.fcstMetricColumns.selectedValues = JSON.parse(localStorage.getItem('fcstGridSelectedColumns'));
  }

  ngAfterViewInit(): void {
      this.filterService.updateFilterLayout(this.filterLayout);
  }

  ngOnInit() {
      this.fcstMetricColumns.loadAllValues(this.getOptionalMetricsColumns());
  }

  ngOnDestroy() {
    this.applyFilterSubscription.unsubscribe();
  }

  public disableReOpt(): boolean {
      return (this.filterData === null || this.filterData === undefined) || !this.filterData.portfolio;
  }

  public checkHiddenColumn(columnName): boolean {
    if (!columnName) {
      return false;
    }
    return this.fcstMetricColumns.selectedValues.findIndex(i => i.id === columnName) === -1;
  }

  public checkViewLevelColumn(): boolean {
    return this.viewLevel;
  }

  public saveHandler(item: OverrideResponse): void {
    if (item.overrideType === OverrideType.Bulk) {
      item.data = this.getSelectedRows();
    }
    this.forecastGridLoading = true;
    this.forecastChartLoading = true;
    this.forecastSvc.update(item).subscribe(
      result => {
        this.getForecastResults(this.filterData, false);
        this.forecastChart.refreshChart();
      },
      error => {
        console.log(error);
        this.forecastGridLoading = false;
        this.forecastChartLoading = false;
      }
    );
    this.overrideRequestItem = undefined;
  }

  public cancelHandler(): void {
    this.overrideRequestItem = undefined;
  }

  public revertHandler(item: OverrideResponse): void {
    if (item.overrideType === OverrideType.Bulk) {
      item.data = this.getSelectedRows(true).map(row => {
          return row.itemKey;
      });
      item.minDate = this.filterData.startDate;
      item.maxDate = this.filterData.endDate;
    } else {
        item.minDate = item.data[0].effDepartureDate;
        item.maxDate = item.data[0].effDepartureDate;
        item.data = item.data.map(row => {
            return `${row.rsid}${row.effDepartureDate}${row.passengerClass}${row.origin}${row.destination}`;
        });
    }
    this.forecastGridLoading = true;
    this.forecastChartLoading = true;
    this.forecastSvc.revert(item).subscribe(
      result => {
        this.getForecastResults(this.filterData, false);
        this.forecastChart.refreshChart();
      },
      error => {
        this.forecastGridLoading = false;
        this.forecastChartLoading = false;
      }
    );
    this.overrideRequestItem = undefined;
  }

  public bulkOverride(): void {
    this.checkReoptStatus().subscribe(r => {
        this.displayForecastOverride({}, OverrideType.Bulk);
    }, error => {
        alert(error.message);
    });
  }

  public reoptAction(): void {
    this.forecastGridLoading = true;
    const me = this;
    setTimeout(() => {
      me.forecastGridLoading = false;
      me.opened = true;
    }, 1800);
  }

  public onDialogClose() {
    this.opened = false;
  }

  // If they click "go to inventory" ... im not
  public onDeleteData() {
    const filters = {
      rsid: '',
      origin: '',
      dest: '',
      date: new Date('04-Oct-2019'),
      startDate: new Date(),
      endDate: new Date(),
      viewType: 'Daily'
    };
    this.navService.navigateWithFilters('inventory', filters);
  }

  public singleOverride(item: ForecastModel): void {
    this.checkReoptStatus().subscribe(r => {
        this.displayForecastOverride(item, OverrideType.Single);
    }, error => {
        alert(error.message);
    });
  }

  public selectionKey(context: RowArgs): any {
    return context.index;
  }

  public getConfidenceIcon(dataItem: any): string {
    let cssClasses = 'fas fa-lg ';
    if (dataItem.forecastConfidence === 1) {
      cssClasses += 'fa-minus low-color';
    } else if (dataItem.forecastConfidence === 2) {
      cssClasses += 'fa-grip-lines medium-color';
    } else if (dataItem.forecastConfidence === 3) {
      cssClasses += 'fa-bars high-color';
    } else {
      cssClasses = '';
    }
    return cssClasses;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadGridData();
  }

  public bookingCurveDateChange(event: any): void {
    this.getBookingCurveChartResults(this.filterData, event.id);
  }

  public formatChartTooltipValue(point: TooltipTemplatePoint): string {
    if (point.series.type === 'rangeArea') {
      return `${point.value.from} - ${point.value.to}`;
    }

    if (!this.chartFieldTypes[point.series.field]) {
      return point.value;
    }

    switch (this.chartFieldTypes[point.series.field]) {
      case 'currency':
        return this.intl.formatNumber(point.value, 'c0');
      case 'number':
        return this.intl.formatNumber(point.value, 'n0');
      case 'percent':
        return this.intl.formatNumber(point.value, 'p2');
      default:
        return point.value;
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
    if (checkedState === 'checked') {
      this.selectedRows = this.gridData.map((item, index) => index);
    } else {
      this.selectedRows = [];
    }
    this.selectAllState = checkedState;
  }

  public onSelectedKeysChange(event): void {
    const len = this.selectedRows.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
    } else if (len > 0 && len < this.gridData.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public triggerReOpt(): void {
      const request = {portfolios: this.filterData.portfolio};
      this.forecastGridLoading = true;
      this.reOptService.initiateReOptimization(request).subscribe(results => {
          if (results.errors.length > 0) {
              alert(results.errors);
          }
          this.forecastGridLoading = false;
      }, error => {
          console.error(`Error during re-optimization trigger: ${error}`);
          this.forecastGridLoading = false;
      });
  }

  private checkReoptStatus(): any {
        return this.reOptService.getReOptimizationStatus()
            .pipe(
                map(m => {
                    if (m.length > 0) {
                        throw new RangeError('Re-optimization currently running');
                    }
                })
            );
    }

  private displayForecastOverride(item: any, overrideType: OverrideType): void {
    this.overrideRequestItem = { overrideType, data: item };
  }

  private getForecastResults(filter: any, fullReset: boolean): void {
    this.resetGrid(fullReset);
    this.forecastGridLoading = true;
    this.forecastSvc.getForecastResults(filter).subscribe(
      results => {
        this.gridData = results.map(result => {
            result.gridDate = DateUtils.convertToDate(result.effDepartureDate as string);
            return result;
        });
        this.loadGridData();
        this.forecastGridLoading = false;
      },
      error => {
        this.forecastGridLoading = false;
      }
    );
  }

  private getRevenueChartResults(filter: any): void {
    this.revenueChartLoading = true;
    this.forecastSvc.getRevenueChartData(filter).subscribe(
      results => {
          this.revenueChartData = ChartUtils.convertChartRange(results, filter.viewType);
          this.revenueChartLoading = false;
      },
      error => {
        this.revenueChartLoading = false;
      }
    );
  }

  private getYieldChartResults(filter: any): void {
    this.yieldChartLoading = true;
    this.forecastSvc.getYieldChartData(filter).subscribe(
      results => {
          this.yieldChartData = ChartUtils.convertChartRange(results, filter.viewType);
          this.yieldChartLoading = false;
      },
      error => {
        this.yieldChartLoading = false;
      }
    );
  }

  private getBookingChartResults(filter: any): void {
    this.bookingChartLoading = true;
    this.forecastSvc.getBookingsChartData(filter).subscribe(
      results => {
          this.bookingChartData = ChartUtils.convertChartRange(results, filter.viewType);
          this.bookingChartLoading = false;
      },
      error => {
        this.bookingChartLoading = false;
      }
    );
  }

  private getBookingCurveChartResults(filter: any, departureDate?: string): void {
    this.bookingCurveChartLoading = true;
    if (departureDate) {
      filter.departureDate = departureDate;
    } else {
      filter.departureDate = filter.startDate;
    }
    this.bookingCurveChartData = [];
    this.forecastSvc.getBookingCurveChartData(filter).subscribe(
      results => {
        this.bookingCurveChartData = results;
        this.bookingCurveChartLoading = false;
      },
      error => {
        this.bookingCurveChartLoading = false;
      }
    );
  }

  public cellClickHandler(event: CellClickEvent): void {
      if (event.column.field === 'totalForecastDemand') {
          this.singleOverride(event.dataItem);
      }
  }

  public onMouseOver(event: MouseEvent): void {
      const element = event.target as HTMLElement;
      const rsid = element.innerText;

      if (this.gridData !== undefined) {
          const idx = this.gridData.findIndex(t => t.rsid === rsid);
          const train = this.gridData[idx];
          if (element.nodeName === 'TD' && train !== undefined) {
              const trainInfo = {
                  rsid: train.rsid,
                  trainDepartureTime: train.trainDepartureTime,
                  trainOriginDestination: train.trainOrigin + '-' + train.trainDestination
              };
              setTimeout(() => this.hoveredGrid = trainInfo, 250);
              this.tooltipDir.toggle(element);
          } else {
              this.tooltipDir.hide();
          }
      }
  }

  private loadGridData(): void {
      if (this.gridSort) {
          this.gridData = orderBy(this.gridData, this.gridSort);
      }
      this.gridView = {
          data: this.gridData.slice(this.skip, this.skip + this.pageSize),
          total: this.gridData.length
      };
  }

  private resetGrid(totalReset: boolean): void {
      if (totalReset) {
          this.skip = 0;
          this.gridSort = [];
      }
      this.selectedRows = [];
      this.gridData = [];

      // first use the selected columns if we are using a saved filter
      let selectedColumns = JSON.parse(localStorage.getItem('fcstGridSelectedColumns'));

      // if those are empty, use whatever we currently have selected
      if (selectedColumns.length === 0) {
          selectedColumns = JSON.parse(localStorage.getItem('selectedForecastGridColumns'));
      }
      this.fcstMetricColumns.selectedValues = selectedColumns;
      this.loadGridData();
  }

  private getSelectedRows(onlyOverrides: boolean = false): any[] {
    const rows = [];
    this.selectedRows.forEach(sr => {
      const row = this.gridData[sr];
      if (onlyOverrides && !row.overridden) {
          return;
      }
      rows.push({
        rsid: row.rsid,
        departureDate: row.departureDate,
        effDepartureDate: row.effDepartureDate,
        daypart: row.daypart,
        passengerClass: row.passengerClass,
        portfolio: row.portfolio,
        journeyType: row.journeyType,
        totalForecastDemand: row.totalForecastDemand,
        origin: row.origin,
        destination: row.destination,
        itemKey: `${row.rsid}${row.effDepartureDate}${row.passengerClass}${row.origin}${row.destination}`
      });
    });
    return rows;
  }

  private createDateList(filter): void {
    this.forecastSvc.getBookingCurveDates(filter).subscribe(
      results => {
        const dates = [];
        results.forEach(depDate => {
          dates.push({id: depDate.departureDate, name: DateUtils.convertToLocaleDateString(depDate.departureDate)});
        });
        this.bookingCurveDates.allValues = dates;
        this.bookingCurveDates.selectedValues = dates[0];
        this.getBookingCurveChartResults(filter, dates[0].id);
      },
      error => {
        const dates = [];
        let sDate = moment();
        let eDate = moment();
        if (filter.startDate === undefined || filter.endDate === undefined) {
            sDate = moment(sDate).add(this.filterData.startDateRange, 'day');
            eDate = moment(eDate).add(this.filterData.endDateRange, 'day');
        } else {
            sDate = moment(filter.startDate);
            eDate = moment(filter.endDate);
        }
        while (sDate <= eDate) {
            const dateString = sDate.format('YYYY-MM-DD');
            dates.push({id: dateString, name: DateUtils.convertToLocaleDateString(dateString)});
            sDate.add(1, 'd');
        }
        this.bookingCurveDates.allValues = dates;
        this.bookingCurveDates.selectedValues = dates[0];
        this.getBookingCurveChartResults(filter, dates[0].id);
      }
    );
  }

    sortChange(sort: Array<SortDescriptor>) {
        this.gridSort = sort;
        this.selectedRows = [];
        this.skip = 0;
        let gridData = this.gridData;
        // Set Forecast overrides to totalForecastDemand before performing sort.
        gridData.forEach((item) => {
            item.totalForecastDemand = parseInt(<string>this.getTotalForecastDmd(item));
        });
        this.gridData = orderBy(gridData, this.gridSort);
        this.loadGridData();
    }
    getTotalForecastDmd(dataItem) {
      const num = dataItem.overridden
          ? dataItem.overrideDemand
          : parseInt(dataItem.totalForecastDemand);
      return num != null && !isNaN(num) ? num.toFixed(0) : null;
    }

  private getOptionalMetricsColumns(): Observable<any> {
    const dataValues = [];
    const key = 'default';
    const name = 'name';
    const id = 'id';
    // eslint-disable-next-line guard-for-in
    for (const val in this.defaultGridColumns) {
      const value = this.defaultGridColumns[val];
      if (!value[key]) {
        dataValues.push({id: val, name: value[name]});
        this.defaultGridColumns[val].default = true;
      }
    }
    return of({
      data: dataValues
    });
  }

  private getDefaultGridColumns(): void {
     this.forecastSvc.getDefaultGridColumns().subscribe(results => {        
       const resultValues = results.reduce(function(result, item) {
           const key = Object.keys(item)[0];
           result[key] = item[key];
           return result;
           }, {});
       this.defaultGridColumns = resultValues;
       this.fcstMetricColumns.loadAllValues(this.getOptionalMetricsColumns());
       this.fcstMetricColumns.selectedValues = JSON.parse(localStorage.getItem('fcstGridSelectedColumns'));
       }, error => {
            console.log(`Error retrieving default grid columns: ${error}`);
     });

  }

  public onValueChange(value) {
    // SidebarNavComponent.selectedInventoryGridColumns = value;
    localStorage.setItem('selectedForecastGridColumns', JSON.stringify(value));
  }

  public getSelectedValues() {
    return JSON.parse(localStorage.getItem('fcstGridSelectedColumns'));
  }

  // This method checks if a column is supposed to be hidden or displayed by default. Used for initial load
  // when HTML page loads soon than the defaultGridColumns
  public getDefaultGridColumnVal(value: string) {
    if (value in this.defaultGridColumns) {
        return !this.defaultGridColumns[value].default;
    }
    return false;
  }
}

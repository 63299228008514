<kendo-grid [data]="buckets"
            [rowClass]="rowCallback"
            [style.height.%]="100"
            [class]="'inventory-override-grid'"
            [rowSticky]="rowSticky"
            [scrollable]="'scrollable'" >
    <kendo-grid-column title="Bucket" field="name" width="100px" [headerClass]="'ra-grid-header'"></kendo-grid-column>
    <kendo-grid-column title="Fare" field="fare" [headerClass]="'ra-grid-header'" format="{0:c2}"></kendo-grid-column>
    <kendo-grid-column title="Booked" width="50" field="booked" [headerClass]="'ra-grid-header'"></kendo-grid-column>
    <kendo-grid-column title="Previous" width="60" field="current" [headerClass]="'ra-grid-header'"></kendo-grid-column>
    <kendo-grid-column title="Recs" field="recommended" [headerClass]="'ra-grid-header'"></kendo-grid-column>
    <kendo-grid-column title="IsFlex" width="0px" field="isFlex" [headerClass]="'ra-grid-header'"></kendo-grid-column>
    <kendo-grid-column title="Override" width="60" field="override" [headerClass]="'ra-grid-header'"
                       [class]="'override-grid-input'" editor="numeric">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <kendo-numerictextbox [(value)]="dataItem.override"
                                  (valueChange)="scanBucketsUp()"
                                  [format]="'n'"
                                  [style.width.%]="100"></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="40" [headerClass]="'ra-grid-header'">
        <ng-template kendoGridCellTemplate let-dataItem>
            <i *ngIf="!dataItem.isProtected" class="fa fa-times fa-lg delete-icon"
               (click)="onClear(dataItem)"></i>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column  [width]="68" title="Remaining" field="remaining" [headerClass]="'ra-grid-header'"></kendo-grid-column> 
</kendo-grid>

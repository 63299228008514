<div class="row">
    <div class="status-text-container col-auto">
        <div class="status-text">
            <div>
                <div class="value-text">{{ totalLabel }}</div>
                <div class="status-type">{{ statusType }}</div>
            </div>
            <div class="label-text">records to review</div>
        </div>
    </div>
    <div style="width: 35%; height: inherit;">
        <kendo-chart style="height: 40%; transform: rotate(90deg);"
                     (seriesClick)="onSeriesClick($event)"
                     (seriesHover)="onSeriesHover($event)"
                     (seriesLeave)="onSeriesLeave()">
            <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [title]="{ text: 'Status' }"
                                             [name]="'status'"
                                             [visible]="false"
                                             [majorGridLines]="{ visible: false }"
                ></kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [majorGridLines]="{ visible: false }">
                    <kendo-chart-category-axis-item-labels
                            [visible]="false"></kendo-chart-category-axis-item-labels>
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
                <kendo-chart-series-item type="bar"
                                         [stack]="{ type: '100%' }"
                                         [data]="data"
                                         [name]="chartConfig.items.new.name"
                                         [categoryField]="'numRecords'"
                                         [field]="'newRec'"
                                         [color]="chartConfig.items.new.color">
                </kendo-chart-series-item>
                <kendo-chart-series-item type="bar"
                                         [stack]="{ type: '100%' }"
                                         [data]="data"
                                         [name]="chartConfig.items.needsApp.name"
                                         [categoryField]="'numRecords'"
                                         [field]="'needAppRec'"
                                         [color]="chartConfig.items.needsApp.color">
                </kendo-chart-series-item>
                <kendo-chart-series-item type="bar"
                                         [stack]="{ type: '100%' }"
                                         [data]="data"
                                         [name]="chartConfig.items.auto.name"
                                         [categoryField]="'numRecords'"
                                         [field]="'autoRec'"
                                         [color]="chartConfig.items.auto.color">
                </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
    </div>
</div>

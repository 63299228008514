import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {environment as env} from '../../../environments/environment';
import {mergeMap, toArray} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';

@Injectable({
    providedIn: 'root'
})
export class TopRevOppService {

    constructor(private http: HttpClient) {
    }

    public getTopRevenueOpportunity(request: any): Observable<TopRevOppModel[]> {
        return this.http.post<any>(`${env.apiBaseUrl}/dashboard/top_rev_opp`,
            {
                ...request,
                startDate: moment(request.startDate).format('YYYY-MM-DD'),
                endDate: moment(request.endDate).format('YYYY-MM-DD')
            })
            .pipe(mergeMap(r => this.convertFromRose(r)),
                toArray());
    }

    // Converting ROSE results to expected FV format
    private convertFromRose(records: RoseTopRevOppModel[]): TopRevOppModel[] {
        const results: TopRevOppModel[] = [];
        records.forEach(record => {
            results.push({
                dt: DateUtils.convertToLocaleDateString(record.effDt),
                rsid: record.rsid,
                od: record.od,
                curr: record.curr,
                rec: record.rec,
                remDem: record.remDem,
                revOpp: record.revOpp
            });
        });

        return results;
    }
}
export class TopRevOppModel {
    dt: string | Date;
    rsid: string;
    od: string;
    curr: string;
    rec: string;
    remDem: number;
    revOpp: number;
}

class RoseTopRevOppModel {
    dt: string;
    effDt: string;
    rsid: string;
    od: string;
    curr: string;
    rec: string;
    remDem: number;
    revOpp: number;
}

<app-heatmap-legends [selectedMetric]="selectedMetric"></app-heatmap-legends>
<div kendoTooltip
     filter=".data-rect"
     [titleTemplate]="titleTemplate"
     [tooltipTemplate]="template"
     [showAfter]="275"
     position="right"
     [style.height.%]="heightPct">
    <ng-template #titleTemplate let-anchor>
        {{ anchor.nativeElement.getAttribute('data-service-id') }}
    </ng-template>
    <ng-template #template let-anchor>
        {{ anchor.nativeElement.getAttribute('data-service-info-dep')}}<br/>
        {{ anchor.nativeElement.getAttribute('data-service-info-arr')}}
    </ng-template>
    <div #heatmap class="ra-heatmap-chart-container" [style.height.%]="100"></div>
</div>
<kendo-contextmenu [target]="heatmap"
                   [items]="menuItems"
                   filter=".data-rect"
                   showOn="click"
                   (popupOpen)="menuOpen($event)"
                   (select)="processMenuItemClick($event)"></kendo-contextmenu>
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {BaseBucketOverrideGrid} from '../../models/base-bucket-override-grid.model';
import {ProfileSelectorService} from '../profile-selector/profile-selector.service';
import {Subscription} from 'rxjs';
import {RowArgs} from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-bucket-override-grid',
    templateUrl: './bucket-override-grid.component.html',
    styleUrls: ['./bucket-override-grid.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BucketOverrideGridComponent extends BaseBucketOverrideGrid implements OnInit, OnDestroy {
    @Input() selectedProfile?: string;
    @Output() selectedProfileChange = new EventEmitter();
    private profileChangeSubscription: Subscription;

    // eslint-disable-next-line @typescript-eslint/ban-types
    public rowSticky: Function = (args: RowArgs) => this.isSticky(args.dataItem.isFlex);

    constructor(private profileSelectSvc: ProfileSelectorService) {
        super();
        this.profileChangeSubscription = this.profileSelectSvc.profileChange$.subscribe(this.selectedProfileChanged.bind(this));
    }

    ngOnInit(): void {
        this.checkNestingBuckets();
        this.scanBucketsUp();
    }

    ngOnDestroy(): void {
        this.profileChangeSubscription.unsubscribe();
    }


    public onClear(bucket: any): void {
        this.updateSelectedProfile('');
        super.onClear(bucket);
    }

    public isSticky(value: boolean): boolean {
        return value;
    }

    public scanBucketsUp(): void {
        this.updateSelectedProfile('');
        super.scanBucketsUp();
    }

    private selectedProfileChanged(profile: any): void {
        /* apply overrides by profile */
        console.log('profile change: ' + profile?.id);
        this.updateSelectedProfile(profile?.id);

        let otb = this.overrideMetrics.intradayOtb;
        if (otb === 0) {
            otb = this.overrideMetrics.otbTotalDemand;
        }

        const fcstDemand = this.overrideMetrics.fcstDemand;
        let totalDemand = otb + fcstDemand;
        const nonProtectedOTB = otb - this.overrideMetrics.protectedTotalBookings;
        const demand = totalDemand; // - this.overrideMetrics.protectedTotalBookings;
        const excess = this.overrideMetrics.totalAllocation - totalDemand;

        if (excess < 0) {
            totalDemand = totalDemand + excess;
        }

        let foundHighestUsedBucket = false; // used to determine the highest bucket with a positive percentage
        let foundMaxBucketWithFare = false; // used to determine the higest bucket with a fare
        let maxBucketWithFare = 'undefined';

        // first pass to determine the lead and last buckets, and set protected buckets
        this.buckets.forEach(bucket => {
            const pBucket = profile.buckets.find(pb => pb.bucket === bucket.name);
            if (pBucket) {
                const allocation = pBucket.percentage * totalDemand;

                // if we have a zero forecast, but the bucket should have allocation under the profile,
                // give it the excess which should be all unsold seats, i.e. open the bucket all the way up
                // otherwise assign the bucket its computed allocation
                if (Math.round(fcstDemand) === 0 && pBucket.percentage > 0) {
                    bucket.override = excess;
                } else {
                    bucket.override = allocation;
                }

                // once we hit a bucket with a percentage above zero, that should be the last bucket going up
                // the nest to get a new value
                if (pBucket.percentage > 0 && !foundHighestUsedBucket && !bucket.isFlex && !bucket.isProtected) {
                    bucket.highest = true;
                    foundHighestUsedBucket = true;
                } else {
                    bucket.highest = false;
                }

                // RLP-1517 - determine the highest bucket that has a fare...the last bucket
                if (pBucket.percentage > 0 && !foundMaxBucketWithFare && !bucket.isFlex && !bucket.isProtected && bucket.fare !== null) {
                    bucket.maxBucket = true;
                    foundMaxBucketWithFare = true;
                    maxBucketWithFare = bucket.name;
                } else {
                    bucket.maxBucket = false;
                }

            } else {
                if (!bucket.isProtected) {
                    bucket.override = this.overrideMetrics.capacity;
                } else {
                    bucket.override = undefined;
                }
            }
        });

        // add excess with a second pass

        this.findDistFromLeadBucket(maxBucketWithFare);

        // log some useful info
        console.log('fcstDemand: ' + fcstDemand);
        console.log('otb: ' + otb);
        console.log('nonProtectedotb: ' + nonProtectedOTB);
        console.log('totalAllocation: ' + this.overrideMetrics.totalAllocation);
        console.log('maxBucket: ' + maxBucketWithFare);

        this.buckets.forEach(bucket => {
            const pBucket = profile.buckets.find(pb => pb.bucket === bucket.name);

            if (pBucket) {
                let allocation = pBucket.percentage * demand;
                console.log('bucket: ' + bucket.name
                    + ', percentage: ' + pBucket.percentage
                    + ', alloc: ' + allocation
                    + ', excess: ' + excess
                    + ', leaddist: ' + bucket.leadBucketDistance
                    + ', fcstDemand: ' + Math.round(fcstDemand)
                    + ', maxBucket: ' + bucket.maxBucket);

                // RLP-1517 Added check for max bucket to not shift up excess on a zero demand journey
                if (Math.round(fcstDemand) === 0 && pBucket.percentage > 0 && (bucket.leadBucketDistance > 0 || bucket.maxBucket)) {
                    allocation += excess;
                } else if (allocation > nonProtectedOTB) {
                    // if bucket has space, and is greater than the lead bucket by the amount specified by the excess alloc param
                    // or if this is the lead bucket and there are protected bookings apply the excess.
                    if (bucket.leadBucketDistance >= this.overrideMetrics?.excess_alloc || bucket.highest) {
                        allocation += excess;
                    }
                    // The below condition is for cases where the excess was added to the lead bucket in P5.
                    if (bucket.leadBucketDistance === 0) {
                        const allocation_temp = Math.round(allocation + excess);
                        if (allocation_temp === bucket.recommended) {
                            allocation = allocation_temp
                        }
                    }
                }

                bucket.override = Math.round(allocation);
                console.log('bucket: ' + bucket.name + ', override: ' + bucket.override);
                bucket.allocEx = allocation.toFixed(1);
            }
            if (bucket.leadBucketDistance === 0) {
                if (bucket.leadBucketMin > 0) {
                   const nonProtectedOTB = otb - this.overrideMetrics.protectedTotalBookings;
                    if ((bucket.override - nonProtectedOTB) < bucket.leadBucketMin) {
                        // Add the delta diff of leadBucketMin and the
                        bucket.override = bucket.override + (bucket.leadBucketMin - (bucket.override - nonProtectedOTB));

                    }
               }
            }

        });

        super.scanBucketsUp();
        const checkValid = super.checkNestingBuckets();
        if (!checkValid) {
            this.nestBuckets();
        }
    }

    private updateSelectedProfile(profile: string): void {
        this.selectedProfile = profile;
        this.selectedProfileChange.emit(this.selectedProfile);
    }

    public nestBuckets() {
        const bucketLength = this.buckets.length - 1;
        let highest = 0;
        for (let i = bucketLength; i > 0; i--) {
            const bucket = this.buckets[i];
            if (bucket.override === null) {
                    bucket.override = bucket.current;
                    highest = bucket.current;
            }
            this.buckets[i] = bucket;
            const prevBucket = this.buckets[i - 1];
            if (bucket.isProtected) {
                if (prevBucket.override < highest) {
                    prevBucket.override = highest;
                }
                continue;
            }
            if (prevBucket.isFlex) {
                prevBucket.override = prevBucket.current;
            } else if (prevBucket.isProtected) {
                if (!(prevBucket.override > prevBucket.current)) {
                    prevBucket.override = prevBucket.current;
                }
            } else {
                if (highest > bucket.override) {
                    prevBucket.override = highest;
                } else if (prevBucket.override > bucket.override) {
                        if (!(bucket.isProtected)) {
                            highest = prevBucket.override;
                        }
                } else {
                        prevBucket.override = bucket.override;
                        if (!(bucket.isProtected)) {
                            highest = bucket.override;
                        }
                    }
            }
            this.buckets[i - 1] = prevBucket;
        }
        super.scanBucketsUp();
    }

}

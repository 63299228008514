import {BaseNotification} from './base-notification';
import * as moment from 'moment';
import {DateUtils} from '../../../date-utils';

export class ReOptNotification extends BaseNotification {
    public header = 'Re-Optimization';
    public iconClass = 'fa-retweet';

    constructor(private notificationData: any) {
        super(notificationData);
        this.title = notificationData.portfolio;
        this.updateHeader();
        this.buildDescription();
    }

    public getRouteInfo(): any {
        const filters = {
            rsid: '',
            origin: '',
            destination: '',
            startDate: moment(this.notificationData.startDate, 'YYYY-MM-DD').toDate(),
            endDate: moment(this.notificationData.endDate, 'YYYY-MM-DD').toDate(),
            portfolio: [this.notificationData.portfolio],
            statusType: 'Re-Optimized'
        };
        return {page: 'inventory', filters};
    }

    public routeDisabled(): boolean {
        return this.notificationData.status !== 'Success';
    }

    private buildDescription(): void {
        this.description = `Your re-optimization for ${DateUtils.convertToLocaleDateString(this.notificationData.startDate)} to
        ${DateUtils.convertToLocaleDateString(this.notificationData.endDate)} ${this.getStatusText()}`;
    }

    private updateHeader(): void {
        if (this.notificationData.status === 'Running') {
            this.header += ' in Progress';
        }
    }

    private getStatusText(): string {
        if (this.notificationData.status === 'Running') {
            return 'is running.';
        } else if (this.notificationData.status === 'Error') {
            return 'has encountered an error.';
        } else if (this.notificationData.status === 'Success') {
            return 'is complete.';
        }
        return 'has unknown status';
    }
}

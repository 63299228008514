import {Injectable, ViewContainerRef} from '@angular/core';
import {CapacityConstraintAlertComponent} from '../components/capacity-constraint-alert/capacity-constraint-alert.component';
import {DialogService} from '@progress/kendo-angular-dialog';

@Injectable({
    providedIn: 'root'
})
export class AlertDialogService {

    constructor(private dialogService: DialogService) {
    }


    public openConstraintAlertDialog(request: any, container: ViewContainerRef) {
        const dialogRef = this.dialogService.open({
            width: 320,
            appendTo: container,
            content: CapacityConstraintAlertComponent,
            actions: [
                { text: 'Cancel' },
                { text: 'Submit' }
            ]
        });
        const alertDialog = dialogRef.content.instance as CapacityConstraintAlertComponent;
        alertDialog.constraintAlert = request;
        return dialogRef;
    }

    public openReminderDeleteAlertDialog(container: ViewContainerRef) {
        return this.dialogService.open({
            appendTo: container,
            content: 'Are you sure you want to delete this reminder?',
            actions: [
                {text: 'No'},
                {text: 'Yes', primary: true}
            ]
        });
    }
}

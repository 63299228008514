import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {TopBkgChangeservice, TopBkgChangesModel} from './top-bkg-changes.service';
import {FilterItem} from '../../shared/filter-item.model';
import {SeriesClickEvent} from '@progress/kendo-angular-charts';
import {NavigateWithFiltersService} from '../../shared/navigate-with-filters.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {DateUtils} from '../../shared/date-utils';
import {ComponentConfiguration} from '../../core/models/config';

@Component({
    selector: 'app-top-bkg-changes',
    templateUrl: './top-bkg-changes.component.html',
    styleUrls: ['./top-bkg-changes.component.scss']
})
export class TopBkgChangesComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    public chartConfig: ComponentConfiguration;
    public chartPopupSettings = {
      popupClass: ['default-chart-tooltip-settings ']
    };

    topBkgChChartData: TopBkgChangesModel[];
    daysDropDownOptions = new FilterItem();
    days: number;
    private topBkgChangesData: any;

    constructor(private filterService: FilterService, private serv: TopBkgChangeservice, private navService: NavigateWithFiltersService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
        this.chartConfig = this.serv.getComponentConfig();
    }

    ngOnInit() {
        this.days = -7;
        this.daysDropDownOptions.loadAllValues(this.serv.getDaysDropDownOptions());
        this.daysDropDownOptions.selectedValues = { id: this.days };
    }

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }

    _loadData(searchOptions: any) {
        this.serv.loadTopBookingsChanges(searchOptions).subscribe(this._onLoadSuccess.bind(this));
    }

    _onLoadSuccess(resp: any) {
        this.topBkgChangesData = resp;
        this.topBkgChChartData = resp[Math.abs(this.days)];
    }

    onDaysDropDownChange(val: any) {
        this.days = val.id;
        this.topBkgChChartData = this.topBkgChangesData[Math.abs(this.days)];
    }

    onSeriesClick(e: SeriesClickEvent | any) {
        const dataItem = e.dataItem;
        const parts = dataItem.key.split('::');
        const odParts = parts[1].split('-');
        const filters = {
            rsid: dataItem.rsid,
            origin: odParts[0],
            dest: odParts[1],
            date: DateUtils.convertLocalDateStringToDate(dataItem.effDt),
            viewType: 'Train'
        };
        this.navService.navigateWithFilters('forecast', filters);
    }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment as env} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReOptimizationService {

    constructor(private http: HttpClient) {
    }

    public getReOptimizationStatus(): Observable<any> {
        return this.http.get<any>(`${env.apiBaseUrl}/reopt/status`);
    }

    public initiateReOptimization(requestArgs: any): Observable<any> {
        return this.http.post<any>(`${env.apiBaseUrl}/reopt/initiate`, requestArgs);
    }

    public checkReOptStatus(): any {
        return this.getReOptimizationStatus().pipe(
            map(m => {
                if (m.length > 0) {
                    throw new RangeError('Re-optimization is currently running');
                }
            })
        );
    }
}

export class RecordCommentDetails {
    public comments: UserComment[];
    public service: string;
    public passengerClass: string;
    public journeyOrigin: string;
    public journeyDestination: string;
    public departureDate: string;
}

export class UserComment {
    public comment: string;
    public date: Date;
    public userName: string;
}

export class SaveCommentRequest {
    public departureDate: Date;
    public service: string;
    public journeyOrigin: string;
    public journeyDestination: string;
    public passengerClass: string;

    constructor(private dataItem: any, public comment: string) {
        this.departureDate = dataItem.departureDate;
        this.service = dataItem.service;
        this.journeyOrigin = dataItem.journeyOrigin;
        this.journeyDestination = dataItem.journeyDestination;
        this.passengerClass = dataItem.passengerClass;
        this.dataItem = null;
    }
}

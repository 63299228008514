import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  VariableProfileSelectorService
} from "@shared/inventory-override/components/variable-profile-selector/variable-profile-selector.service";

@Component({
  selector: 'app-variable-profile-selector',
  templateUrl: './variable-profile-selector.component.html',
  styleUrls: ['./variable-profile-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VariableProfileSelectorComponent implements OnInit {

  public increaseOption = { label: 'Increase', value: 'increase' };
  public decreaseOption = { label: 'Decrease', value: 'decrease' }

  public variableProfileSelection = 'decrease';

  public selectionToggle = false;

  public dropdownData = Array.from({ length: 8 }, (_, i) => i + 1);
  public selectedValue: number;

  constructor(private variableSelectorSvc: VariableProfileSelectorService) { }

  ngOnInit(): void {
  }

  onOptionClick(value: string) {
    this.variableProfileSelection = value;
    this.selectionToggle = !this.selectionToggle;
    
  }

  onDropdownChange(value: any) {
    this.selectedValue = value;
    this.variableSelectorSvc.changeVariableProfileValues(this.variableProfileSelection, this.selectedValue);
  }
}

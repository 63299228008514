<div class="metrics-container">
    <div class="metric-content">
        <label>Pacing</label>
        <span>{{overrideMetrics.pacing | kendoNumber: 'p'}}</span>
    </div>
    <div class="metric-content">
        <label>Sensitivity</label>
        <span>{{overrideMetrics.sensitivity}}</span>
    </div>
    <div class="metric-content">
        <label>Competition</label>
        <span>{{overrideMetrics.compPctDiff}}</span>
    </div>
    <div class="metric-content">
        <label>Booked</label>
        <span>{{overrideMetrics.booked}}</span>
    </div>
    <div class="metric-content">
        <label>Proj Yield</label>
        <span>{{overrideMetrics.projectedYield | kendoNumber:'c2'}}</span>
    </div>
    <div class="metric-content">
        <label>LY Yield</label>
        <span>{{overrideMetrics.lyYield | kendoNumber:'c2'}}</span>
    </div>
</div>

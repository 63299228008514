import {Component, Input} from '@angular/core';
import {BaseSearchRequest, FilterItem} from '../../filter-item.model';
import {LookupService} from '../../lookup.service';

@Component({
    selector: 'app-filter-od-drop-downs',
    templateUrl: './filter-od-drop-downs.component.html',
    styleUrls: ['./filter-od-drop-downs.component.scss']
})
export class FilterODDropDownsComponent {
    @Input()
    public set journeyFilterConfig(config: any) {
        this.pFilterConfig = config;
    }

    public originData: FilterItem = new FilterItem();
    public destinationData: FilterItem = new FilterItem();
    public pFilterConfig = {
        dropDownType: 'multi',
        isRequired: true
    };

    constructor(private lookupSvc: LookupService) {
        this.originData.loadAllValues(this.lookupSvc.loadOrigins());
        this.destinationData.loadAllValues(this.lookupSvc.loadDestinations());
    }

    public clearAndSetValues(req: BaseSearchRequest): void {
        if (window.location.href.indexOf('/group-sales') > -1) {
            this.originData.selectedValue = req.origin ? req.origin.map(v =>
            ({id: v, name: v})) : this.originData.selectedValue;
            this.destinationData.selectedValue = req.destination ? req.destination.map(v =>
            ({id: v, name: v})) : this.destinationData.selectedValue;
        } else {
            this.originData.selectedValues = req.origin ? req.origin.map(v =>
                ({id: v, name: v})) : this.originData.selectedValues;
            this.destinationData.selectedValues = req.destination ? req.destination.map(v =>
                ({id: v, name: v})) : this.destinationData.selectedValues;
        }
    }

    public dropdownTagMapper(tags: any[]): any[] {
        return tags.length < 3 ? tags : [tags];
    }

    public getCurrentFilterConfig(type: any): any {
        if (type === 'groupSales') {
            const originConfig = {
            id: 'originData',
            name: 'Origin',
            values: this.originData.selectedValue
        };
            const destConfig = {
            id: 'destinationData',
            name: 'destination',
            values: this.destinationData.selectedValue
        };
            return [originConfig, destConfig];
        } else {
            const originConfig = {
                id: 'originData',
                name: 'Origin',
                values: this.originData.selectedValues
            };
            const destConfig = {
                id: 'destinationData',
                name: 'destination',
                values: this.destinationData.selectedValues
            };
            return [originConfig, destConfig];
        }
    }

    public getSelectedValues(filterConfig?: any): any {
        if (filterConfig?.dropDownType === 'multi' || this.pFilterConfig.dropDownType === 'multi') {
            if (window.location.href.indexOf('/group-sales') > -1) {
                return {
                origin: this.originData.selectedValue.name,
                destination: this.destinationData.selectedValue.name
                };
            }
            return {
                origin: this.originData.selectedValues.map(origin => origin.name),
                destination: this.destinationData.selectedValues.map(dest => dest.name)
            };
        } else {
            return {
                origin: this.originData.selectedValue.name,
                destination: this.destinationData.selectedValue.name
            };
        }
    }

    public isValid(): boolean {
        if (this.pFilterConfig.dropDownType === 'multi' && this.pFilterConfig.isRequired) {
            return (this.originData.selectedValues && this.originData.selectedValues.length > 0) &&
                (this.destinationData.selectedValues && this.destinationData.selectedValues.length > 0);
        }
        if (this.pFilterConfig.dropDownType === 'single' && this.pFilterConfig.isRequired) {
            return this.originData.selectedValue && this.destinationData.selectedValue;
        }
        return true;
    }

    public originChange(selectedValues: any): void {
        if (selectedValues.length > 0 && this.pFilterConfig.dropDownType === 'multi') {
            let destinations = [];
            selectedValues.forEach(value => {
                destinations = destinations.concat(value.destinations);
            });
            const distinct = (value: any, index: any, self: any) => {
                return self.indexOf(value) === index;
            };
            this.filterSelectableDestinations(destinations.filter(distinct));
            // now update selected destinations as per new filteredValues (LNERP1-716)
            for (let i = 0; i < this.destinationData.selectedValues.length; i++) {
                const selVal = this.destinationData.selectedValues[i];
                const ind = this.destinationData.filteredValues.findIndex(element => {
                    return (element.id === selVal.id);
                });
                if (ind < 0) {
                    this.destinationData.selectedValues.splice(i--, 1);
                }
            }
        } else if (selectedValues && this.pFilterConfig.dropDownType === 'single') {
            this.filterSelectableDestinations(selectedValues.destinations);
            this.destinationData.selectedValue = null;
        } else {
            this.destinationData.filteredValues = this.destinationData.allValues;
        }
    }

    // Should be called when populating OD filters from a view configuration
    public setValues(origin: any, destination: any): void {
        if (window.location.href.indexOf('/group-sales') > -1) {
            this.originData.selectedValue = origin;
            this.destinationData.selectedValue = destination;
            return;
        }
        if (this.pFilterConfig.dropDownType === 'multi') {
            this.originData.selectedValues = origin;
            this.destinationData.selectedValues = destination;
        } else {
            this.originData.selectedValue = origin;
            this.destinationData.selectedValue = destination;
        }
    }

    public valuesLoaded(): boolean {
        return this.originData.valuesLoaded && this.destinationData.valuesLoaded;
    }

    private filterSelectableDestinations(destinations: string[]): void {
        this.destinationData.filteredValues = this.destinationData.allValues.filter(d => {
            return destinations.find(dest => dest === d.id);
        });
    }
}

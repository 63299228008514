import {DateUtils} from '../../../shared/date-utils';
import {Component, Input, OnInit} from '@angular/core';
import {TopBarService} from './top-bar.service';
import { DemandForecastChartResult } from 'src/app/forecast/forecast.model';


@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
    @Input() pageTitle: string;
    @Input() appLogo: string;
    public appTitle = 'FareVantage';
    public displayLogo = true;
    public statusInfo = '';

    constructor(private topBarService: TopBarService) {
    }

    ngOnInit(): void {
        this.getStatusData();
    }

    private getStatusData(): void {
        this.topBarService.getStatusInfo().subscribe(results => {
            this.statusInfo = 'Overnight: ';
            const separator = ' | ';
            let refreshTime = 'Not run in the last 24 hours';

            // get overnight first
            results.forEach( (result) => {
                if (result.statusEvent === 'Overnight' && result.uiRefresh !== null) {
                    refreshTime = DateUtils.convertDateTimeToLocaleString(result.uiRefresh);
                }
            });

            this.statusInfo += refreshTime;

            // add intra-days
            results.forEach( (result) => {
                if (result.statusEvent !== 'Overnight' && result.uiRefresh !== null) {
                    refreshTime = DateUtils.convertDateTimeToLocaleString(result.uiRefresh);
                    this.statusInfo += separator + result.statusEvent + ': ' + refreshTime + ' ';
                }
            });

        }, error => {
            console.error(`Error retrieving status data: ${error.message}`);
        });
    }

    public showLogo(): boolean {
        return this.displayLogo;
    }

    public hideLogo(): void {
        this.displayLogo = false;
    }

}

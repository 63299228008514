import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appTabLabelWrapper]'
})
export class TabLabelWrapperDirective {

  constructor(public elementRef: ElementRef) { }

  focus(): void {
      this.elementRef.nativeElement.focus();
  }

  getOffsetLeft(): number {
      return this.elementRef.nativeElement.offsetLeft;
  }

  getOffsetRight(): number {
      return this.elementRef.nativeElement.offsetWidth;
  }

}

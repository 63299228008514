import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {SharedGrid} from '../../shared/grid.model';
import {SpEventImpactModel, SpEventImpactService} from './sp-event-impact.service';
import {NavigateWithFiltersService} from '../../shared/navigate-with-filters.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {TooltipDirective} from '@progress/kendo-angular-tooltip';
import {DateUtils} from '../../shared/date-utils';

@Component({
    selector: 'app-sp-event-impact',
    templateUrl: './sp-event-impact.component.html',
    styleUrls: ['./sp-event-impact.component.css']
})
export class SpEventImpactComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
    public tableGrid = new SharedGrid();
    public showTotalRemDem = false;
    public eventsHoverContent: string;

    constructor(private filterService: FilterService, private serv: SpEventImpactService, private navService: NavigateWithFiltersService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }

    _loadData(searchOptions: any) {
        this.serv.getSpecialEventImpacts(searchOptions).subscribe(this._onLoadSuccess.bind(this));
    }

    _onLoadSuccess(resp: SpEventImpactModel[]) {
        this.tableGrid.gridData = resp;
        this.tableGrid.gridSort = [];
    }

    toggleColumn($event, field: any) {
        $event.preventDefault();
        this.showTotalRemDem = !this.showTotalRemDem;
    }

    gridUserSelectionChange(selection: any) {
        const selectedData = selection.selectedRows[0].dataItem;
        const filters = {
            date: DateUtils.convertLocalDateStringToDate(selectedData.effDt),
            viewType: 'Train'
        };
        this.navService.navigateWithFilters('forecast', filters);
    }

    onEventsCellMouseEnter(event: any, hoverType: number, dataItem) {
        if (hoverType === 0) {
            this.eventsHoverContent = dataItem.eventsHoverContent;
            this.tooltipDir.toggle(event.target);
        } else if (hoverType === 1) {
            this.eventsHoverContent = dataItem.rsidHoverContent;
            this.tooltipDir.toggle(event.target);
        } else {
            this.eventsHoverContent = null;
        }
    }

    onEventsCellMouseLeave() {
        this.tooltipDir.hide();
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../core/services/config/config.service';
import {ComponentConfiguration} from '../../../core/models/config';
import {Observable} from 'rxjs';
import {DemandForecastChartResult} from '../../forecast.model';
import {UrlStore} from '../../../shared/url-store';
import {DateUtils} from '../../../shared/date-utils';
import {map} from 'rxjs/operators';
import {ChartUtils} from '../../../shared/helpers/chart-utils';

@Injectable({
    providedIn: 'root'
})
export class ForecastChartService {
    private defaultConfig = {
            id: 'dmdFcst',
            name: 'Demand Forecast Chart',
            type: 'chart',
            config: {
                metric: {
                    bkdDmd: {
                        name: 'Bkd Dmd',
                        style: {color: '#E6E7E8'}
                    },
                    fcstDmd: {
                        name: 'Fcst Dmd',
                        style: {color: '#0B3EF9'}
                    },
                    fcstOverride: {
                        name: 'Fcst Override',
                        style: {color: '#05053A'}
                    },
                    allocation: {
                        name: 'Allocation',
                        style: {color: '#333333'}
                    },
                    tyYield: {
                        name: 'TY Yield',
                        style: {color: '#71E2AF'}
                    },
                    lyYield: {
                        name: 'LY Yield',
                        style: {color: '#EE7E99'}
                    },
                    events: {
                        name: 'Events'
                    }
                }
            }
        };

    constructor(private http: HttpClient, private configSvc: ConfigService) {
    }

    public getComponentConfig(): ComponentConfiguration {
        const config = this.configSvc.getComponentConfiguration('forecast', 'dmdFcst');
        return config ? config : this.defaultConfig;
    }

    public getChartData(searchOptions: any): Observable<any> {
        return this.http.post<DemandForecastChartResult[]>(`${UrlStore.api.forecast.forecastChart}`,
            {
                ...searchOptions,
                startDate: DateUtils.convertDateToStringFormat(searchOptions.startDate),
                endDate: DateUtils.convertDateToStringFormat(searchOptions.endDate)
            })
            .pipe(map(resp => {
                return ChartUtils.convertChartRange(resp, searchOptions.viewType);
            }));
    }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ProfileSelectorService {
    private profileChange = new Subject<any>();

    profileChange$ = this.profileChange.asObservable();

    constructor() {
    }

    public changeSelectedProfile(selectedProfile: any): void {
        this.profileChange.next(selectedProfile);
    }
}

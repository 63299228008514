import {Component, Input} from '@angular/core';
import {DialogContentBase, DialogRef} from '@progress/kendo-angular-dialog';

@Component({
    selector: 'app-capacity-constraint-alert',
    templateUrl: './capacity-constraint-alert.component.html',
    styleUrls: ['./capacity-constraint-alert.component.scss']
})
export class CapacityConstraintAlertComponent extends DialogContentBase {
    @Input() constraintAlert: any;

    constructor(public dialog: DialogRef) {
        super(dialog);
    }

}

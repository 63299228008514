import { Observable } from 'rxjs/Observable';
import { FilterTimePickerValue } from './components/filter-time-picker/filter-time-picker.component';

export class FilterItem {
    public allValues: any;
    public filteredValues: any;
    public selectedValues: any;
    public selectedValue: any;
    lastFilterFn: (item: any) => any;
    public valuesLoaded = false;

    constructor() {
        this.selectedValues = [];
    }

    public loadAllValues(observableValues: Observable<any>, sortFunction?: () => any): void {
        observableValues.subscribe(response => {
            this.allValues = response.data;
            if (sortFunction) {
                this.allValues = this.allValues.sort(sortFunction);
            }
            this.filteredValues = this.allValues;
            this.valuesLoaded = true;
        });
    }

    filterItems(value: string) {
        const lst = this.lastFilterFn ? this.allValues.filter(this.lastFilterFn) : this.allValues;
        this.filteredValues = lst.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    filterItemsByFn(fn: (item: any) => any, sticky: boolean) {
        this.filteredValues = this.allValues.filter(fn);
        if (sticky) {
            this.lastFilterFn = fn;
        }
    }

    findByName(name: string) {
        return this.allValues.find(o => (o.name === name));
    }

    findById(id: string) {
        return this.allValues.find(o => (o.id === id));
    }

    clear() {
        if (this.selectedValues) {
            this.selectedValues = [];
        }
    }


}

export class BaseSearchRequest {
    public startDate: Date;
    public endDate: Date;
    public startDateRange: number;
    public endDateRange: number;
    public passengerClass: string;
    public portfolio: string[];
    public origin: string[];
    public destination: string[];
    public dayOfWeek: number[];
    public rsid: string[];
    public recordStatus?: string[];
    public viewType?: string;
    public customerType?: string;
    public statusType?: string;
    public viewLevel?: boolean;
    public journeyType?: string[];
    public daypart?: string[];
    public time?: FilterTimePickerValue;
    public groupSize?: number;
    public isNewSearch = false;
}

<div class="reminder-selector-container">
    <div>Reminder Date</div>
    <div>
        <kendo-datepicker [(value)]="reminderDate" [style.width.%]="100"
                          (valueChange)="onValueChange($event)"></kendo-datepicker>
    </div>
    <div>
        <i class="fa fa-times fa-lg delete-icon" [class.is-disabled]="!reminderDate"
                   (click)="deleteReminderClick()"></i>
    </div>
</div>

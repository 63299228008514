<app-side-tab-group [parentElement]="dialog?.dialog?.instance" [animationDuration]="'500ms'">
    <app-side-tab>
        <ng-template appTabLabel>
            <i class="fas fa-comment fa-2x"></i>
        </ng-template>
        <div style="width: 350px; height: 400px">
            <app-comments [commentDetails]="invOverride?.comments"></app-comments>
        </div>
    </app-side-tab>
    <app-side-tab>
        <ng-template appTabLabel>
            <i class="fas fa-chart-bar fa-2x"></i>
        </ng-template>
        <app-shoulder-chart [title]="'Journey Shoulder Chart'"
                            [chartRequest]="shoulderChartRequest"
                            [trainType]="'journey'"
                            [width]="350"
                            [padding]="10"
                            style="display: block; height: 350px"></app-shoulder-chart>
    </app-side-tab>
</app-side-tab-group>
<kendo-dialog-titlebar>
    {{title}}
</kendo-dialog-titlebar>
<div *ngIf="!loading" class="dialog-content-container">
    <div class="grid-container">
        <app-bucket-override-grid [buckets]="invOverride.buckets"
                                  [overrideMetrics]="invOverride.metrics"
                                  [(selectedProfile)]="selectedProfile"></app-bucket-override-grid>
    </div>
    <div class="info-container">
        <div class="metric-container">
            <app-override-metrics [overrideMetrics]="invOverride.metrics"></app-override-metrics>
        </div>
        <div class="profile-info-container">
            <app-profile-info [profileInfo]="{ recommendedProfile: invOverride.recommendedProfile,
                currentProfile: invOverride.currentProfile}"></app-profile-info>
        </div>
        <div class="profile-selector-container">
            <app-profile-selector [profiles]="invOverride.profiles"></app-profile-selector>
        </div>
        <div class="status-container">
            <kendo-dialog-actions class="ra-dialog-button-group">
                <button class="k-button ra-dialog-button" (click)="ShowBuckets()"> +/- Buckets</button>
                <button class="k-button ra-dialog-button" (click)="nestBuckets()"> AutoNest </button>
            </kendo-dialog-actions>
            <app-status-selector [isIgnored]="invOverride.isIgnored"
                                 (isIgnoredChange)="ignoredChanged($event)"></app-status-selector>
        </div>
        <div class="reminder-container">
            <app-reminder-selector
                    [(reminderDate)]="invOverride.reminderDate" (delete)="onDeleteReminder()"></app-reminder-selector>
        </div>
        <kendo-dialog-actions class="ra-dialog-button-group">
            <button class="k-button ra-dialog-button" (click)="onCancel()">Cancel</button>
            <button class="k-button ra-dialog-button" (click)="onSave()" [disabled]="!isValid()">Override
            </button>
            <button class="k-button ra-dialog-button" (click)="onApprove()">Approve</button>
        </kendo-dialog-actions>
    </div>
</div>
<div *ngIf="loading" class="loading-overlay">
    <div class="loading-background"></div>
    <div class="loading-icon">
        <i class="fas fa-2x fa-spinner fa-spin"></i>
    </div>
</div>

<div #alertContainer></div>
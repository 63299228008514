import { Input, Directive } from '@angular/core';

@Directive()
export class BaseDashboardComponent {
    applyFilterSubscription: any;
    @Input() public rowHt?: number;

    constructor() { }

}

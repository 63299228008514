import * as moment from 'moment';

export abstract class BaseNotification {
    public header: string;
    public dateLabel: string;
    public iconClass: string;
    public title: string;
    public description: string;

    constructor(public data: any) {
        this.convertNotificationDateLabel();
    }

    abstract getRouteInfo(): any;

    abstract routeDisabled(): boolean;

    private convertNotificationDateLabel(): void {
        const currentDate = moment();
        const notificationDate = moment(new Date(this.data.notificationDate));
        let unitOfTime = '';
        let timeDiff = currentDate.diff(notificationDate, 'minutes');

        if (timeDiff < 5) {
            this.dateLabel = 'now';
            return;
        }

        if (timeDiff >= 5 && timeDiff < 60) {
            unitOfTime = 'minute';
        } else if (timeDiff >= 60 && timeDiff < (60 * 24)) {
            unitOfTime = 'hour';
            timeDiff = currentDate.diff(notificationDate, 'hours');
        } else if (timeDiff >= (60 * 24) && timeDiff < (60 * 24 * 7)) {
            unitOfTime = 'day';
            timeDiff = currentDate.diff(notificationDate, 'days');
        } else {
            unitOfTime = 'week';
            timeDiff = currentDate.diff(notificationDate, 'weeks');
        }
        unitOfTime = timeDiff === 1 ? unitOfTime : `${unitOfTime}s`;
        this.dateLabel = `${timeDiff} ${unitOfTime} ago`;
    }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment as env } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  public getReOptNotifications(): Observable<any> {
      return this.http.get<any>(`${env.apiBaseUrl}/reopt/notifications`);
  }
}

<kendo-dialog *ngIf="active" (close)="closeDialog()" [minWidth]="350" [width]="width">
    <kendo-dialog-titlebar [ngStyle]="screenConfig.base">
        {{title}}
    </kendo-dialog-titlebar>
    <div class="walk-up-grid-container">
        <kendo-grid [data]="gridData"
                    [kendoGridSelectBy]="selectionKey"
                    [selectable]="tableGrid.selectableSettings"
                    [rowHeight]="tableGrid.options.rowHeight"
                    [height]="tableGrid.options.height"
                    [selectedKeys]="selectedRows"
                    [scrollable]="'virtual'"
                    [sortable]="true"
                    (cellClick)="cellClickHandler($event)"
                    (cellClose)="cellCloseHandler($event)"
                    (selectedKeysChange)="onSelectedKeysChange()">
            <kendo-grid-checkbox-column [showSelectAll]="true" [width]="35" [class]="'checkbox-cell'"
                                        [headerClass]="'ra-grid-header ra-grid-checkbox'">
                <ng-template kendoGridHeaderTemplate>
                    <input class="k-checkbox" kendoGridSelectAllCheckbox
                           id="selectAllCheckboxIdWalk"
                           [state]="selectAllState"
                           (selectAllChange)="onSelectAllChange($event)">
                    <label class="k-checkbox-label" for="selectAllCheckboxIdWalk"></label>
                </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column title="Leg" field="leg"
                               [headerClass]="'ra-grid-header'"></kendo-grid-column>
            <kendo-grid-column title="System Forecast" field="walkUpDemand" format="{0:n0}"
                               [headerClass]="'ra-grid-header'"></kendo-grid-column>
            <kendo-grid-column title="Excess" field="excessAllocation" format="{0:n0}"
                               [headerClass]="'ra-grid-header'"></kendo-grid-column>
            <kendo-grid-column title="Override" field="override" editor="numeric"
                               [headerClass]="'ra-grid-header'"></kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="row bulk-inputs">
        <div class="col-5">
            <label [for]="numOverride">Numeric Override</label>
            <input #numOverride title="" [(ngModel)]=numericOverride type="number"
                   [disabled]="isBulkInputsDisabled() || pctOverride.value > 0">
        </div>
        <div class="col-3">
            <label [for]="pctOverride">% Override</label>
            <input #pctOverride title="" [(ngModel)]="percentOverride" type="number"
                   [disabled]="isBulkInputsDisabled() || numOverride.value > 0">
        </div>
    </div>
    <kendo-dialog-actions>
        <button class="k-button" (click)="onCancel()">Cancel</button>
        <button class="k-button" (click)="onSave()" [disabled]="isSaveDisabled()">Save</button>
        <button class="k-button" (click)="onRevert()" [disabled]="isRevertDisabled()">Revert</button>
    </kendo-dialog-actions>
</kendo-dialog>
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {AnimationEvent} from '@angular/animations';
import {Subscription} from 'rxjs/Subscription';
import {Subject} from 'rxjs/Subject';
import {CdkPortalOutlet, PortalHostDirective, TemplatePortal} from '@angular/cdk/portal';
import {Direction, Directionality} from '@angular/cdk/bidi';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import {sideMenuTabsAnimations} from '../side-menu-animations';

export type SideTabBodyPositionState =
    'left'
    | 'center'
    | 'right'
    | 'left-origin-center'
    | 'right-origin-center';

@Component({
    selector: 'app-side-tab-body',
    templateUrl: './side-tab-body.component.html',
    styleUrls: ['./side-tab-body.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [
        sideMenuTabsAnimations.changeTab,
        sideMenuTabsAnimations.expandTab
    ]
})
export class SideTabBodyComponent implements OnInit, OnDestroy {
    private positionIndex: number;
    private dirChangeSubscription = Subscription.EMPTY;
    positionState: SideTabBodyPositionState;
    changeTabCompleted = new Subject<AnimationEvent>();

    @Output() readonly centering: EventEmitter<number> = new EventEmitter<number>();
    @Output() readonly beforeCentering: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() readonly afterLeavingCenter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() readonly centered: EventEmitter<void> = new EventEmitter<void>(true);
    @ViewChild(PortalHostDirective) portalHost: PortalHostDirective;
    @Input() content: TemplatePortal;
    @Input() origin: number;
    @Input() animationDuration = '200ms';
    @Input() active = false;

    @Input()
    set position(position: number) {
        this.positionIndex = position;
        this._computePositionAnimationState();
    }

    constructor(private elementRef: ElementRef<HTMLElement>,
                @Optional() private directionality: Directionality,
                changeDetectorRef?: ChangeDetectorRef) {
        if (this.directionality && changeDetectorRef) {
            this.dirChangeSubscription = this.directionality.change.subscribe((dir: Direction) => {
                this._computePositionAnimationState(dir);
                changeDetectorRef.markForCheck();
            });
        }

        this.changeTabCompleted.pipe(distinctUntilChanged((x, y) => {
            return x.fromState === y.fromState && x.toState === y.toState;
        })).subscribe(event => {
            if (this._isCenterPosition(event.toState) && this._isCenterPosition(this.positionState)) {
                this.centered.emit();
            }

            if (this._isCenterPosition(event.fromState) && !this._isCenterPosition(this.positionState)) {
                this.afterLeavingCenter.emit();
            }
        });
    }

    ngOnInit(): void {
        if (this.positionState === 'center' && this.origin != null) {
            this.positionState = this._computePositionFromOrigin();
        }
    }

    ngOnDestroy(): void {
        this.dirChangeSubscription.unsubscribe();
        this.changeTabCompleted.complete();
    }

    _onChangeTabStarted(event: AnimationEvent): void {
        const isCentering = this._isCenterPosition(event.toState);
        this.beforeCentering.emit(isCentering);
        if (isCentering) {
            this.centering.emit(this.elementRef.nativeElement.clientHeight);
        }
    }

    _getLayoutDirection(): Direction {
        return this.directionality && this.directionality.value === 'rtl' ? 'rtl' : 'ltr';
    }

    _isCenterPosition(position: SideTabBodyPositionState | string): boolean {
        return position === 'center' ||
            position === 'left-origin-center' ||
            position === 'right-origin-center';
    }

    private _computePositionAnimationState(dir: Direction = this._getLayoutDirection()) {
        if (this.positionIndex < 0) {
            this.positionState = dir === 'ltr' ? 'left' : 'right';
        } else if (this.positionIndex > 0) {
            this.positionState = dir === 'ltr' ? 'right' : 'left';
        } else {
            this.positionState = 'center';
        }
    }

    private _computePositionFromOrigin(): SideTabBodyPositionState {
        const dir = this._getLayoutDirection();

        if ((dir === 'ltr' && this.origin <= 0) || (dir === 'rtl' && this.origin > 0)) {
            return 'left-origin-center';
        }

        return 'right-origin-center';
    }

}

@Directive({
    selector: '[appSideTabBodyHost]'
})
export class SideTabBodyPortalDirective extends CdkPortalOutlet implements OnInit, OnDestroy {
    private centeringSub = Subscription.EMPTY;
    private leavingSub = Subscription.EMPTY;

    constructor(componentFactoryResolver: ComponentFactoryResolver,
                viewContainerRef: ViewContainerRef,
                @Inject(forwardRef(() => SideTabBodyComponent)) private sideTabBodyComponent: SideTabBodyComponent) {
        super(componentFactoryResolver, viewContainerRef);
    }

    ngOnInit() {
        super.ngOnInit();

        this.centeringSub = this.sideTabBodyComponent.beforeCentering
            .pipe(startWith(this.sideTabBodyComponent._isCenterPosition(this.sideTabBodyComponent.positionState)))
            .subscribe((isCentering: boolean) => {
                if (isCentering && !this.hasAttached()) {
                    this.attach(this.sideTabBodyComponent.content);
                }
            });

        this.leavingSub = this.sideTabBodyComponent.afterLeavingCenter.subscribe(() => {
            this.detach();
        });
    }

    ngOnDestroy(): void {
        this.centeringSub.unsubscribe();
        this.leavingSub.unsubscribe();
    }
}

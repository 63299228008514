import {Injectable} from '@angular/core';
import {CldrIntlService} from '@progress/kendo-angular-intl';
import {DateUtils} from '../date-utils';

@Injectable()
export class FirstDayIntlService extends CldrIntlService {

    constructor() {
        super(DateUtils.getUsersLocale());
    }

    public firstDay(): number {
        return 1;
    }
}

<kendo-chart [title]="title"
             [popupSettings]="defaultChartPopupSettings"
             [style.padding.px]="padding"
             [style.height.%]="100"
             [style.width.px]="width">
    <kendo-chart-tooltip [shared]="true">
        <ng-template kendoChartSharedTooltipTemplate let-category="category"
                     let-points="points">
            <div class="chart-tooltip-category">{{category}}</div>
              <div class="chart-tooltip-container">
                  <ng-container *ngFor="let point of points">
                      <div class="chart-tooltip-point-color">
                          <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                      </div>
                      <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                      <div class="chart-tooltip-point-value">{{point.value | number:'1.0-0'}}</div>
                  </ng-container>
              </div>
        </ng-template>
    </kendo-chart-tooltip>
    <kendo-chart-legend position="top"
                        orientation="horizontal"></kendo-chart-legend>
    <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [name]="'passengers'"
                                     [title]="{ text: 'Passengers' }"
                                     [min]="0">
        </kendo-chart-value-axis-item>
    </kendo-chart-value-axis>

    <kendo-chart-category-axis>
        <kendo-chart-category-axis-item  [majorGridLines]="{ visible: false }"
                                         [axisCrossingValue]="[0,10]">
        </kendo-chart-category-axis-item>
    </kendo-chart-category-axis>

    <kendo-chart-series>
        <kendo-chart-series-item type="column"
                                 [data]="chartData"
                                 [stack]="true"
                                 [axis]="'passengers'"
                                 [color]="chartConfig.config.metric.bkdDmd.style.color"
                                 [name]="chartConfig.config.metric.bkdDmd.name"
                                 [field]="'actual'" [categoryField]="'rsid'">
        </kendo-chart-series-item>
        <kendo-chart-series-item type="column"
                                 [data]="chartData"
                                 [stack]="true"
                                 [color]="chartConfig.config.metric.fcstDmd.style.color"
                                 [axis]="'passengers'"
                                 [name]="chartConfig.config.metric.fcstDmd.name"
                                 [field]="'forecast'" [categoryField]="'rsid'">
        </kendo-chart-series-item>
    </kendo-chart-series>
</kendo-chart>
<mat-card class="ra-card"
          [style.height.%]="100"
          style="width: 100%; height: 100%; box-sizing: border-box;">
    <mat-card-title class="card-title">Demand Forecast Chart</mat-card-title>
    <mat-card-content style="zoom: 1; height: 90%;">
        <kendo-chart [style.height.%]="100" [popupSettings]="popupSettings"
                     (seriesClick)="onSeriesClick($event)">
            <kendo-chart-tooltip [shared]="true" [opacity]=".8">
                <ng-template kendoChartSharedTooltipTemplate
                             let-category="category"
                             let-points="points">
                    <div class="chart-tooltip-category">{{category}}</div>
                    <div class="chart-tooltip-container">
                        <ng-container *ngFor="let point of points">
                            <div class="chart-tooltip-point-color">
                                <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                            </div>
                            <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                            <div class="chart-tooltip-point-value">{{formatChartTooltipValue(point)}}</div>
                        </ng-container>
                    </div>
                </ng-template>
            </kendo-chart-tooltip>
            <kendo-chart-panes>
                <kendo-chart-pane name="top"></kendo-chart-pane>
                <kendo-chart-pane name="bottom" [height]="'35'"></kendo-chart-pane>
            </kendo-chart-panes>
            <kendo-chart-legend position="top" orientation="horizontal"></kendo-chart-legend>
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [name]="'passengers'"
                                             [title]="{text: 'Passengers'}"
                                             pane="top"></kendo-chart-value-axis-item>
                <kendo-chart-value-axis-item [name]="'yield'"
                                             [title]="{text: 'Yield'}"
                                             pane="top"></kendo-chart-value-axis-item>
                <kendo-chart-value-axis-item [name]="'specialEvents'"
                                             [title]="{text: 'Events'}"
                                             pane="bottom"
                                             [labels]="{visible: false}"
                                             [majorGridLines]="{visible: false}"
                                             [max]="1"
                                             [min]="0"></kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [axisCrossingValue]="[0, chartData.length + 1]"
                                                [labels]="{visible: false}"
                                                [majorGridLines]="{visible: false}"
                                                pane="bottom">
                    <kendo-chart-category-axis-item-labels [rotation]="270"
                                                           [step]="1"
                                                           [position]="'start'"></kendo-chart-category-axis-item-labels>
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'realizedDemand'"
                                         [name]="chartConfig.config.metric.bkdDmd.name"
                                         [color]="chartConfig.config.metric.bkdDmd.style.color"
                                         [categoryField]="'range'"
                                         [axis]="'passengers'"
                                         [stack]="true"></kendo-chart-series-item>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'forecastDemand'"
                                         [name]="chartConfig.config.metric.fcstDmd.name"
                                         [color]="chartConfig.config.metric.fcstDmd.style.color"
                                         [categoryField]="'range'"
                                         [axis]="'passengers'"
                                         [stack]="true"></kendo-chart-series-item>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'forecastOverride'"
                                         [name]="chartConfig.config.metric.fcstOverride.name"
                                         [color]="chartConfig.config.metric.fcstOverride.style.color"
                                         [categoryField]="'range'"
                                         [axis]="'passengers'"
                                         [stack]="true"></kendo-chart-series-item>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'allocation'"
                                         [name]="chartConfig.config.metric.allocation.name"
                                         [color]="chartConfig.config.metric.allocation.style.color"
                                         [categoryField]="'range'"
                                         [axis]="'passengers'"
                                         [style]="'step'"
                                         type="line"></kendo-chart-series-item>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'projectedYield'"
                                         [name]="chartConfig.config.metric.tyYield.name"
                                         [color]="chartConfig.config.metric.tyYield.style.color"
                                         [categoryField]="'range'"
                                         [axis]="'yield'"
                                         type="line"></kendo-chart-series-item>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'lyYield'"
                                         [name]="chartConfig.config.metric.lyYield.name"
                                         [color]="chartConfig.config.metric.lyYield.style.color"
                                         [categoryField]="'range'"
                                         [axis]="'yield'"
                                         type="line"
                                         dashType="dash"></kendo-chart-series-item>
                <kendo-chart-series-item [data]="chartData"
                                         [field]="'specialEvents'"
                                         [name]="chartConfig.config.metric.events.name"
                                         [categoryField]="'range'"
                                         [axis]="'specialEvents'"
                                         [visibleInLegend]="false"
                                         [visual]="eventsVisual"></kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
        <div *ngIf="this.isLoading" class="k-i-loading"></div>
    </mat-card-content>
</mat-card>


export class SearchUtils {

    // A binary search function that returns the index of the item
    public static binarySearch(arr: any[], searchItem: any, compareFn): number {
        let startIndex = 0;
        let endIndex = arr.length - 1;
        while (startIndex <= endIndex) {
            const midIndex = Math.floor((startIndex + endIndex) / 2);
            const compareResult = compareFn(searchItem, arr[midIndex]);
            if (compareResult > 0) {
                startIndex = midIndex + 1;
            } else if (compareResult < 0) {
                endIndex = midIndex - 1;
            } else {
                return midIndex;
            }
        }
        return startIndex;
    }
}

<div class="notification-card" *ngIf="notification">
  <div class="card-container">
    <div class="notification-header">
      <span class="icon-container">
        <i class="icon fas {{notification.iconClass}}"></i>
      </span>
      <span class="header-text">{{notification.header}}</span>
      <span class="notification-short-date">
        <i class="icon fas fa-caret-right"></i>
        {{notification.dateLabel}}
      </span>
    </div>
    <div class="notification-info-container">
      <div class="info-title">{{notification.title}}</div>
      <span class="info-description">{{notification.description}}</span>
    </div>
  </div>
  <div *ngIf="!notification.routeDisabled()" class="action-container">
    <span (click)="viewNotification()">VIEW RESULTS</span>
    <!-- Hiding for right now until dismiss notification is implemented -->
    <span style="display: none;"
          (click)="dismissNotification()"
          [class.disabled]="notification.routeDisabled()">DISMISS</span>
  </div>
</div>

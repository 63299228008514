import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {environment as env} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateUtils} from '../../shared/date-utils';

@Injectable({
    providedIn: 'root'
})
export class SpEventImpactService {

    constructor(private http: HttpClient) {
    }

    public getSpecialEventImpacts(filterRequest: any): Observable<SpEventImpactModel[]> {
        return this.http.post<SpEventImpactModel[]>(`${env.apiBaseUrl}/dashboard/special_event`,
            {
                ...filterRequest,
                startDate: moment(filterRequest.startDate).format('YYYY-MM-DD'),
                endDate: moment(filterRequest.endDate).format('YYYY-MM-DD')
            }).pipe(map(resp => {
                resp.forEach(item => {
                    item.effDt = DateUtils.convertToLocaleDateString(item.effDt as string);
                    item.eventsHoverContent = 'Events:<br>' + item.eventNames.join('<br>');
                    item.rsidHoverContent = 'Services:<br>' + item.affectedRSIDs.join('<br>');
                });
                return resp;
        }));
    }
}

export class SpEventImpactModel {
    effDt: string | Date;
    eventsCount: number;
    eventNames: string[];
    pctDemand: number;
    impact: string;
    totalRemDem: string;
    affectedRSIDs: string[];
    eventsHoverContent?: string;
    rsidHoverContent?: string;
}

<div style="padding-top: 10px;">
  <!--  Row for 4 mini cards and revenue graph-->
  <div class="row" style="margin: 1px">
    <!--    column for 4 mini graphs-->
    <div class="col-35pct">
      <div class="row">
        <div class="col-50pct">
            <app-proj-rev-card
                    [dataKey]="'projectedRev'"
                    [allValues]="topLineMetrics"></app-proj-rev-card>
        </div>

        <div class="col-50pct">
          <mat-card class="ra-fieldset-card simple-card info-card">
            <mat-card-title class="card-title">
                Status Report
            </mat-card-title>
            <mat-card-content>
              <app-status-report></app-status-report>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div class="row">
        <div class="col-50pct">
          <mat-card class="ra-fieldset-card simple-card info-card">
            <mat-card-title class="card-title">
              Price
            </mat-card-title>

            <mat-card-content>
              <app-simple-value
                [dataKey]="'avgYield'"
                [hdr]="'Yield'"
                [valFmt]="'curr'"
                [label]="'since last year'"
                [allValues]="topLineMetrics"
              ></app-simple-value>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-50pct">
          <mat-card class="ra-fieldset-card simple-card info-card">
            <mat-card-title class="card-title">
              Passengers
            </mat-card-title>
            <mat-card-content>
              <app-simple-value
                [dataKey]="'numBkngs'"
                [hdr]="'Bookings'"
                [label]="'since last year'"
                [allValues]="topLineMetrics"
              ></app-simple-value>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div class="row">
        <div class="col-50pct">
          <mat-card class="ra-fieldset-card simple-card info-card">
            <mat-card-title class="card-title">
                Forecast Accepted
            </mat-card-title>

            <mat-card-content>
              <app-simple-value
                [dataKey]="'fcstAccepted'"
                [hdr]="'Fcst. % Accepted'"
                [valFmt]="'pct'"
                [subType]="'target'"
                [label]="'system target'"
                [allValues]="topLineMetrics"
              ></app-simple-value>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-50pct">
          <mat-card class="ra-fieldset-card simple-card info-card">
            <mat-card-title class="card-title">
                Inventory Accepted
            </mat-card-title>
            <mat-card-content>
              <app-simple-value
                [dataKey]="'fcstRejected'"
                [hdr]="'Inv. % Accepted'"
                [valFmt]="'pct'"
                [subType]="'target'"
                [label]="'system target'"
                [allValues]="topLineMetrics"
              ></app-simple-value>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <!--    column for revenue graph-->
    <div class="col-65pct" style="float: left;">
      <mat-card class="ra-card"
                [style.height.px]="435"
                style=" width: 100%; height: 100%; box-sizing: border-box;">
        <mat-card-title class="card-title">Revenue</mat-card-title>

        <mat-card-content style="zoom:1;">
          <kendo-chart class="forecast-chart" [style.height.px]="365" [popupSettings]="chartPopupSettings">
            <kendo-chart-tooltip [shared]="true">
              <ng-template
                kendoChartSharedTooltipTemplate
                let-category="category"
                let-points="points"
              >
                <div class="chart-tooltip-category">{{category}}</div>
                  <div class="chart-tooltip-container">
                      <ng-container *ngFor="let point of points">
                          <div class="chart-tooltip-point-color">
                              <span class="k-chart-shared-tooltip-marker" [style.background-color]="point.point.color"></span>
                          </div>
                          <div class="chart-tooltip-series-name">{{point.series.name}}</div>
                          <div class="chart-tooltip-point-value">{{formatChartTooltipValue(point)}}</div>
                      </ng-container>
                  </div>
              </ng-template>
            </kendo-chart-tooltip>
            <kendo-chart-panes>
              <kendo-chart-pane name="top"></kendo-chart-pane>
              <kendo-chart-pane name="bottom"></kendo-chart-pane>
            </kendo-chart-panes>
            <kendo-chart-legend
              position="top"
              orientation="horizontal"
            ></kendo-chart-legend>
            <kendo-chart-value-axis>
              <kendo-chart-value-axis-item
                [title]="{ text: 'Bkd Rev' }"
                [name]="'revenue'"
                [labels]="{ step: 2 }"
                [minorGridLines]="{ step: 2 }"
                [majorGridLines]="{ step: 2 }"
                pane="top"
              ></kendo-chart-value-axis-item>
              <kendo-chart-value-axis-item
                [title]="{ text: 'Yield' }"
                [name]="'yield'"
                [labels]="{ step: 2 }"
                [minorGridLines]="{ step: 2 }"
                [majorGridLines]="{ step: 2 }"
                pane="top"
              ></kendo-chart-value-axis-item>
              <kendo-chart-value-axis-item
                [title]="{ text: 'Pacing %' }"
                [name]="'pacing'"
                [minorGridLines]="{ step: 3 }"
                [majorGridLines]="{ step: 3 }"
                pane="bottom">
                  <kendo-chart-value-axis-item-labels format="p" step="3"></kendo-chart-value-axis-item-labels>
              </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item
                [axisCrossingValue]="[0, revenueChartData?.length + 1]"
                pane="bottom"
                [majorGridLines]="{ visible: false }"
              >
                <kendo-chart-category-axis-item-labels
                  [rotation]="270"
                  [step]="1"
                  [position]="'start'"
                ></kendo-chart-category-axis-item-labels>
              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
              <kendo-chart-series-item
                [data]="revenueChartData"
                [name]="revChartConfig.config.metric.rev.name"
                [categoryField]="'range'"
                [axis]="'revenue'"
                [field]="'revenue'"
                [color]="revChartConfig.config.metric.rev.style.color"
                [stack]="true"
              >
              </kendo-chart-series-item>
              <kendo-chart-series-item
                [data]="revenueChartData"
                [name]="revChartConfig.config.metric.projRev.name"
                [categoryField]="'range'"
                [axis]="'revenue'"
                [field]="'projectedRevenue'"
                [color]="revChartConfig.config.metric.projRev.style.color"
                [stack]="true"
              >
              </kendo-chart-series-item>
              <kendo-chart-series-item
                [data]="revenueChartData"
                [name]="revChartConfig.config.metric.lyYield.name"
                [color]="revChartConfig.config.metric.lyYield.style.color"
                [categoryField]="'range'"
                [axis]="'yield'"
                [field]="'lyYield'"
                type="line"
                dashType="dash"
              ></kendo-chart-series-item>
              <kendo-chart-series-item
                [data]="revenueChartData"
                [name]="revChartConfig.config.metric.tyYield.name"
                [color]="revChartConfig.config.metric.tyYield.style.color"
                [categoryField]="'range'"
                [axis]="'yield'"
                [field]="'tyYield'"
                type="line"
              ></kendo-chart-series-item>
              <kendo-chart-series-item
                [data]="revenueChartData"
                [name]="revChartConfig.config.metric.pacing.name"
                [color]="revChartConfig.config.metric.pacing.style.color"
                [categoryField]="'range'"
                [axis]="'pacing'"
                [field]="'pacing'"
                axis="pacing"
              ></kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!--  Row for tab graphs and top booking change-->
  <div class="row" style="margin: 0 10px 1px 1px;">
    <div class="col-50pct">
      <app-top-bkg-changes [rowHt]="350"></app-top-bkg-changes>
    </div>
    <div class="col-50pct">
      <app-combo-card-select [rowHt]="350"></app-combo-card-select>
    </div>
  </div>
</div>

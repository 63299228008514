import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {switchMap} from 'rxjs-compat/operators/switchMap';
import {tap} from 'rxjs/operators';
import {timer} from 'rxjs/internal/observable/timer';
import {NavigateWithFiltersService} from '../../../navigate-with-filters.service';
import {NotificationService} from '../notification.service';

@Component({
    selector: 'app-notification-menu',
    templateUrl: './notification-menu.component.html',
    styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
    @ViewChild('notification') private anchor: ElementRef;
    @ViewChild('popup', {read: ElementRef}) private popup: ElementRef;
    public show = false;
    public anchorAlign = {horizontal: 'right', vertical: 'bottom'};
    public popupAlign = {horizontal: 'left', vertical: 'bottom'};
    public notificationData = [];
    private pollSubscription: any;
    private pollWaitTime = 60000;

    constructor(private nav: NavigateWithFiltersService, private notificationService: NotificationService) {
    }

    ngOnInit(): void {
        this.startReOptPolling();
    }

    ngOnDestroy(): void {
        this.pollSubscription.unsubscribe();
    }

    public dismissNotification(notification: any): void {
        console.log('Not implemented');
    }

    public onToggle(show?: boolean): void {
        this.show = show !== undefined ? show : !this.show;
    }

    public routeNotification(routeInfo: any): void {
        if (routeInfo) {
            this.nav.navigateWithFilters(routeInfo.page, routeInfo.filters);
            this.onToggle(false);
        }
    }

    @HostListener('document:click', ['$event'])
    private documentClick(event: any): void {
        if (!this.popupContains(event.target)) {
            this.onToggle(false);
        }
    }

    private popupContains(target: any): boolean {
        return this.anchor.nativeElement.contains(target) ||
            (this.popup ? this.popup.nativeElement.contains(target) : false);
    }

    private startReOptPolling(): void {
        this.pollSubscription = timer(500, this.pollWaitTime)
            .pipe(switchMap(() => {
                return this.notificationService.getReOptNotifications()
                    .pipe(tap(r => {
                        this.notificationData = r;
                    }, error => {
                        console.error(error);
                    }));
            }));
        this.pollSubscription.subscribe();
    }
}

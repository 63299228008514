import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardComponent} from './dashboard.component';
import {SimpleValueComponent} from './simple-value/simple-value.component';
import {StatusReportComponent} from './status-report/status-report.component';
import {RevOppComponent} from './rev-opp/rev-opp.component';
import {RemindReportComponent} from './remind-report/remind-report.component';
import {ArcGaugeModule} from '@progress/kendo-angular-gauges';
import {SpEventImpactComponent} from './sp-event-impact/sp-event-impact.component';
import {ColumnResizingService, GridModule, SharedModule} from '@progress/kendo-angular-grid';
import {TopBkgChangesComponent} from './top-bkg-changes/top-bkg-changes.component';
import {OvrdAlertsComponent} from './ovrd-alerts/ovrd-alerts.component';
import {TopRevOppComponent} from './top-rev-opp/top-rev-opp.component';
import {PaceVsRemDemComponent} from './pace-vs-rem-dem/pace-vs-rem-dem.component';
import {TopCapConstComponent} from './top-cap-const/top-cap-const.component';
import {ChartsModule} from '@progress/kendo-angular-charts';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {ComboCardSelectComponent} from './combo-card-select/combo-card-select.component';
import {FormsModule} from '@angular/forms';
import { ProjRevCardComponent } from './proj-rev-card/proj-rev-card.component';
import {DialogModule} from '@progress/kendo-angular-dialog';

@NgModule({
    imports: [
        CommonModule,
        GridModule,
        ChartsModule,
        DropDownsModule,
        TooltipModule,
        ArcGaugeModule,
        MatRadioModule,
        MatTabsModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        FormsModule,
        SharedModule,
        DialogModule
    ],
    declarations: [
        DashboardComponent,
        SimpleValueComponent,
        StatusReportComponent,
        RevOppComponent,
        RemindReportComponent,
        SpEventImpactComponent,
        ComboCardSelectComponent,
        TopBkgChangesComponent,
        OvrdAlertsComponent,
        TopRevOppComponent,
        PaceVsRemDemComponent,
        TopCapConstComponent,
        ProjRevCardComponent
    ],
    providers: [
        ColumnResizingService
    ]
})
export class DashboardModule {
}

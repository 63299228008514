import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UrlStore} from '../../../shared/url-store';
import {AppConfiguration} from '../../models/config';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private appConfig: AppConfiguration;

    constructor(private http: HttpClient) {
    }

    public getAppConfig(): Observable<AppConfiguration> {
        return this.http.get<AppConfiguration>(`${UrlStore.api.config}/app`);
    }

    public getAppConfigObject(): AppConfiguration {
        if (!this.appConfig) {
            this.appConfig = JSON.parse(localStorage.getItem('appConfig'));
        }
        return this.appConfig;
    }

    public getConfig(): Observable<any> {
        return this.http.get(UrlStore.api.config);
    }

    public getComponentConfiguration(screenName: string, componentId: string): any {
        if (this.appConfig.hasOwnProperty(screenName)) {
            return this.appConfig[screenName].components.find(comp => comp.id === componentId);
        }
        return null;
    }

    public refreshAppConfig(): void {
        this.getAppConfig().subscribe(config => {
            this.appConfig = config;
            localStorage.setItem('appConfig', JSON.stringify(config));
        });
    }
}

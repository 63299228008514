import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../customer-menu-item.model';
import {UrlStore} from "@shared/url-store";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-customer-menu-item',
    templateUrl: './customer-menu-item.component.html',
    styleUrls: ['./customer-menu-item.component.scss']
})

export class CustomerMenuItemComponent implements OnInit {
    @Input() menuItem: MenuItem;

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
    }

     public swapTenant(customerId: any) {
        console.log(customerId);
        let params = {};
        params['customer_id'] = customerId.toString();
        params['customer_token'] = localStorage.getItem('id_token');
        let headers = new Headers();
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('X-Requested-With', 'XMLHttpRequest');
        const requestOptions = {
            headers: headers,
            params: params
        };

        return this.http.post<any>(UrlStore.api.swapTenant.swapTenantURL, requestOptions ).toPromise().then(response => {
            localStorage.setItem('appConfig', JSON.stringify(response));
            window.location.reload();
        });

    }


}

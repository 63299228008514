export enum OverrideType {
    Bulk = 'bulk',
    Single = 'single'
}

export enum StatusType {
    Active = 'Active',
    NeedsApproval = 'Needs Approval',
    New = 'New',
    OverrideIgnore = 'Override Ignore',
    OverrideWatch = 'Override Watch',
    UserFlag = 'User Flag',
    Approve = 'Approved',
    ReOptimized = 'Re-Optimized',
    AutopilotOff = 'Autopilot Off'
}

export enum BusinessRuleIndicator {
    Active = 'Active',
    Pending = 'Pending'
}

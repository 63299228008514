import {RowClassArgs} from '@progress/kendo-angular-grid';
import { Input, Directive } from '@angular/core';
import {Bucket} from '../../../inventory/inventory.model';

@Directive()
export class BaseBucketOverrideGrid {
    @Input() buckets: any;
    @Input() overrideMetrics?: any;

    constructor() {
    }

    public checkBucketStatus(bucket: any): boolean {
        return this.checkBucketClosedStatus(bucket);
    }

    public checkNestingBuckets(): boolean {
        const bucketLength = this.buckets.length - 1;
        let checkValid = true;
        for (let i = bucketLength; i >= 0; i--) {
            const bucket = this.buckets[i];
            if (bucket.override === null) {
                bucket.override = 0;
            }
            bucket.isValid = this.checkBucketNesting(bucket);
            if (!bucket.isValid) {
                checkValid = false;
            }
        }
       return checkValid;
    }

    public onClear(bucket: any): void {
        const index = this.buckets.indexOf(bucket);
        const bucketLength = this.buckets.length;
        for (let i = index; i < bucketLength; i++) {
            if (!this.buckets[i].isProtected) {
                this.buckets[i].override = 0;
            }
        }
        this.checkNestingBuckets();
    }

    public rowCallback(context: RowClassArgs): any {
        let showHidden = '0';
        if ('showHidden' in localStorage) {
            showHidden =  localStorage.getItem('showHidden');
        }
        context.dataItem.hidden = false;
        const classMap = {
            rowPadding: true,
            protected: false,
            invalid: false,
            closed: false,
            hidden: false
        };
        if (context.dataItem.isProtected) {
            classMap.protected = true;
        } else if (!context.dataItem.isValid) {
            classMap.invalid = true;
        } else if (context.dataItem.isClosed) {
            classMap.closed = true;
        }
        if (context.dataItem.booked === 0 && context.dataItem.fare === null) {
            if (showHidden === '0') {
                classMap.hidden = true;
            }
        }
        return classMap;
    }

    public scanBucketsUp(): void {
        let prevBucket = null;
        let nextBucket = null;
        const bucketLength = this.buckets.length - 1;
        let totalOTB = 0;
        let hasOverrides = false;
        let protectedBookings = 0;

        // determine if overrides have been applied
        for (let i = bucketLength; i >= 0; i--) {
            if (this.buckets[i].override > 0) {
                hasOverrides = true;
                break;
            }
        }

        // first reset all buckets to be valid
        for (let i = bucketLength; i >= 0; i--) {
            this.buckets[i].isValid = true;
            this.buckets[i].remaining = 0;
            this.buckets[i].recNoOtb = 0;

            if (hasOverrides) {
                this.buckets[i].recOrOveride = this.buckets[i].override;
            } else {
                this.buckets[i].recOrOveride = this.buckets[i].recommended;
            }

            this.buckets[i].isClosed = this.checkBucketClosedStatus(this.buckets[i]);

            if (this.buckets[i].isProtected) {
                this.buckets[i].override = undefined;
            }

        }

        // for remaining calculation, get the total OTB for nonflex and notProtected
        for (let i = bucketLength; i >= 0; i--) {
            if (!this.buckets[i].isFlex && !this.buckets[i].isProtected) {
                if (this.buckets[i].isProtected) {
                    protectedBookings += this.buckets[i].booked;
                    continue;
                } else {
                    totalOTB += this.buckets[i].booked;
                }
            }
        }

        const leadBucketName = this.findLeadBucketName(totalOTB);
        for (let i = bucketLength; i >= 0; i--) {
            if (!this.buckets[i].isFlex) {
                if (hasOverrides) {
                    this.buckets[i].recNoOtb = Math.max(0, this.buckets[i].override - totalOTB);
                } else {
                    this.buckets[i].recNoOtb = Math.max(0, this.buckets[i].recommended - totalOTB);
                }
            }
        }

        for (let i = bucketLength; i >= 0; i--) {
            const bucket = this.buckets[i];

            if (bucket.isProtected || bucket.isFlex) {
                if (hasOverrides) {
                    if (bucket.isProtected) {
                        this.buckets[i].remaining = Math.max(0, bucket.recommended - bucket.booked);
                    } else {
                        this.buckets[i].remaining = bucket.override - bucket.booked;
                    }
                } else {
                    if (bucket.isProtected) {
                        this.buckets[i].remaining = Math.max(0, bucket.recommended - bucket.booked);
                    } else {
                        this.buckets[i].remaining = bucket.recommended - bucket.booked;
                    }
                }
            } else {
                nextBucket = this.findNextBucket(i);
                if (nextBucket != null) {
                    this.buckets[i].remaining = this.buckets[i].recNoOtb - nextBucket.recNoOtb;
                } else {
                    this.buckets[i].remaining = this.buckets[i].recNoOtb;
                }
                if (this.buckets[i].name === leadBucketName) {
                    // If LeadBucketRecommendation  - TotalOTB < LeadBucketMinimum,
                    // then LeadBucketRecommendation = LeadBucketRecommendation + LeadBucketMinimum -(LeadBucketRecommendation  - TotalOTB)
                    if ((this.buckets[i].leadBucketMin > 0) && (this.buckets[i].override === 0)) {
                        this.buckets[i].remaining = Math.max(this.buckets[i].leadBucketMin, this.buckets[i].remaining);
                        if (this.buckets[i].recommended - totalOTB < this.buckets[i].leadBucketMin) {
                            this.buckets[i].recommended = this.buckets[i].recommended + (this.buckets[i].leadBucketMin - (
                                this.buckets[i].recommended - totalOTB));
                        }
                    }
                }
            }
            if (bucket.isProtected || !bucket.isValid) {
                // if this bucket is already invalid no need to check again
                continue;
            }
            // Check all previous buckets against this one
            for (let j = i - 1; j >= 0; j--) {
                prevBucket = this.findPreviousBucket(j);
                if (bucket.isValid && prevBucket) {
                    // both buckets are invalid if  bucket.override > prevBucket.override
                    bucket.isValid = (bucket.override <= prevBucket.override);
                }
                if (!prevBucket === null) {
                     if (prevBucket.isValid) {
                         prevBucket.isValid = (bucket.override <= prevBucket.override);
                     }
                }
            }
        }
    }

    private checkBucketNesting(bucket: any): boolean {
        if (bucket.isProtected) {
            return true;
        }

        const bucketIndex = this.buckets.indexOf(bucket);
        if (bucketIndex === 0) {
            return this.checkFirstBucketNesting(bucket, bucketIndex);
        } else if (bucketIndex === this.buckets.length - 1) {
            return this.checkLastBucketNesting(bucket, bucketIndex);
        } else {
            return this.checkMiddleBucketNesting(bucket, bucketIndex);
        }
    }

    private checkBucketClosedStatus(bucket: Bucket): boolean {
        if (bucket.isFlex || bucket.isProtected) {
            return false;
        }

        let otb = this.overrideMetrics?.intradayOtb - this.overrideMetrics?.protectedTotalBookings;
        if (otb === 0) {
            otb = this.overrideMetrics?.otbTotalDemand - this.overrideMetrics?.protectedTotalBookings;
        }
        return bucket.recOrOveride <= otb;
    }

    private checkFirstBucketNesting(bucket, bucketIndex): boolean {
        return !(bucket.override < this.buckets[bucketIndex + 1].override);
    }

    private checkLastBucketNesting(bucket, bucketIndex): boolean {
        return !(bucket.override > this.buckets[bucketIndex - 1].override);
    }

    private checkMiddleBucketNesting(bucket, bucketIndex): boolean {
        if (this.buckets[bucketIndex + 1].isProtected) {
            if (this.buckets.length -1 < bucketIndex + 2) {
                return !(bucket.override > this.buckets[bucketIndex - 1].override);
            }
            return !(bucket.override < this.buckets[bucketIndex + 2].override ||
                bucket.override > this.buckets[bucketIndex - 1].override);
        } else if (this.buckets[bucketIndex - 1].isProtected && !(this.buckets[bucketIndex - 1].isFlex)) {
            return !(bucket.override < this.buckets[bucketIndex + 1].override ||
                bucket.override > this.buckets[bucketIndex - 2].override);
        } else {
            return !(bucket.override < this.buckets[bucketIndex + 1].override ||
                bucket.override > this.buckets[bucketIndex - 1].override);
        }
    }

    private findPreviousBucket(index) {
        for (let i = index; i >= 0; i--) {
            const bucket = this.buckets[i];
            // skip all protected buckets
            if (!bucket.isProtected) {
                return bucket;
            }
        }
        return null;
    }

    private findNextBucket(index) {
        for (let i = index + 1; i <= this.buckets.length - 1; i++) {
            const bucket = this.buckets[i];
            // skip all protected and flex buckets
            if (bucket.isProtected) {
                    continue;
            } else if (!bucket.isProtected  && !bucket.isFlex) {
                return bucket;
            }
        }
        return null;
    }

    private findLeadBucketName(totalOTB) {
        let leadBucket: Bucket;

        // remove any protected buckets or buckets that are sold out
        const filteredBuckets = this.buckets.filter((bucket) => {
            if (bucket.isFlex || bucket.isProtected) {
                return false;
            } else {
                // use the recommended allocation unless it has been overriden, then use the override allocation
                // for overnight, this will always be recommended
                return bucket.recOrOveride > totalOTB;
            }
        });
        // if the train is not totally sold out, get the bucket with the lowest allocation, that will be the lead bucket
        if (filteredBuckets.length > 0) {
            leadBucket = filteredBuckets.reduce((p, c) => p.recOrOveride < c.recOrOveride ? p : c);
            if (leadBucket !== undefined) {
                return leadBucket.name;
            }
        }
        return null;
    }

    protected findDistFromLeadBucket(maxBucket) {
        const bucketLength = this.buckets.length - 1;
        let totalOTB = 0;
        let nextIndex = bucketLength;
        let maxBucketIndex = 0;

        // first reset all buckets to be valid
        for (let i = bucketLength; i >= 0; i--) {

            // assign an index to each non-flex/non-protected bucket
            if (!this.buckets[i].isFlex && !this.buckets[i].isProtected) {
                this.buckets[i].bucketIndex = nextIndex;
                nextIndex--;
            }

            // add to the total already booked
            if (!this.buckets[i].isFlex && !this.buckets[i].isProtected) {
                    totalOTB += this.buckets[i].booked;
            }

            if (this.buckets[i].name === maxBucket) {
                maxBucketIndex = this.buckets[i].bucketIndex;
            }
            console.log('bucket: ' + this.buckets[i].name
                + ', bucketIndex: ' + this.buckets[i].bucketIndex
                + ', totalOTB: ' + totalOTB
                + ', isFlex: ' + this.buckets[i].isFlex
                + ', isProtected: ' + this.buckets[i].isProtected
                + ', recommended: ' + this.buckets[i].recommended
                + ', override: ' + this.buckets[i].override);
        }

        // ignore flex and protected buckets
        const filteredBuckets = this.buckets.filter((bucket) => {
            if (bucket.isFlex || bucket.isProtected) {
                return false;
            } else {
                return Math.round(bucket.override) > totalOTB;
            }
        });

        // find the lead bucket and get the index
        let leadBucket: Bucket;
        let leadBucketIndex = 0;
        console.log('filteredBuckets: ' + JSON.stringify(filteredBuckets));
        if (filteredBuckets.length > 0) {
            leadBucket = filteredBuckets.reduce((p, c) => p.override < c.override ? p : c);
            if (leadBucket !== undefined) {
                leadBucketIndex = leadBucket.bucketIndex;
            }
        }
        if (leadBucketIndex === 0 && maxBucketIndex > 0) {
            console.log('No lead.  Setting lead bucket to max bucket: ' + maxBucketIndex);
            leadBucketIndex = maxBucketIndex;
        }

        // set the lead bucket distance for all buckets
        for (let i = bucketLength; i >= 0; i--) {
            this.buckets[i].leadBucketDistance = leadBucketIndex - this.buckets[i].bucketIndex;
        }
    }
}

import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import * as d3 from 'd3';
import {TubemapService} from '../../services/tubemap.service';
import {HeatmapModel} from '../../models/heatmap.model';
import {TubemapModel} from '../../models/tubemap.model';
import {FilterService} from '../../../shared/filter.service';
import {DateUtils} from '../../../shared/date-utils';
import {
    SharedChart,
    SharedChartMenuAction,
    SharedChartMenuEvent
} from '../../models/shared-chart.model';
import {HeatmapService} from '../../services/heatmap.service';

@Component({
    selector: 'app-single-tubemap',
    templateUrl: './single-tubemap.component.html',
    styleUrls: [
        '../../shared/styles/shared-chart.scss',
        './single-tubemap.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class SingleTubemapComponent implements OnChanges, OnDestroy {
    @ViewChild('tubemap', {static: true}) chartContainer: ElementRef;
    @Input() selectedTrain: HeatmapModel;
    @Input() selectedMetricId: string;
    public menuItems = [
        {text: 'View ODs', action: SharedChartMenuAction.viewODs},
        {text: 'Override Advance', action: SharedChartMenuAction.overrideAdvanced},
        {text: 'Override Walk-Up', action: SharedChartMenuAction.overrideWalkUp}
    ];
    public tubeMenuItems = this.menuItems.concat(
        [
            {text: 'Toggle Auto-Pilot', action: SharedChartMenuAction.toggleAutoPilot}
        ]);
    private axisArea = {
        trains: null,
        stations: null
    };
    private axisScales = {
        trains: null,
        stations: null
    };
    private data: TubemapModel;
    private margin = {
        top: 25,
        right: 85,
        bottom: 100,
        left: 100
    };
    private height = 0;
    private chartWidth = 0;
    private ro: ResizeObserver;
    private selectedTubeRecord;
    private svg = null;

    constructor(private tubemapSvc: TubemapService, private filterService: FilterService,
                private heatmapService: HeatmapService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTrain) {
            this.tubemapSvc.getTubemapData(this.buildTubeChartRequest()).subscribe(resp => {
                this.removeChartElement();
                this.data = resp;
                this.createChart();
            }, error => {
                console.error(`Error retrieving single tube data: ${error}`);
            });
        } else if (changes.selectedMetricId) {
            this.updateChartDataContent();
        }

        if (!this.ro) {
            this.ro = new ResizeObserver((entries, observer) => {
                for (const entry of entries) {
                    if (this.data) {
                        this.removeChartElement();
                        this.createChart();
                    }
                }
            });
            this.ro.observe(this.chartContainer.nativeElement.offsetParent);
        }
    }

    ngOnDestroy(): void {
        this.ro.unobserve(this.chartContainer.nativeElement.offsetParent);
    }

    public menuOpen(event: any): void {
        this.selectedTubeRecord = this.selectedTrain;
    }

    public onTubeClick(tubeContext: any): void {
        this.selectedTubeRecord = Object.assign({}, this.selectedTrain);
        this.selectedTubeRecord.origin = tubeContext.origin;
        this.selectedTubeRecord.destination = tubeContext.destination;
    }

    public processMenuItemClick(event: any): void {
        const menuClickEvent = new SharedChartMenuEvent();
        menuClickEvent.data = this.selectedTubeRecord;
        menuClickEvent.action = event.item.action;
        if (menuClickEvent.action === SharedChartMenuAction.toggleAutoPilot) {
            const autoDisabled = !this.data.tubes[0].autoPilotDisabled;
            d3.select(event.target).classed('auto-disabled', autoDisabled);
            this.data.tubes[0].autoPilotDisabled = autoDisabled;
            this.selectedTrain.autoPilotDisabled = autoDisabled;
        }
        if (menuClickEvent.action === SharedChartMenuAction.viewODs &&
            (this.selectedTubeRecord.origin && this.selectedTubeRecord.destination)) {
            this.highlightSelectedLeg();
        }
        if (menuClickEvent.action === SharedChartMenuAction.viewODs) {
            this.svg.select('.chart-content').selectAll('.tube-row').selectAll('.selected-leg').remove();
            if (this.selectedTubeRecord.origin && this.selectedTubeRecord.destination) {
                this.highlightSelectedLeg();
            }
        }
        this.heatmapService.menuClick(menuClickEvent);
    }

    private addCircle(tubeRow, od, idx): void {
        tubeRow.append('circle')
            .attr('class', 'bar')
            .attr('cx', this.axisScales.stations(od.origin))
            .attr('cy', this.axisScales.trains(idx))
            .attr('r', 6);
        tubeRow.append('circle')
            .attr('class', 'bar')
            .attr('cx', this.axisScales.stations(od.destination))
            .attr('cy', this.axisScales.trains(idx))
            .attr('r', 6);
    }

    private buildTubeChartRequest(): any {
        const currFilter = this.filterService.getCurrentFilter();
        return {
            passengerClass: currFilter.passengerClass,
            startDate: this.selectedTrain.effDepartureDate,
            endDate: this.selectedTrain.effDepartureDate,
            rsid: [this.selectedTrain.rsid],
            journeyOrigin: currFilter.origin,
            journeyDestination: currFilter.destination,
            recordStatus: currFilter.recordStatus
        };
    }

    private createChart(): void {
        const element = this.chartContainer.nativeElement;
        this.height = 50;
        this.chartWidth = element.clientWidth - this.margin.left + this.margin.right - 17;

        if (this.height < 0 || this.chartWidth < 0) {
            return;
        }

        this.svg = d3.select(element).append('svg')
            .attr('width', this.chartWidth)
            .attr('height', this.height)
            .append('g')
            .attr('transform', `translate(${this.margin.left - 25}, ${this.margin.top})`);

        const stationWidth = this.chartWidth - this.margin.left + 25;
        this.axisScales.stations = d3.scalePoint()
            .rangeRound([0, stationWidth])
            .padding(.1)
            .domain(this.data.points);

        const trainHeight = this.height - this.margin.top;
        this.axisScales.trains = d3.scalePoint()
            .rangeRound([trainHeight, 0])
            .padding(.1)
            .domain(this.data.tubes.map((d, idx) => idx));

        this.axisArea.stations = d3.axisTop(this.axisScales.stations);
        this.axisArea.trains = d3.axisLeft(this.axisScales.trains);

        this.svg.append('g').attr('class', 'train-axis');
        this.updateTrainAxis();

        this.svg.append('g').attr('class', 'station-axis');
        this.updateStationAxis();

        this.svg.append('g').attr('class', 'chart-content');
        this.updateChartDataContent();
    }

    private highlightSelectedLeg(): void {
        const tubeRow = this.svg.select('.chart-content').selectAll('.tube-row');
        tubeRow.append('rect')
                .attr('class', 'selected-leg')
                .attr('x', this.axisScales.stations(this.selectedTubeRecord.origin) - 10)
                .attr('y', this.axisScales.trains(0) - 8)
                .attr('rx', 10)
                .attr('height', 12 + 3)
                .attr('width', (this.axisScales.stations(this.selectedTubeRecord.destination) -
                    this.axisScales.stations(this.selectedTubeRecord.origin)) + 21)
                .lower();
    }

    private removeChartElement(): void {
        d3.select(this.chartContainer.nativeElement).select('svg').remove();
    }

    private updateChartDataContent(): void {
        this.svg.select('.chart-content').selectAll('.tube-row').remove();
        this.data.tubes.forEach((tube, idx) => {
            const tubeRow = this.svg.select('.chart-content')
                .append('g')
                .attr('class', 'tube-row');

            tube.segments.forEach(od => {
                const tubeContext = {
                    origin: od.origin,
                    destination: od.destination,
                    rsid: tube.rsid,
                    effDepartureDate: tube.effDepartureDate,
                    passengerClass: tube.passengerClass
                };

                const yTrain = this.axisScales.trains(idx);

                tubeRow.append('line')
                    .attr('class', `menu-hook line ${SharedChart.getMetricColorClass(od[this.selectedMetricId],
                        this.selectedMetricId)}-color`)
                    .attr('x1', this.axisScales.stations(od.origin))
                    .attr('y1', yTrain)
                    .attr('x2', this.axisScales.stations(od.destination))
                    .attr('y2', yTrain)
                    .attr('stroke-linecap', 'round')
                    .on('click', this.onTubeClick.bind(this, tubeContext));

                tubeRow.call(this.addCircle.bind(this, tubeRow, od, idx));

            });
        });
    }

    private updateStationAxis(): void {
        this.svg.select('.station-axis')
            .call(this.axisArea.stations);
    }

    private updateTrainAxis(): void {
        this.svg.select('.train-axis')
            .call(this.axisArea.trains);

        this.svg.selectAll('.train-axis .tick text').remove();
        this.svg.selectAll('.train-axis .tick')
            .classed('auto-disabled', d => this.data.tubes[d].autoPilotDisabled)
            .append('text')
            .attr('x', -10)
            .attr('y', -3)
            .attr('fill', 'currentColor')
            .text(d => this.data.tubes[d].rsid);
        this.svg.selectAll('.train-axis .tick').append('text')
            .attr('x', -10)
            .attr('y', 7)
            .attr('fill', 'currentColor')
            .text(d => DateUtils.convertToLocaleDateString(this.data.tubes[d].effDepartureDate));
    }

}

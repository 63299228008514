<mat-card #container class="ra-card" [style.height.%]="100">
    <mat-card-title class="card-title">
        <div class="heat-card-title-container">
            <div class="">
                Heatmap
            </div>
            <div class="metrics-selector">
                <kendo-dropdownlist
                        [data]="heatMapOrderOptions.allValues"
                        [(value)]="heatMapOrderOptions.selectedValues"
                        [popupSettings]="{appendTo: 'component'}"
                        [textField]="'name'"
                        [valueField]="'id'"
                        class="filter-drop-down"
                        (selectionChange)="onHeatmapOrderChange($event)">
                </kendo-dropdownlist>
            </div>
            <div class="metrics-selector">
                <kendo-dropdownlist
                        [data]="heatmapMetricOptions.allValues"
                        [(value)]="heatmapMetricOptions.selectedValues"
                        [popupSettings]="{appendTo: 'component'}"
                        [textField]="'name'"
                        [valueField]="'id'"
                        class="filter-drop-down"
                        (selectionChange)="onHeatmapMetricChange($event)">
                </kendo-dropdownlist>
            </div>
        </div>
    </mat-card-title>
    <mat-card-content [style.height.%]="90">
        <div class="spinner-wrapper">
            <mat-tab-group class="heatmap-tab-group"
                           (selectedTabChange)="chartChange($event)">
                <ng-container *ngFor="let tab of heatmapMarketTabs">
                    <mat-tab [label]="tab.name">
                        <ng-template matTabContent>
                            <app-heatmap [data]="tab.data"
                                         [selectedMetric]="selectedMetricOption"
                                         [selectedOrder]="selectedOrderOption"></app-heatmap>
                        </ng-template>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
            <div *ngIf="heatmapLoading" class="k-i-loading" [style.height.%]="100"></div>
        </div>
    </mat-card-content>
</mat-card>
import {
    animate,
    AnimationTriggerMetadata,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

export const sideMenuTabsAnimations: {
    readonly changeTab: AnimationTriggerMetadata;
    readonly expandTab: AnimationTriggerMetadata;
} = {
    changeTab: trigger('changeTab', [
        state('center, void, top-origin-center, bottom-origin-center', style({transform: 'none'})),
        state('top', style({transform: 'translate3d(-100%, 0, 0)', minHeight: '1px'})),
        state('bottom', style({transform: 'translate3d(100%, 0, 0)', minHeight: '1px'})),

        transition('* => top, * => bottom, top => center, bottom => center',
            animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')),
        transition('void => top-origin-center', [
            style({transform: 'translate3d(-100%, 0, 0)'}),
            animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')
        ]),
        transition('void => bottom-origin-center', [
            style({transform: 'translate3d(100%, 0, 0)'}),
            animate('{{animationDuration}} cubic-bezier(0.35, 0, 0.25, 1)')
        ])
    ]),
    expandTab: trigger('expandCollapseTrigger', [
        transition(':enter', [
            style({width: 0}),
            animate('{{animationDuration}}', style({width: '*'}))
        ]),
        transition(':leave', [
            style({width: '*'}),
            animate('{{animationDuration}}', style({width: 0}))
        ])
    ])
};

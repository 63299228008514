<div class="comment-area-container">
    <div #userCommentContainer class="comment-area comment-display">
        <div class="user-comment-container" *ngFor="let userComment of commentDetails?.comments">
            <app-user-comment [userComment]="userComment"></app-user-comment>
        </div>
    </div>
    <div class="comment-area comment-input-area">
        <div style="height: 100%;">
            <div class="comment-input-container">
                <div class="text-input-container">
                    <div #inputArea class="text-input-area" contenteditable="true"
                         role="textbox"
                         (keydown.enter)="saveComment($event, inputArea)"
                         (paste)="onPaste($event)"></div>
                </div>
                <span class="text-input-action" (click)="saveComment($event, inputArea)">
                    <i class="far fa-comment-alt"></i>
                </span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="loading-overlay">
    <div class="loading-background"></div>
    <div class="loading-icon">
        <i class="fas fa-2x fa-spinner fa-spin"></i>
    </div>
</div>

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariableProfileSelectorService {

  private variableProfileChangeType = new Subject<any>();

  variableProfileChangeType$ = this.variableProfileChangeType.asObservable();

  constructor() {
  }

  public changeVariableProfileValues(variableProfileChangeType: any,
                                     variableProfileChangeValue: number): void {
    console.log(variableProfileChangeType);
    console.log(variableProfileChangeValue);
    const value_type = String(variableProfileChangeType) + '_' + String(variableProfileChangeValue)
    if (variableProfileChangeValue === undefined) {
      this.variableProfileChangeType.next(undefined);
    } else {
      this.variableProfileChangeType.next(value_type);
    }

  }
}


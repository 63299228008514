import {Component, Input, OnInit} from '@angular/core';
import {DialogAction} from '@progress/kendo-angular-dialog';
import {FilterToolbarService} from '../shared/filter-toolbar.service';

@Component({
  selector: 'app-save-filter-btn',
  templateUrl: './save-filter-btn.component.html',
  styleUrls: ['./save-filter-btn.component.scss']
})
export class SaveFilterBtnComponent implements OnInit {
  @Input() set filters(filterViews) {
    this.convertToNames(filterViews);
  }
  public dialogActions = [
      { text: 'Cancel' },
      { text: 'Save' }
  ];
  public dateOptionSwitch = false;
  public existingFilterNamesSource: string[] = [];
  public existingFilterNamesData: string[] = [];
  public saveDialogOpened = false;
  public overwriteDialogOpened = false;
  public currentInput: string;

  constructor(private filterToolbarService: FilterToolbarService) { }

  ngOnInit() {
  }

  public onAction(action: DialogAction): void {
    if (action.text === 'Save') {
      this.save();
    } else {
      this.cancel();
    }
  }

  public close(): void {
    if (this.overwriteDialogOpened) {
      this.overwriteDialogOpened = false;
    } else {
      this.saveDialogOpened = false;
      this.clearInput();
    }
  }

  public filterDropDownChange(filterName: string): void {
    this.existingFilterNamesData = this.existingFilterNamesSource.filter(existingName => {
      return existingName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
    });
  }

  public open(): void {
    this.saveDialogOpened = true;
  }

  private save(): void {
    if (this.checkForDuplicateFilterName()) {
      this.overwriteDialogOpened = true;
    } else {
      this.filterToolbarService.saveFilter(this.currentInput, this.dateOptionSwitch);
      this.overwriteDialogOpened = false;
      this.saveDialogOpened = false;
      this.clearInput();
    }
  }

  private cancel(): void {
    this.close();
  }

  private checkForDuplicateFilterName(): boolean {
    const isDuplicateName = this.existingFilterNamesSource.includes(this.currentInput);
    return !this.overwriteDialogOpened && isDuplicateName;
  }

  private clearInput(): void {
    this.currentInput = '';
  }

  private convertToNames(filterViews): void {
    if (filterViews) {
      this.existingFilterNamesSource = filterViews.map(fv => fv.name);
      this.existingFilterNamesData = this.existingFilterNamesSource;
    } else {
      this.existingFilterNamesSource = [];
      this.existingFilterNamesData = [];
    }
  }

}

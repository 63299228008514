<div *ngIf="pFilterConfig.dropDownType === 'multi'">
    <kendo-multiselect [data]="originData.allValues"
                       [(value)]="originData.selectedValues"
                       (valueChange)="originChange($event)"
                       [autoClose]="false"
                       [textField]="'name'"
                       [popupSettings]="{popupClass: 'drop-down-popup', appendTo: 'component'}"
                       [tagMapper]="dropdownTagMapper"
                       [valueField]="'id'" class="filter-drop-down"></kendo-multiselect>
    <kendo-multiselect [data]="destinationData.filteredValues"
                       [(value)]="destinationData.selectedValues"
                       [autoClose]="false"
                       [textField]="'name'"
                       [popupSettings]="{popupClass: 'drop-down-popup', appendTo: 'component'}"
                       [tagMapper]="dropdownTagMapper"
                       [valueField]="'id'" class="filter-drop-down"></kendo-multiselect>
</div>
<div *ngIf="pFilterConfig.dropDownType === 'single'">
    <kendo-dropdownlist [data]="originData.allValues"
                        [(value)]="originData.selectedValue"
                        [popupSettings]="{appendTo: 'component'}"
                        (valueChange)="originChange($event)"
                        [textField]="'name'"
                        [valueField]="'id'" class="filter-drop-down"></kendo-dropdownlist>
    <kendo-dropdownlist [data]="destinationData.filteredValues"
                        [(value)]="destinationData.selectedValue"
                        [popupSettings]="{appendTo: 'component'}"
                        [textField]="'name'"
                        [valueField]="'id'" class="filter-drop-down"></kendo-dropdownlist>
</div>



import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

    constructor(private auth: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (state.url === '/group-sales') {
            const value = JSON.parse(localStorage.getItem('userConfig'));
            const groupSalesKeyName = 'hasGroupSalesAccess';
            if (value[groupSalesKeyName] === true) {
                return this.hasAppAccess(state.url);
            } else {
                window.location.replace(window.location.href.replace('group-sales', 'dashboard'));
                return value[groupSalesKeyName];
            }
        }
        return this.hasAppAccess(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.hasAppAccess(state.url);
    }

    private hasAppAccess(url: string): boolean {
        const isLoggedIn = this.auth.isLoggedIn();
        if (!isLoggedIn) {
            this.auth.beginLogin(url);
            return false;
        }
        return true;
    }
}

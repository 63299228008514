<kendo-excelexport [data]="gridData" [fileName]="fileName" #excelExportGrid>
  <ng-template ngFor let-col [ngForOf]="gridColumns">
    <kendo-excelexport-column field="{{col.field}}" title="{{col.title}}" [cellOptions]="col.cellOpt"></kendo-excelexport-column>
  </ng-template>
</kendo-excelexport>
<kendo-excelexport #excelExportFilter>
</kendo-excelexport>
<kendo-dialog *ngIf="dialogVisible" [minWidth]="350" (close)="closeDialog()">
  <kendo-dialog-titlebar>
    Excel Export Save
  </kendo-dialog-titlebar>
  <div>
    <label [for]="inputFileName" [style.margin-right.px]="10">Enter a file name</label>
    <input #inputFileName [(ngModel)]="fileName">
  </div>
  <kendo-dialog-actions class="ra-dialog-button-group">
    <button class="k-button ra-dialog-button" (click)="onCancel($event)">Cancel</button>
    <button class="k-button ra-dialog-button" (click)="onSave(excelExportGrid, excelExportFilter)">Save</button>
  </kendo-dialog-actions>
</kendo-dialog>

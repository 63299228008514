import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationMenuComponent} from './notification-menu/notification-menu.component';
import {NotificationCardComponent} from './notification-card/notification-card.component';
import {PopupModule} from '@progress/kendo-angular-popup';
import {NotificationService} from '@progress/kendo-angular-notification';


@NgModule({
    declarations: [
        NotificationMenuComponent,
        NotificationCardComponent
    ],
    imports: [
        CommonModule,
        PopupModule
    ],
    exports: [
        NotificationMenuComponent
    ],
    providers: [
        NotificationService
    ]
})
export class NotificationModule {
}

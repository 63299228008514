<div class="profile-selector-title">
    <span>Profile Increase/Decrease</span>
</div>
<div class="variable-profile-outer-class">
    <div class="variable-profile-left">
        <div class="variable-profile-class">
            <input
                    type="radio"
                    [value]="increaseOption.value"
                    [checked]="selectionToggle"
                    (click)="onOptionClick(increaseOption.value)"
            />
            <label>Increase</label>
        </div>
        <div class="variable-profile-class">
            <input
                    type="radio"
                    [value]="decreaseOption.value"
                    [checked]="!selectionToggle"
                    (click)="onOptionClick(decreaseOption.value)"
            />
            <label>Decrease</label>
        </div>

    </div>
    <div class="variable-profile-right">
        <div class="variable-profile-class-combobox">
            <kendo-combobox
                    [data]="dropdownData"
                    [valuePrimitive]="true"
                    (valueChange)="onDropdownChange($event)"
            >
            </kendo-combobox>

            <div>
                Selected Value: {{ selectedValue }}
            </div>
        </div>
    </div>
</div>
import {Component, OnDestroy} from '@angular/core';
import {HeatmapModel} from '../../models/heatmap.model';
import {TubemapService} from '../../services/tubemap.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tubemap-container',
    templateUrl: './tubemap-container.component.html',
    styleUrls: ['./tubemap-container.component.scss']
})
export class TubemapContainerComponent implements OnDestroy {
    public isVisible = false;
    public selectedTrain: HeatmapModel;
    public selectedMetricId: string;

    private changeMetricSubscription: Subscription;
    private closeSubscription: Subscription;
    private displaySubscription: Subscription;

    constructor(private tubemapSvc: TubemapService) {
        this.changeMetricSubscription = this.tubemapSvc.changeMetric$.subscribe(this.changeMetric.bind(this));
        this.closeSubscription = this.tubemapSvc.closeTubemap$.subscribe(this.close.bind(this));
        this.displaySubscription = this.tubemapSvc.displayTubemap$.subscribe(this.open.bind(this));
    }

    ngOnDestroy(): void {
        this.changeMetricSubscription.unsubscribe();
        this.closeSubscription.unsubscribe();
        this.displaySubscription.unsubscribe();
    }

    private changeMetric(metricId): void {
        if (this.isVisible) {
            this.selectedMetricId = metricId;
        }
    }

    public close(): void {
        this.isVisible = false;
    }

    public open({tube, metricId}): void {
        this.isVisible = true;
        this.selectedTrain = tube;
        this.selectedMetricId = metricId;
    }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterService} from '../../shared/filter.service';
import {SharedGrid} from '../../shared/grid.model';
import {RemindReportModel, RemindReportService} from './remind-report.service';
import {NavigateWithFiltersService} from '../../shared/navigate-with-filters.service';
import {BaseDashboardComponent} from '../base-dashboard-component';
import {DateUtils} from '../../shared/date-utils';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {ReminderService} from '../../shared/services/reminder.service';
import {ReminderRequest} from '../../shared/models/reminder.model';

@Component({
    selector: 'app-remind-report',
    templateUrl: './remind-report.component.html',
    styleUrls: ['./remind-report.component.css']
})
export class RemindReportComponent extends BaseDashboardComponent implements OnInit, OnDestroy {
    public tableGrid = new SharedGrid();
    public isLoading = false;
    private searchOptions: any;

    constructor(private filterService: FilterService, private serv: RemindReportService, private navService: NavigateWithFiltersService,
                private dialogService: DialogService, private reminderSvc: ReminderService) {
        super();
        this.applyFilterSubscription = this.filterService.filterTriggered$.subscribe(this._loadData.bind(this));
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.applyFilterSubscription.unsubscribe();
    }

    public deleteReminderClick(dataItem: RemindReportModel): void {
        const dialog: DialogRef = this.dialogService.open({
            title: 'Delete Confirmation',
            content: 'Are you sure you want to delete this reminder?',
            actions: [
                {text: 'No'},
                {text: 'Yes', primary: true}
            ]
        });
        dialog.result.subscribe(resp => {
            if (resp instanceof DialogCloseResult || resp.text === 'No') {
                return;
            } else {
                this.deleteReminder(dataItem);
            }
        });
    }

    _loadData(searchOptions: any) {
        this.searchOptions = searchOptions;
        this.isLoading = true;
        this.serv.getReminderReport(searchOptions).subscribe(resp => {
            this._onLoadSuccess(resp);
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
            console.error(`Error occurred for reminder report`, error);
        });
    }

    _onLoadSuccess(resp: RemindReportModel[]) {
        this.tableGrid.gridData = resp;
        this.tableGrid.gridSort = [];
    }

    onFcstImgClick(dataItem) {
        const filters = this.getNavFilter(dataItem);
        filters.viewType = 'Train';
        this.navService.navigateWithFilters('forecast', filters);
    }

    onInvImgClick(dataItem) {
        const filters = this.getNavFilter(dataItem);
        this.navService.navigateWithFilters('inventory', filters);
    }

    private deleteReminder(dataItem: RemindReportModel): void {
        this.isLoading = true;
        const reminderRequest: ReminderRequest = {
            rsid: dataItem.rsid,
            passengerClass: dataItem.passengerClass,
            effDepartureDate: dataItem.effDt as string,
            origin: dataItem.origin,
            destination: dataItem.destination
        };
        this.reminderSvc.deleteReminder(reminderRequest).subscribe(resp => {
            if (!resp) {
                console.error(`Error deleting reminder`, resp);
            }
            this._loadData(this.searchOptions);
        }, error => {
            console.error(`Error deleting reminder`, error);
        });
    }

    private getNavFilter(dataItem: RemindReportModel): any {
        return {
            rsid: dataItem.rsid,
            origin: dataItem.origin,
            dest: dataItem.destination,
            date: DateUtils.convertLocalDateStringToDate(dataItem.dt as string)
        };
    }
}

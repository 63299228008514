import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UrlStore} from '../../url-store';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class InventoryOverrideService {

    constructor(private http: HttpClient) {
    }

    public approveRecommendation(approvalRequest: any): Observable<any> {
        return this.http.post<any>(`${UrlStore.api.inventory.approveRecommendation}`, approvalRequest);
    }

    public checkClassConstraint(constraintCheckRequest: any): Observable<any> {
        return this.http.post<any>(`${UrlStore.api.inventory.constraintCheck}`, constraintCheckRequest);
    }

    public getInventoryOverride(overrideRequest: any): Observable<any> {
        return this.http.post<any>(`${UrlStore.api.inventory.overrideInfo}`, overrideRequest);
    }

    public saveBulkOverride(overrideData: any): Observable<any> {
        return this.http.post<any>(`${UrlStore.api.inventory.saveBulkOverride}`, overrideData);
    }

    public saveSingleOverride(invOverride: any): Observable<any> {
        return this.http.post<any>(`${UrlStore.api.inventory.singleOverride}`, invOverride);
    }

    public getBulkOverrideInfo(bulkInfoRequest: any): Observable<any> {
        return this.http.post<any>(`${UrlStore.api.inventory.overrideInfo}`, bulkInfoRequest);
    }
}
